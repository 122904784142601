import React from 'react';

import '../../wetx-styles/wetx.light.css';

const WetxCustomConfirm = ({
  title,
  description,
  onClose,
  cancelButtonText,
  confirmButtonText,
  confirmButtonClick
}) => {
  return (
    <div className="custom-ui d-flex flex-column align-items-center">
      <p className="h4 confirm-title text-center">{title}</p>
      <div className="confirm-divider" />
      <p className="text-center confirm-desc">{description}</p>
      <div className="confirm-actions d-flex flex-row justify-content-around">
        <button className="btn btn-secondary custom-secondary mr-3" onClick={onClose}>
          {cancelButtonText}
        </button>
        <button
          className="btn btn-primary"
          onClick={() => {
            confirmButtonClick();
            onClose();
          }}
        >
          {confirmButtonText}
        </button>
      </div>
    </div>
  );
};

export default WetxCustomConfirm;
