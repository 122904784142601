import React, { useState } from 'react';
import Amplify, { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';

import WetxUploadTagSelect from './utils/WetxUploadTagSelect';
import WetxUploaderSingleSelect from './utils/WetxUploaderSingleSelect';

import store from '../../redux/state';
import { deleteFile } from '../../redux/files/files.actions';

import '../../wetx-styles/wetx.light.css';

const WetxUploaderDB = ({
  uploadedFiles,
  setUploadedFiles,
  setFilesToUpload,
  file,
  types,
  categories,
  client_bucket
}) => {
  const [filename, setFilename] = useState(file.key);
  const { tags } = useSelector(state => state.files);

  file['filename'] = filename;

  const replaceFile = () => {
    const index = uploadedFiles.findIndex(f => f.key === file.key);
    let newArr = [...uploadedFiles];
    newArr[index] = file;
    setUploadedFiles(newArr);
  };

  const updateFilename = e => {
    setFilename(e.target.value);
    file['filename'] = e.target.value;
    replaceFile();
  };

  const addCategory = e => {
    file['category'] = parseInt(e.id_category);
    replaceFile();
  };

  const addType = e => {
    file['type'] = parseInt(e.id_type);
    replaceFile();
  };

  const editFileTags = tags => {
    file['user_tags'] = tags;
    replaceFile();
  };

  const removeFileFromUploading = async () => {
    Amplify.configure({
      Storage: {
        AWSS3: {
          bucket: client_bucket,
          region: 'us-west-2'
        }
      }
    });

    const result = await Storage.remove(file.filename, { level: 'public' });

    if (result['$metadata'].httpStatusCode) {
      let newArr = [...uploadedFiles];
      newArr = newArr.filter(item => item.key !== file.key);
      store.dispatch(deleteFile(file.key, client_bucket));
      setUploadedFiles(newArr);
      setFilesToUpload(newArr);
    }
  };

  return (
    <div
      className="d-flex flex-row align-items-center justify-content-around w-100"
      style={{ border: '1px solid #EDF2F8', borderTop: 'none' }}
    >
      <input
        className="flex-fill"
        type="text"
        value={filename}
        onChange={e => {
          updateFilename(e);
        }}
        style={{ border: '1px solid #ccc', borderRadius: '5px', maxWidth: '20%', height: '40px' }}
      />
      <div className="divider" />
      <WetxUploaderSingleSelect
        title="Select Category"
        options={categories}
        objSelector="id_category"
        objTitle="category_name"
        handleChange={addCategory}
        currentCategory={file.category}
      />
      <div className="divider" />
      <WetxUploaderSingleSelect
        title="Select Type"
        options={types}
        objSelector="id_type"
        objTitle="type_name"
        handleChange={addType}
      />
      <div className="divider" />
      <WetxUploadTagSelect tags={tags} editFileTags={editFileTags} />
      <div className="divider" />
      <div style={{ width: '30px' }}>
        <button
          style={{
            width: '20px',
            height: '20px',
            fontSize: '13px',
            lineHeight: '13px',
            border: 'none',
            borderRadius: '50%',
            color: '#fff',
            background: '#E63756',
            fontWeight: '600'
          }}
          onClick={() => removeFileFromUploading()}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default WetxUploaderDB;
