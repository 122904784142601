import React from 'react';

const WetxSystemTagsOverlay = ({ userTags, addTags, noAdmin, addFileTag, removeTags }) => {
  return (
    <div className="system-tags-overlay">
      <div className="system-tags-container">
        {userTags && userTags.length > 0 ? (
          userTags.map((tag, index) => (
            <li
              key={index}
              className="tag"
              style={{
                background: tag.background,
                color: tag.color,
                padding: '5px 8px',
                borderRadius: '10px',
                height: '22px',
                fontSize: '12px',
                lineHeight: '12px'
              }}
            >
              <span
                onClick={e => {
                  addFileTag && addFileTag(tag);
                }}
                className="tag-title"
                style={{ cursor: 'pointer' }}
              >
                {tag.tag_name}
              </span>
              {removeTags && (
                <span
                  className="tag-close-icon"
                  style={{
                    background: tag.color,
                    color: tag.background
                  }}
                  onClick={() => {
                    removeTags && removeTags(tag.id_tag);
                  }}
                >
                  x
                </span>
              )}
            </li>
          ))
        ) : (
          <p>There are no system labels matching your criteria</p>
        )}
      </div>
      <div>
        <div className="hor-divider" />

        <div
          className="text-center mt-2 system-tags-button"
          onClick={() => {
            addTags();
          }}
        >
          + Create Label
        </div>
      </div>
    </div>
  );
};

export default WetxSystemTagsOverlay;
