import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DataGrid, { Column, HeaderFilter, Paging, Pager, Selection } from 'devextreme-react/data-grid';

import 'devextreme/dist/css/dx.light.css';
import '../../wetx-styles/wetx.light.css';

import FileFormatter from './helpers/FileFormatter';
import ActionFormatter from './helpers/ActionFormatter';
import ArchiveActionFormatter from '../wetx-archive/utils/ArchiveActionFormatter';

import WetxSingleFileView from './WetxSingleFileView';
import WetxCloudDownloadIndicator from './WetxCloudDownloadIndicator';
import WetxCloudShareModal from './WetxCloudShareModal';
import WetxAddTagModal from './WetxAddTagModal';
import WetxCloudUploadModal from './WetxCloudUploadModal';
import ButtonIcon from '../common/ButtonIcon';
import WetxPager from './utils/WetxPager';
import WetxDateRangeFilter from './utils/WetxDateRangeFilter';
import WetxMultiActionsModal from './WetxMultiActionsModal';
import WetxIcon from '../utilities/WetxIcon';

const WetxDevExtremeTable = ({ files, folderName, archive, folderId, hideUploadBtn }) => {
  const [singleFile, setSingleFile] = useState(null);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [tagging, setTagging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [previewPos, setPreviewPos] = useState({ x: 0, y: 0 });
  const [previewFile, setPreviewFile] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState(null);
  const [dataGridRef] = useState(React.createRef());
  const [currentItemCount, setCurrentItemCount] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openMultiActionsModal, setOpenMultiActionsModal] = useState(false);

  const user = useSelector(state => state.auth.attributes.sub);
  const client = useSelector(state => state.auth.clientOrganisation);
  const clientTags = useSelector(state => state.files.tags);
  const { pinnedFiles } = useSelector(state => state.files);

  const insertFileWithNewTags = (file, newTags) => {
    const foundIndex = files.findIndex(f => f.id === file.id);
    file.user_tags = newTags;
    files[foundIndex] = file;
  };

  const renderCustomFilename = data => {
    return (
      <FileFormatter
        file={data.data}
        user={user}
        previewFile={previewFile}
        previewPos={previewPos}
        setPreviewFile={setPreviewFile}
        setSingleFile={setSingleFile}
        setViewing={setViewing}
        setPreviewPos={setPreviewPos}
      />
    );
  };

  const renderCustomTags = data => {
    let { user_tags } = data.data;
    user_tags = JSON.parse(user_tags);

    if (user_tags && user_tags !== 'null') {
      return (
        <div className="d-flex flex-row">
          {user_tags.map((tag, index) => (
            <li
              key={index}
              className="tag"
              style={{
                background: tag.background,
                color: tag.color,
                height: '20px'
              }}
            >
              <span className="tag-title">{tag.tag_name}</span>
            </li>
          ))}
        </div>
      );
    }
  };

  const docDateFormatter = cellInfo => {
    if (cellInfo.value) {
      const dateRange = JSON.parse(cellInfo.value);
      let startDate;
      let endDate;
      if (dateRange.start_date !== '') {
        const year = new Date(dateRange.start_date).getFullYear();
        const month = new Date(dateRange.start_date).getMonth() + 1;

        startDate = `${month}/${year}`;
      }
      if (dateRange.end_date !== '') {
        const year = new Date(dateRange.end_date).getFullYear();
        const month = new Date(dateRange.end_date).getMonth() + 1;

        endDate = `${month}/${year}`;
      }

      if (startDate) {
        const returnDate = `${startDate} ${endDate ? `to ${endDate}` : ''}`;
        return returnDate;
      }
    }
  };
  const renderActions = data => {
    return (
      <ActionFormatter
        file={data.data}
        user={user}
        client={client}
        isDownloading={isDownloading}
        setSingleFile={setSingleFile}
        setIsDownloading={setIsDownloading}
        setOpenDownloadModal={setOpenDownloadModal}
        setViewing={setViewing}
        setSharing={setSharing}
        pinnedDocuments={pinnedFiles}
        setTagging={setTagging}
      />
    );
  };

  const renderArchiveActions = data => {
    return <ArchiveActionFormatter file={data.data} user={user} />;
  };

  const clearState = () => {
    setViewing(false);
  };

  const tableSelectionChanged = ({ selectedRowsData }) => {
    setSelectedFiles(selectedRowsData);
  };

  const tagHeaderFilter =
    clientTags.length > 0 &&
    clientTags.map(tag => {
      return {
        text: tag.tag_name,
        value: ['user_tags', 'contains', tag.tag_name]
      };
    });

  const customCellHeader = data => {
    return <WetxDateRangeFilter data={data} files={files} folderId={folderId} setFilteredFiles={setFilteredFiles} />;
  };

  if (folderName !== 'Uncategorized' && files.length === 0) return null;

  return (
    <div className="mt-3 file-table">
      <div
        className="p-2 file-header d-flex flex-row justify-content-between align-items-center"
        style={{ background: 'transparent' }}
      >
        <div
          className="m-1 d-flex flex-row align-items-center"
          style={{ width: '100%', fontSize: '18px', fontWeight: '800' }}
        >
          {folderName}
        </div>
        {selectedFiles && selectedFiles.length > 0 && (
          <div
            color="link"
            size="sm"
            className="btn btn-falcon-default btn-sm d-flex flex-row align-items-center"
            style={{ minWidth: '150px', marginRight: '10px' }}
            onClick={() => setOpenMultiActionsModal(true)}
          >
            <WetxIcon iconName="gear" size="16px" title="Actions for multiple files" margin="5px" color={'gray'} />
            <div style={{ fontWeight: '600' }}>Manage files</div>
          </div>
        )}
        {!archive && !hideUploadBtn && (
          <ButtonIcon
            icon="plus"
            transform="shrink-3 down-2"
            color="falcon-default"
            size="sm"
            style={{ minWidth: '100px' }}
            onClick={() => setUploading(true)}
          >
            Upload
          </ButtonIcon>
        )}
      </div>
      <DataGrid
        showColumnLines={true}
        showRowLines={true}
        id={folderName}
        dataSource={filteredFiles ? filteredFiles : files}
        showBorders={true}
        allowColumnResizing={true}
        ref={dataGridRef}
        onRowPrepared={e => {
          e.rowElement.style.height = '40px';
        }}
        onContentReady={e => {
          if (currentItemCount !== e.component.totalCount()) {
            setCurrentItemCount(e.component.totalCount());
          }
        }}
        onSelectionChanged={tableSelectionChanged}
        remoteOperations={true}
      >
        <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always" />
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={10} />
        <Pager
          visible={false}
          showPageSizeSelector={true}
          allowedPageSizes={[5, 10, 15, 20, 25, 50]}
          showNavigationButtons={true}
          displayMode="compact"
          showInfo={true}
        />
        <Column dataField="type_name" width="8%" caption="Type">
          <HeaderFilter allowSearch={true} />
        </Column>
        <Column dataField="filename" allowHeaderFiltering={false} width="60%" cellRender={renderCustomFilename} />
        <Column caption="Labels" dataField="user_tags" width="20%" cellRender={renderCustomTags} allowFiltering={true}>
          <HeaderFilter allowSearch={true} dataSource={tagHeaderFilter} />
        </Column>
        <Column
          caption="Date"
          dataField="document_date"
          width="7%"
          allowHeaderFiltering={false}
          customizeText={docDateFormatter}
          headerCellRender={customCellHeader}
        />
        <Column caption="Actions" width="5%" cellRender={archive ? renderArchiveActions : renderActions} />
        <div className="card" />
      </DataGrid>
      <WetxPager
        dataGridRef={dataGridRef}
        filesNumber={
          dataGridRef.current
            ? dataGridRef.current.props.dataSource.length
            : filteredFiles
            ? filteredFiles.length
            : files.length
        }
        allowedPageSizes={[5, 10, 15, 20, 25, 50]}
        currentItemCount={currentItemCount}
      />

      {singleFile && viewing && (
        <WetxSingleFileView
          file={singleFile}
          archive={archive && true}
          openModal={true}
          handleModalClose={clearState}
        />
      )}
      {singleFile && openDownloadModal && (
        <WetxCloudDownloadIndicator
          openModal={true}
          isDownloading={isDownloading}
          openDownloadModal={openDownloadModal}
          setOpenDownloadModal={setOpenDownloadModal}
          file={singleFile.file}
        />
      )}
      {singleFile && sharing && <WetxCloudShareModal file={singleFile} openModal={true} setSharing={setSharing} />}
      {singleFile && tagging && (
        <WetxAddTagModal
          file={singleFile.file}
          openModal={true}
          setTagging={setTagging}
          id_client={client.id_client}
          insertFileWithNewTags={insertFileWithNewTags}
        />
      )}
      {uploading && <WetxCloudUploadModal openModal={true} setUploading={setUploading} category={folderId} />}
      {selectedFiles.length > 0 && openMultiActionsModal && (
        <WetxMultiActionsModal
          open={openMultiActionsModal}
          setOpen={setOpenMultiActionsModal}
          selectedFiles={selectedFiles}
        />
      )}
      {/* Multi tag management */}
    </div>
  );
};

export default WetxDevExtremeTable;
