import React, { useEffect, useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import { range } from 'lodash';

import { Card, CardBody, CardFooter, Button } from 'reactstrap';
import ReactEcharts from 'echarts-for-react';

import 'react-datepicker/dist/react-datepicker.css';

import Flex from '../common/Flex';
import Loader from '../common/Loader';
import ButtonIcon from '../common/ButtonIcon';

import { getChartDataFromUSGS } from '../../redux/widgets/widgets.actions';

const years = range(1950, new Date(Date.now()).getFullYear() + 1, 1);
const months = moment.monthsShort();

const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
  <Button color="primary" onClick={onClick} ref={ref}>
    {value}
  </Button>
));

const CustomDateHeader = ({
  props: {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }
}) => {
  return (
    <div
      style={{
        margin: 10,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ButtonIcon
        color="primary"
        className="ml-auto p-1"
        type="submit"
        icon="chevron-left"
        iconAlign="right"
        transform="down-1 shrink-4"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      />
      <select
        value={new Date(date).getFullYear()}
        onChange={({ target: { value } }) => changeYear(value)}
        className="flex-grow-1 ml-1 mr-1"
        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #c7c7c7' }}
      >
        {years.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[new Date(date).getMonth()]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        className="flex-grow-1 mr-1"
        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #c7c7c7' }}
      >
        {months.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <ButtonIcon
        color="primary"
        className="ml-auto p-1"
        type="submit"
        icon="chevron-right"
        iconAlign="right"
        transform="down-1 shrink-4"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      />
    </div>
  );
};

const WetxWidgetUSGS = () => {
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState(new Date() - 31 * 24 * 60 * 60 * 1000);
  const [endDate, setEndDate] = useState(new Date());

  const getChartData = async () => {
    const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
    const res = await getChartDataFromUSGS(formattedStartDate, formattedEndDate);
    setData(res);
  };

  useEffect(() => {
    getChartData();

    //eslint-disable-next-line
  }, [startDate, endDate]);

  if (!data) return <Loader />;
  return (
    <Card style={{ height: '100%' }}>
      <CardBody tag={Flex} align="center" justify="center" style={{ padding: '0.25rem' }}>
        <ReactEcharts style={{ height: '100%', width: '100%', minHeight: '300px' }} option={data} />
      </CardBody>
      <CardFooter className="d-flex flex-row justify-content-center align-center">
        <div className="mr-2 d-flex flex-column justify-content-center align-center">
          <p className="text-center m-0">Show results from: </p>
        </div>
        <DatePicker
          renderCustomHeader={e => <CustomDateHeader props={e} />}
          selected={startDate}
          onChange={date => {
            if (moment(date).valueOf() > moment(endDate).valueOf()) {
              return toast.error('Start date is larger than the end date');
            } else {
              return setStartDate(date);
            }
          }}
          dateFormat="yyyy-MM-dd"
          strictParsing
          customInput={<CustomDateInput />}
        />
        <div className="mr-2 ml-2 d-flex flex-column justify-content-center align-center">
          <p className="text-center m-0">to: </p>
        </div>
        <DatePicker
          renderCustomHeader={e => <CustomDateHeader props={e} />}
          selected={endDate}
          onChange={date => {
            if (moment(date).valueOf() > moment().valueOf()) {
              return toast.error('We cannot predict the future, but we can invent it.');
            }
            if (moment(startDate).valueOf() > moment(date).valueOf()) {
              return toast.error('End date is smaller than the of start date');
            } else {
              return setEndDate(date);
            }
          }}
          dateFormat="yyyy-MM-dd"
          strictParsing
          customInput={<CustomDateInput />}
        />
      </CardFooter>
    </Card>
  );
};

export default WetxWidgetUSGS;
