import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import WetxCustomConfirm from '../../wetx-utils/WetxCustomConfirm';

import 'react-confirm-alert/src/react-confirm-alert.css';

import { createFolder, updateFolder, deleteFolder } from '../../../redux/cabinets/cabinets.actions';
import store from '../../../redux/state';
import Loader from '../../common/Loader';

const ManageCabinetsTable = ({ cabinets }) => {
  const [currentCabinets, setCurrentCabinets] = useState([...cabinets] || null);
  const [editingCabinet, setEditingCabinet] = useState(null);
  const [cabinetName, setCabinetName] = useState('');
  const [newCabinetName, setNewCabinetName] = useState('');
  const [loadingCabinets, setLoadingCabinets] = useState(false);

  const client = useSelector(state => state.auth.clientOrganisation);
  const { loading } = useSelector(state => state.cabinets);

  const handleCabinetNameChange = e => {
    setCabinetName(e.target.value);
  };

  useEffect(() => {
    setCurrentCabinets(cabinets);
  }, [cabinets]);
  useEffect(() => {
    setLoadingCabinets(loading);
  }, [loading]);

  return (
    <div className="manage-cabinets-table__container">
      <div className="add-new-cabinet w-100 d-flex flex-row justify-content-around mb-3">
        <Input
          type="text"
          value={newCabinetName}
          onChange={e => {
            setNewCabinetName(e.target.value);
          }}
          placeholder="New cabinet name"
          style={{ height: '30px', maxWidth: '60%' }}
        />
        <div
          className={`mr-2 btn btn-primary btn-sm ${newCabinetName === '' ? 'disabled' : ''}`}
          onClick={() => {
            store.dispatch(createFolder(client.id_client, newCabinetName));
          }}
        >
          {loadingCabinets ? 'Please wait...' : '+ Add new cabinet'}
        </div>
      </div>
      {loadingCabinets ? (
        <Loader />
      ) : (
        <div
          style={{
            boxShadow: '0px 7px 14px rgba(65, 69, 88, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.07)',
            maxHeight: '60vh',
            overflowY: 'scroll'
          }}
        >
          <div
            className="d-sm-flex flex-row justify-content-between"
            style={{
              background: '#ffffff',
              fontSize: '16px',
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px'
            }}
          >
            <div className="flex-even text-center">Cabinet name</div>
            <div className="flex-even text-center">Actions</div>
          </div>
          <div style={{ fontSize: '14px' }}>
            {currentCabinets &&
              currentCabinets.map((cabinet, index) => (
                <div
                  key={index}
                  className="d-flex flex-row justify-content-between align-items-center pb-1 pt-1"
                  style={{
                    background: index % 2 > 0 ? '#ffffff' : '#F3F4EE',
                    borderBottomLeftRadius: index + 1 === cabinets.length && '6px',
                    borderBottomRightRadius: index + 1 === cabinets.length && '6px'
                  }}
                >
                  {editingCabinet && editingCabinet === cabinet.id_category ? (
                    <div className="flex-even text-center">
                      <Input
                        type="text"
                        value={cabinetName}
                        onChange={handleCabinetNameChange}
                        className="ml-1"
                        style={{ height: '25px' }}
                      />
                    </div>
                  ) : (
                    <div className="flex-even text-center">{cabinet.category_name}</div>
                  )}

                  <div className="flex-even d-flex flex-row justify-content-center">
                    <div
                      className="mr-2 btn btn-primary btn-sm"
                      onClick={() => {
                        if (editingCabinet && editingCabinet === cabinet.id_category) {
                          setEditingCabinet(null);
                          return store.dispatch(updateFolder(editingCabinet, client.id_client, cabinetName));
                        }
                        setEditingCabinet(cabinet.id_category);
                        setCabinetName(cabinet.category_name);
                      }}
                    >
                      {editingCabinet && editingCabinet === cabinet.id_category ? 'Save' : 'Edit'}
                    </div>
                    <div
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        if (editingCabinet && editingCabinet === cabinet.id_category) {
                          setCabinetName('');
                          setEditingCabinet(null);
                        } else {
                          confirmAlert({
                            customUI: ({ onClose }) => {
                              return (
                                <WetxCustomConfirm
                                  title="Are you sure you want to remove this cabinet?"
                                  description="This action will permanently remove selected cabinet. Cabinet must be empty in order to be deleted"
                                  onClose={onClose}
                                  cancelButtonText="Cancel"
                                  confirmButtonText="Yes, Delete Cabinet"
                                  confirmButtonClick={() => {
                                    store.dispatch(deleteFolder(cabinet.id_category));
                                  }}
                                />
                              );
                            }
                          });
                        }
                      }}
                    >
                      {editingCabinet && editingCabinet === cabinet.id_category ? 'Cancel' : 'Delete'}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageCabinetsTable;
