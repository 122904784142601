import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import store from '../../redux/state';
import { viewFile } from '../../redux/files/files.actions';

import WetxSingleFileView from '../wetx-cloud/WetxSingleFileView';
import WetxCloudShareModal from './WetxCloudShareModal';
import WetxAddTagModal from './WetxAddTagModal';
import WetxCloudDownloadIndicator from './WetxCloudDownloadIndicator';
import WetxOverlay from './utils/WetxOverlay';

import '../../wetx-styles/wetx.light.css';

const shortenFilename = filename => {
  if (filename.length > 40) {
    const firstPart = filename.substring(0, 20);
    const secondPart = filename.substring(filename.length - 20);
    return firstPart + '...' + secondPart;
  }
  return filename;
};

const WetxCloudDropdownFileItem = ({ item }) => {
  const [singleFile, setSingleFile] = useState(null);
  const [tagging, setTagging] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const client = useSelector(state => state.auth.clientOrganisation);

  const clearState = () => {
    setSingleFile(null);
    setTagging(false);
    setSharing(false);
    setIsDownloading(false);
    setViewing(false);
    setOpenDownloadModal(false);
  };

  const insertFileWithNewTags = tags => {
    item['user_tags'] = JSON.stringify(tags);
  };

  return (
    <div className="dropdown-file__container" style={{ background: '#f5f5f5', borderRadius: '10px' }}>
      <div className="dropdown-file__content">
        <div
          className="mr-2 mb-2 p-2 align-items-center justify-content-center"
          style={{ width: '100%', cursor: 'pointer' }}
        >
          <div className="dropdown-file__overlay-container d-flex justify-content-center align-items-end pb-2">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
              alt="pdf"
              style={{ width: '80px' }}
            />

            <WetxOverlay
              file={item}
              setSingleFile={setSingleFile}
              setTagging={setTagging}
              setSharing={setSharing}
              setIsDownloading={setIsDownloading}
              setViewing={setViewing}
              setOpenDownloadModal={setOpenDownloadModal}
            />
          </div>
          <p
            className="h6 mt-1 text-center text-break"
            onClick={async () => {
              const fileUrl = await store.dispatch(viewFile(item));
              setSingleFile({ file: item, fileUrl });
              setViewing(true);
            }}
          >
            {shortenFilename(item.filename.split('/')[item.filename.split('/').length - 1])}
          </p>
        </div>
        {singleFile && viewing && (
          <WetxSingleFileView file={singleFile} openModal={true} handleModalClose={clearState} />
        )}
        {singleFile && openDownloadModal && (
          <WetxCloudDownloadIndicator
            openModal={true}
            isDownloading={isDownloading}
            openDownloadModal={openDownloadModal}
            setOpenDownloadModal={setOpenDownloadModal}
            file={singleFile.file}
          />
        )}
        {singleFile && sharing && <WetxCloudShareModal file={singleFile} openModal={true} setSharing={setSharing} />}
        {singleFile && tagging && (
          <WetxAddTagModal
            file={singleFile.file}
            openModal={true}
            setTagging={setTagging}
            id_client={client.id_client}
            insertFileWithNewTags={insertFileWithNewTags}
          />
        )}
      </div>
    </div>
  );
};

export default WetxCloudDropdownFileItem;
