import React, { useState, useEffect } from 'react';

import { Card, CardBody, Row, Col } from 'reactstrap';
import ReactEcharts from 'echarts-for-react';

import Loader from '../common/Loader';

import { loadWidgetData } from '../../redux/widgets/widgets.actions';
import store from '../../redux/state';

const WetxWidgetSimpleChart = ({ idWidget, options: { title, option, data_value } }) => {
  const [widgetData, setWidgetData] = useState(null);

  const getWidgetDataMeta = async () => {
    const data = await store.dispatch(loadWidgetData(idWidget));

    if (data) setWidgetData(data[0].widget_data);
  };

  useEffect(() => {
    if (!widgetData) {
      getWidgetDataMeta();
    }
  });

  if (!widgetData) return <Loader />;

  const newObj = { ...option, ...widgetData };
  return (
    <Card style={{ height: '100%', width: '100%', maxHeight: '400px' }}>
      <CardBody>
        <Row className="align-items-center justify-content-center" style={{ height: '100%' }}>
          <Col style={{ fontSize: '2.1rem', fontWeight: '600' }}>${data_value}</Col>
          <Col>
            <ReactEcharts style={{ maxHeight: '100px' }} option={newObj} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default WetxWidgetSimpleChart;
