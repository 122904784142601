import { elasticActionTypes } from './elastic.types';
import axiosInstance from '../helpers/axios-instance';

export const elasticDeepSearch = (bucket, searchTerm, tagFilters, typeFilters) => async dispatch => {
  try {
    dispatch({ type: elasticActionTypes.DEEP_SEARCH_REQUEST });
    const res = await axiosInstance.post('/s3/deep-search', { bucket, searchTerm, tagFilters, typeFilters });
    if (res.data.message) {
      return dispatch({ type: elasticActionTypes.DEEP_SEARCH_ERROR, payload: res.data.message });
    }
    dispatch({ type: elasticActionTypes.DEEP_SEARCH_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: elasticActionTypes.DEEP_SEARCH_ERROR, payload: error });
  }
};
