import React from 'react';

import { Card, CardBody } from 'reactstrap';
import Flex from '../common/Flex';

const WetxWidgetSnotel = ({ options: { url, data_url } }) => {
  return (
    <Card style={{ height: '100%' }}>
      <CardBody tag={Flex} align="center" justify="center" style={{ padding: '0.25rem', height: '100%' }}>
        <div className="position-relative" style={{ height: '100%', width: '100%' }}>
          <iframe style={{ width: '100%', height: '100%', border: 'none' }} src={url} title="Snotel Data View" />
        </div>
      </CardBody>
      <div style={{ padding: '0.5rem' }}>
        <a href={data_url} target="_blank" rel="noopener noreferrer">
          Snow Depth Data
        </a>
      </div>
    </Card>
  );
};

export default WetxWidgetSnotel;
