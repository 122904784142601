import { combineReducers } from 'redux';

import auth from './auth';
import dashboards from '../dashboards/dashboards.reducer';
import widgets from '../widgets/widgets.reducer';
import files from '../files/files.reducer';
import adminBuckets from '../admin/buckets/buckets.reducer';
import adminClients from '../admin/clients/client.reducer';
import adminUsers from '../admin/users/users.reducer';
import elastic from '../elastic/elastic.reducer';
import water from '../water/water.reducer';
import users from '../users/users.reducer';
import cabinets from '../cabinets/cabinets.reducer';

const rootReducer = combineReducers({
  auth,
  dashboards,
  widgets,
  files,
  adminBuckets,
  adminClients,
  adminUsers,
  elastic,
  water,
  users,
  cabinets
});

export default rootReducer;
