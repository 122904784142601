import React, { useState } from 'react';
import { Modal, ModalBody, Progress, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import Amplify, { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import WetxUploaderDB from './WetxUploaderDB';
import WetxIcon from '../utilities/WetxIcon';

import { saveFiles } from '../../redux/files/files.actions';
import store from '../../redux/state';

import '../../wetx-styles/wetx.light.css';

const WetxCloudUploadModal = ({ openModal, setUploading, category }) => {
  const [filesToUpload, setFilesToUpload] = useState(null);
  const [upload, setUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentlyUploading, setCurrentlyUploading] = useState(null);

  const { client_bucket } = useSelector(state => state.auth.clientOrganisation);
  const types = useSelector(state => state.files.types);
  const categories = useSelector(state => state.cabinets.items);

  const uploadFiles = async () => {
    Amplify.configure({
      Storage: {
        AWSS3: {
          bucket: client_bucket,
          region: 'us-west-2'
        }
      }
    });

    const uploadingFiles = filesToUpload.map((file, index) => {
      Storage.configure({ level: 'public' });
      return Storage.put(file.path, file, {
        contentType: file.type,
        progressCallback(progress) {
          const uploadPercentage = Math.floor((progress.loaded / progress.total) * 100);
          const uploadPercentIndicator = document.querySelector(`.uploading-file-${index}`);
          const progressBar = document.querySelector(`.upload-progress-${index}`);
          if (progressBar) {
            progressBar.setAttribute('aria-valuenow', uploadPercentage);
            progressBar.style.width = uploadPercentage + '%';
          }
          if (uploadPercentIndicator) {
            uploadPercentIndicator.innerText = `${uploadPercentage}%`;
          }
        }
      });
    });
    // map through currently uploaded file promisses, if any is canceled it will show up as such
    uploadingFiles.map(async (filePromise, index) => {
      try {
        const result = await filePromise;

        result['filename'] = result['key'];
        result['category'] = category;
        result['categories'] = categories;
        result['types'] = types;
        result['type'] = null;
        result['user_tags'] = null;

        setUploadedFiles(uploadedFiles => [...uploadedFiles, result]);
        store.dispatch(saveFiles([result], client_bucket));
      } catch (err) {
        const newCurrentlyUploading = uploadingFiles;
        delete newCurrentlyUploading[index];
        setCurrentlyUploading(newCurrentlyUploading);
        const newFilesToUpload = filesToUpload;
        newFilesToUpload[index] = null;
        setFilesToUpload(newFilesToUpload);

        if (newFilesToUpload.filter(file => file).length === 0) {
          setFilesToUpload(null);
          setCurrentlyUploading(null);
          setUploadedFiles([]);
          setUpload(false);
        }
        // remove file uploader for canceled current file from view/ this throws but because it
        // auto removes all cancelled files once one file uploads
        const uploadContainer = document.querySelector(`.upload-container-${index}`);
        if (uploadContainer) {
          uploadContainer.parentNode.removeChild(uploadContainer);
        }
      }
    });

    // set current promises in state so we can cancel them
    if (!currentlyUploading) {
      setCurrentlyUploading(uploadingFiles);
    }
  };

  const saveFilesToDatabase = async () => {
    if (client_bucket) {
      store.dispatch(saveFiles(uploadedFiles, client_bucket));
      setFilesToUpload(null);
      setUploadedFiles([]);
      setUpload(false);
      setCurrentlyUploading(null);
    }
  };

  const cancelUpload = async index => {
    // cancel promise with the current index
    await Storage.cancel(currentlyUploading[index], 'success');
  };

  return (
    <Modal className="resize-modal" isOpen={openModal} centered={true}>
      <div
        className="modal-header d-flex flex-row justify-content-between align-items-center"
        style={{ borderBottom: '1px solid #c7c7c7c7', fontSize: '0.8em' }}
      >
        <div>
          <p style={{ margin: '0', fontSize: '18px' }}>Upload new documents</p>
        </div>
        <div className="flex-end ml-100" title="Close File" style={{ paddingLeft: '5px', fontSize: '18px' }}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setUploading(false);
              if (uploadedFiles.length > 0) {
                saveFilesToDatabase();
              }
            }}
          />
        </div>
      </div>
      <ModalBody className="d-flex flex-column">
        {!filesToUpload ? (
          <Dropzone
            accept="application/pdf"
            onDropRejected={rejectedFiles => {
              setFilesToUpload(null);
              return toast.error('You can upload only PDF files');
            }}
            onDrop={acceptedFiles => {
              if (acceptedFiles.length > 50) {
                return toast.error('You can upload maximum of 50 files');
              }
              setFilesToUpload(acceptedFiles);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div>
                <section
                  className="mb-2 d-flex flex-column justify-content-center align-items-center"
                  style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}
                >
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    {...getRootProps()}
                    style={{ height: '100%', minHeight: '300px', width: '100%' }}
                  >
                    <input {...getInputProps()} />
                    <div
                      className="d-flex flex-row justify-content-center align-items-center"
                      style={{
                        cursor: 'pointer',
                        border: '1px dashed #ddd',
                        borderRadius: '5px',
                        width: '100%',
                        height: '100%',
                        minHeight: '300px',
                        margin: '0'
                      }}
                    >
                      <WetxIcon iconName="upload" color="gray" size="30px" title="Open File" margin="10px" />
                      Drop your files here
                    </div>
                  </div>
                </section>
                <div className="actions d-flex flex-row align-items-center justify-content-end">
                  <div
                    className="btn btn-secondary"
                    onClick={() => {
                      setUploading(false);
                    }}
                  >
                    Cancel
                  </div>

                  <label className="btn btn-primary m-0 ml-2">
                    <input type="file" style={{ display: 'none' }} {...getInputProps()} />
                    Browse
                  </label>
                </div>
              </div>
            )}
          </Dropzone>
        ) : (
          filesToUpload.filter(file => file !== null).length !== uploadedFiles.length && (
            <div>
              {filesToUpload.map((file, index) => {
                return (
                  <div
                    key={index}
                    className={`d-flex flex-column justify-content-between mb-2 upload-container-${index}`}
                  >
                    {file && (
                      <div className="w-100">
                        <div>{file.name}</div>
                        <div className="d-flex flex-row align-items-center">
                          <div
                            style={{
                              width: '90%',
                              backgroundColor: '#f5f5f5',
                              borderRadius: '10px',
                              height: '0.8rem',
                              overflow: 'hidden'
                            }}
                          >
                            <Progress
                              bar
                              className={`mb-3 upload-progress-${index} float-left`}
                              color="#2A7BE4"
                              style={{ height: '0.8rem', fontWeight: 'bold', color: '#f5f5f5' }}
                            />
                          </div>
                          <div
                            className="flex-end d-flex flex-row justify-content-around align-items-center"
                            style={{ width: '10%' }}
                          >
                            <div className={`uploading-file-${index}`}>0%</div>
                            <button
                              style={{
                                width: '20px',
                                height: '20px',
                                fontSize: '13px',
                                lineHeight: '13px',
                                border: 'none',
                                borderRadius: '50%',
                                color: '#fff',
                                background: '#E63756',
                                fontWeight: '600'
                              }}
                              onClick={() => cancelUpload(index)}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {!upload && (
                <div className="upload-actions d-flex flex-row" style={{ marginLeft: 'auto', width: 'fit-content' }}>
                  <Button
                    className="mr-2"
                    color="secondary"
                    onClick={() => {
                      setFilesToUpload(null);
                      setCurrentlyUploading(null);
                      setUploadedFiles([]);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      uploadFiles();
                      setUpload(true);
                    }}
                  >
                    Upload
                  </Button>
                </div>
              )}
            </div>
          )
        )}
        {filesToUpload && filesToUpload.filter(file => file !== null).length === uploadedFiles.length && (
          <div>
            <div className="uploaded-table d-flex flex-column">
              <div
                className="uploaded-table__header d-flex flex-row justify-content-around"
                style={{ border: '1px solid #EDF2F8', height: '30px' }}
              >
                <div style={{ width: '20%', textAlign: 'center' }}>Filename</div>
                <div className="divider" />
                <div style={{ width: '20%', textAlign: 'center' }}>Cabinet</div>
                <div className="divider" />
                <div style={{ width: '20%', textAlign: 'center' }}>Type</div>
                <div className="divider" />
                <div style={{ width: '10%', textAlign: 'center' }}>Labels</div>
                <div className="divider" />
                <div style={{ width: '30px', textAlign: 'center' }} />
              </div>
              <div className="d-flex flex-column w-100">
                {uploadedFiles.map((file, index) => {
                  return (
                    <WetxUploaderDB
                      key={index}
                      file={file}
                      types={types}
                      categories={categories}
                      uploadedFiles={uploadedFiles}
                      setFilesToUpload={setFilesToUpload}
                      setUploadedFiles={setUploadedFiles}
                      setUpload={setUpload}
                      client_bucket={client_bucket}
                    />
                  );
                })}
              </div>
            </div>
            <div className="upload-controls mt-2" style={{ marginLeft: 'auto', width: 'fit-content' }}>
              <Button
                className="mr-2"
                color="secondary"
                onClick={() => {
                  saveFilesToDatabase();
                  setFilesToUpload(null);
                  setUploadedFiles([]);
                  setUpload(false);
                  setCurrentlyUploading(null);
                }}
              >
                Upload More
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  saveFilesToDatabase();
                  setUploading(false);
                }}
              >
                Done
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default WetxCloudUploadModal;
