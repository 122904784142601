import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';

import WetxCloudDownloadIndicator from './WetxCloudDownloadIndicator';
import WetxCloudShareModal from './WetxCloudShareModal';
import WetxIcon from '../utilities/WetxIcon';
import WetxDocEdit from './utils/WetxDocEdit';
import WetxFileActions from './utils/WetxFileActions';
import WetxArchiveActions from './utils/WetxArchiveActions';
import WetxSingleFilePageNavigator from '../wetx-utils/WetxSingleFilePageNavigator';

import store from '../../redux/state';
import { downloadFile } from '../../redux/files/files.actions';

import '../../wetx-styles/wetx.light.css';
import WetxPdfViewer from './WetxPdfViewer';

const WetxSingleFileView = ({
  file: {
    file,
    file: { id, filename, type_name, tags, file_key },
    fileUrl,
    page,
    pages
  },
  openModal,
  handleModalClose,
  archive,
  searchValue
}) => {
  const [modal, setModal] = useState(openModal);
  const [isPinned, setIsPinned] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [showDocumentDetails, setShowDocumentDetails] = useState(false);
  const [editingFile, setEditingFile] = useState(false);
  const [newFilename, setNewFilename] = useState(filename);
  const [currentPage, setCurrentPage] = useState(page);

  const user = useSelector(state => state.auth.attributes.sub);
  const client = useSelector(state => state.auth.clientOrganisation);
  const pinnedDocuments = useSelector(state => state.files.pinnedFiles);

  const checkIfPinned = () => {
    pinnedDocuments.map(doc => doc.file_key === file_key && setIsPinned(true));
  };

  useEffect(() => {
    if (pinnedDocuments) {
      checkIfPinned();
    }

    // eslint-disable-next-line
  }, [pinnedDocuments]);

  const toggle = () => {
    if (modal && handleModalClose) handleModalClose();

    setModal(!modal);
  };

  const retrieveFile = async () => {
    setIsDownloading(true);
    setOpenDownloadModal(true);
    const fileDownloaded = await store.dispatch(downloadFile(file));

    if (fileDownloaded) setIsDownloading(false);
  };

  return (
    <div>
      <Modal className="modal-lg modal-view" isOpen={modal} centered={true}>
        <div className="modal-header d-flex flex-column" style={{ padding: '1px' }}>
          <div className="d-flex flex-row justify-content-between w-100" style={{ padding: '15px' }}>
            <div className="modal-header__Meta flex-start d-inline-flex flex-fill">
              <WetxIcon iconName="document" color="blue" size="45px" title="Open File" margin="10px" />
              <div className="modal-header__Meta__text d-flex flex-column w-100">
                <div style={{ fontSize: '18px' }} className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center flex-fill">
                    <strong className="mr-2">Document name: </strong>
                    {editingFile && !archive ? (
                      <div className="flex-fill mr-4" style={{ position: 'relative' }}>
                        <input
                          type="text"
                          value={newFilename}
                          onChange={e => setNewFilename(e.target.value)}
                          style={{
                            border: '1px solid #D8E2EE',
                            boxSizing: 'border-box',
                            borderRadius: '6px',
                            padding: '0 5px',
                            height: '30px',
                            width: '100%'
                          }}
                        />
                        <div
                          style={{
                            background: '#000',
                            width: '20px',
                            height: '20px',
                            textAlign: 'center',
                            lineHeight: '17px',
                            color: '#fff',
                            fontWeight: '600',
                            borderRadius: '50%',
                            position: 'absolute',
                            right: '20px',
                            top: '5px',
                            cursor: 'pointer'
                          }}
                          onClick={() => setNewFilename('')}
                        >
                          x
                        </div>
                      </div>
                    ) : (
                      filename
                    )}
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setShowDocumentDetails(!showDocumentDetails);
                      setEditingFile(false);
                    }}
                  >
                    <p
                      style={{
                        fontSize: '16px',
                        color: '#1F77FF',
                        fontWeight: '600',
                        margin: '0',
                        marginRight: '10px'
                      }}
                    >
                      {!showDocumentDetails ? 'View' : 'Close'} Document Details
                    </p>
                    <WetxIcon
                      iconName="arrow"
                      color="black"
                      size="8px"
                      title="Open File"
                      margin="10px"
                      rotate={showDocumentDetails && '90'}
                    />
                  </div>
                </div>
              </div>
            </div>
            {archive ? (
              <WetxArchiveActions toggle={toggle} id={id} />
            ) : (
              <div className="d-flex flex-column" style={{ minWidth: '220px' }}>
                <WetxFileActions
                  toggle={toggle}
                  retrieveFile={retrieveFile}
                  isPinned={isPinned}
                  setIsPinned={setIsPinned}
                  setSharing={setSharing}
                  id={id}
                  client={client}
                  user={user}
                />
                {pages && (
                  <WetxSingleFilePageNavigator
                    currentPage={currentPage}
                    pages={pages}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </div>
            )}
          </div>
          {showDocumentDetails && (
            <WetxDocEdit
              editingFile={editingFile}
              setEditingFile={setEditingFile}
              file={file}
              readOnly={archive && true}
              newFilename={newFilename}
              id_client={client.id_client}
            />
          )}
        </div>
        <Fragment>
          <ModalBody style={{ width: '100%', height: '60%' }}>
            <WetxPdfViewer
              fileUrl={fileUrl}
              initialPage={page}
              currentPage={currentPage}
              initialHighlight={searchValue}
            />
          </ModalBody>
        </Fragment>
      </Modal>

      {file && openDownloadModal && (
        <WetxCloudDownloadIndicator
          openModal={true}
          isDownloading={isDownloading}
          openDownloadModal={openDownloadModal}
          setOpenDownloadModal={setOpenDownloadModal}
          file={file}
        />
      )}
      {file && sharing && <WetxCloudShareModal file={{ file, fileUrl }} openModal={true} setSharing={setSharing} />}
    </div>
  );
};

export default WetxSingleFileView;
