import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import ActionFormatter from '../helpers/ActionFormatter';

import WetxIcon from '../../utilities/WetxIcon';
import WetxCustomConfirm from '../../wetx-utils/WetxCustomConfirm';

import { pinFile } from '../../../redux/files/files.actions';
import store from '../../../redux/state';

import '../../../wetx-styles/wetx.light.css';

const WetxOverlay = ({
  file,
  setSingleFile,
  setTagging,
  setSharing,
  setIsDownloading,
  setViewing,
  setOpenDownloadModal
}) => {
  const [isPinned, setIsPinned] = useState(false);

  const user = useSelector(state => state.auth.attributes);
  const client = useSelector(state => state.auth.clientOrganisation);
  const pinnedDocuments = useSelector(state => state.files.pinnedFiles);

  const checkIfPinned = () => {
    pinnedDocuments.map(doc => doc.id === file.id && setIsPinned(true));
  };

  useEffect(() => {
    if (pinnedDocuments) {
      checkIfPinned();
    }
    // eslint-disable-next-line
  }, [pinnedDocuments]);

  return (
    <div className="dropdown-file__overlay d-flex flex-row justify-content-between align-items-start p-2">
      <div
        className="mt-2 clip-image"
        onClick={() => {
          if (isPinned) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <WetxCustomConfirm
                    title="Are you sure you want to unpin this document?"
                    description="This will remove it from the pinned documents. The document will remain within the vault"
                    onClose={onClose}
                    cancelButtonText="Cancel"
                    confirmButtonText="Yes, Remove Pin"
                    confirmButtonClick={() => {
                      store.dispatch(pinFile(file.id, client.id_client, user.sub));
                      setIsPinned(!isPinned);
                    }}
                  />
                );
              }
            });
          } else {
            store.dispatch(pinFile(file.id, client.id_client, user.sub));
            setIsPinned(!isPinned);
          }
        }}
      >
        <WetxIcon
          iconName="pin"
          size="16px"
          title={isPinned ? 'Unpin File' : 'Pin File'}
          margin="0"
          color="white"
          rotate={isPinned ? true : false}
        />
      </div>
      <div>
        <div className="clip-image">
          <ActionFormatter
            file={file}
            vertical={true}
            setSingleFile={setSingleFile}
            user={user.sub}
            client={client}
            pinnedDocuments={pinnedDocuments}
            setTagging={setTagging}
            setSharing={setSharing}
            setIsDownloading={setIsDownloading}
            setViewing={setViewing}
            setOpenDownloadModal={setOpenDownloadModal}
          />
        </div>
      </div>
    </div>
  );
};

export default WetxOverlay;
