import React from 'react';

import store from '../../redux/state';
import { editDashboard } from '../../redux/dashboards/dashboards.actions';

const WetxDashboardEditActions = () => {
  return (
    <div className={`dashboard-edit-actions`}>
      <div className={`dashboard-edit-actions__buttons`}>
        <button
          onClick={() => {
            // store.dispatch(saveLayout(currentDashboard.id_dashboard, layouts));
            store.dispatch(editDashboard({ editingDashboard: false, saveNewLayout: true }));
          }}
          className="mr-2 btn btn-falcon-default btn-sm"
        >
          Save Changes
        </button>
        <button
          onClick={() => {
            store.dispatch(editDashboard({ editingDashboard: false, saveNewLayout: false }));
          }}
          className="mr-2 btn btn-falcon-default btn-sm"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default WetxDashboardEditActions;
