import { waterActionTypes } from './water.types';

const initialState = {
  waterRights: null,
  waterShares: null,
  orgWaterShares: null,
  loading: false,
  error: null
};

function waterReducer(state = initialState, action) {
  switch (action.type) {
    case waterActionTypes.GET_WATER_RIGHTS_REQUEST:
      return { ...state, loading: true };
    case waterActionTypes.GET_WATER_SHARES_REQUEST:
      return { ...state, loading: true };
    case waterActionTypes.GET_ORG_WATER_SHARES_REQUEST:
      return { ...state, loading: true };
    case waterActionTypes.CREATE_WATER_RIGHTS_REQUEST:
      return { ...state, loading: true };
    case waterActionTypes.CREATE_WATER_SHARES_REQUEST:
      return { ...state, loading: true };
    case waterActionTypes.CREATE_ORG_WATER_SHARES_REQUEST:
      return { ...state, loading: true };

    case waterActionTypes.GET_WATER_RIGHTS_SUCCESS:
      return { ...state, loading: false, waterRights: action.payload };
    case waterActionTypes.GET_WATER_SHARES_SUCCESS:
      return { ...state, loading: false, waterShares: action.payload };
    case waterActionTypes.GET_ORG_WATER_SHARES_SUCCESS:
      return { ...state, loading: false, orgWaterShares: action.payload };
    case waterActionTypes.CREATE_WATER_RIGHTS_SUCCESS: {
      action.payload['priority_date'] = new Date(action.payload['priority_date']).toLocaleDateString();

      if (state.waterRights) {
        state.waterRights = [action.payload, ...state.waterRights];
      } else {
        state.waterRights = [action.payload];
      }

      return Object.assign({}, state);
    }
    case waterActionTypes.CREATE_WATER_SHARES_SUCCESS: {
      action.payload['priority_date'] = new Date(action.payload['priority_date']).toLocaleDateString();

      if (state.waterShares) {
        state.waterShares = [action.payload, ...state.waterShares];
      } else {
        state.waterShares = [action.payload];
      }

      return Object.assign({}, state);
    }
    case waterActionTypes.CREATE_ORG_WATER_SHARES_SUCCESS: {
      action.payload['priority_date'] = new Date(action.payload['priority_date']).toLocaleDateString();

      if (state.orgWaterShares) {
        state.orgWaterShares = [action.payload, ...state.orgWaterShares];
      } else {
        state.orgWaterShares = [action.payload];
      }

      return Object.assign({}, state);
    }

    case waterActionTypes.GET_WATER_RIGHTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case waterActionTypes.GET_WATER_SHARES_ERROR:
      return { ...state, loading: false, error: action.payload };
    case waterActionTypes.GET_ORG_WATER_SHARES_ERROR:
      return { ...state, loading: false, error: action.payload };
    case waterActionTypes.CREATE_WATER_RIGHTS_ERROR:
      return { ...state, loading: true };
    case waterActionTypes.CREATE_WATER_SHARES_ERROR:
      return { ...state, loading: true };
    case waterActionTypes.CREATE_ORG_WATER_SHARES_ERROR:
      return { ...state, loading: true };

    default:
      return state;
  }
}

export default waterReducer;
