import { userActionTypes } from './users.types';

const initialState = {
  orgUsers: [],
  permissions: null,
  roles: null,
  rolePermissions: null,
  shareholders: null,
  loading: false,
  error: null
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.GET_ROLES_REQUEST:
      return { ...state, loading: true };
    case userActionTypes.CREATE_USER_REQUEST:
      return { ...state, loading: true };
    case userActionTypes.GET_ORG_USERS_REQUEST:
      return { ...state, loading: true };
    case userActionTypes.GET_PERMISSIONS_REQUEST:
      return { ...state, loading: true };
    case userActionTypes.GET_SHAREHOLDERS_REQUEST:
      return { ...state, loading: true };
    case userActionTypes.GET_ROLE_PERMISSIONS_REQUEST:
      return { ...state, loading: true };
    case userActionTypes.UPDATE_USER_REQUEST:
      return { ...state, loading: true };
    case userActionTypes.DELETE_ADDITIONAL_PERMISSION_REQUEST:
      return { ...state, loading: true };

    case userActionTypes.GET_ROLES_SUCCESS:
      return { ...state, loading: false, roles: action.payload };
    case userActionTypes.GET_ORG_USERS_SUCCESS: {
      const newOrgUsers = action.payload;
      for (const user of newOrgUsers) {
        if (user.permissions) {
          const formattedPermissions = [];
          for (let permission of user.permissions) {
            permission = JSON.parse(permission);
            formattedPermissions.push(permission);
          }
          user.permissions = formattedPermissions;
        }
        if (user.roles) {
          const formattedRoles = [];
          for (let role of user.roles) {
            role = JSON.parse(role);
            formattedRoles.push(role);
          }
          user.roles = formattedRoles;
        }

        if (user.additional_permissions) {
          const formattedAp = [];
          for (let ap of user.additional_permissions) {
            ap = JSON.parse(ap);
            formattedAp.push(ap);
          }

          user.additional_permissions = formattedAp;
        }
      }

      return Object.assign({}, state, { orgUsers: newOrgUsers, loading: false });
    }
    case userActionTypes.GET_PERMISSIONS_SUCCESS:
      return { ...state, loading: false, permissions: action.payload };
    case userActionTypes.GET_SHAREHOLDERS_SUCCESS:
      return { ...state, loading: false, shareholders: action.payload };
    case userActionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userActionTypes.GET_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        rolePermissions: action.payload
      };
    case userActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userActionTypes.DELETE_ADDITIONAL_PERMISSION_SUCCESS: {
      const userIndex = state.orgUsers.findIndex(user => user.id_user === action.payload.id_user);
      if (userIndex > -1) {
        const editedUser = state.orgUsers[userIndex];
        const filteredAdditionalPermissions = editedUser.additional_permissions.filter(
          perm => perm.id_permission !== action.payload.id_permission
        );
        editedUser.additional_permissions =
          filteredAdditionalPermissions.length === 0 ? null : filteredAdditionalPermissions;

        state.orgUsers[userIndex] = editedUser;
      }

      console.log(state.orgUsers);
      return Object.assign({}, state, { orgUsers: state.orgUsers, loading: false });
    }

    case userActionTypes.GET_ROLES_ERROR:
      return { ...state, loading: false, error: action.payload };
    case userActionTypes.CREATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case userActionTypes.GET_ORG_USERS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case userActionTypes.GET_PERMISSIONS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case userActionTypes.GET_SHAREHOLDERS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case userActionTypes.GET_ROLE_PERMISSIONS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case userActionTypes.UPDATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };
    case userActionTypes.DELETE_ADDITIONAL_PERMISSION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default userReducer;
