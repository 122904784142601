import axios from 'axios';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

require('dotenv').config();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL || 'http://localhost:3333'
});

axiosInstance.interceptors.request.use(
  async config => {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();

    if (token) {
      config.headers['accesstoken'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // The request was made and the server responded with a status code
      if (error.response.status === 401) {
        alert('Unauthorized');
      }
      console.log(error.response.data);
      toast.error(error.response.data.message);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error);
    }
    console.log(error.config);
    // whatever you want to do with the error
    // throw error;
    return Promise.reject(error);
  }
);

export default axiosInstance;
