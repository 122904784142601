import React, { useState } from 'react';
import _ from 'lodash';

const WetxInput = props => {
  const [focused, setFocused] = useState(props.focused || false);
  return (
    <div style={{ height: '60px', width: '100%' }} className={`mt-3 ${props.className}`}>
      <div style={{ width: '100%' }} className="input__container">
        <label htmlFor={props.title} className={`mr-3 mb-0 ${props.centerclass} input__title ${focused && 'focused'}`}>
          {_.startCase(props.title)}
        </label>
        <input
          {...props}
          placeholder={focused ? props.placeholder : ''}
          id={props.title}
          style={{
            ...props.style,
            border: `${
              props.readOnly
                ? 'none'
                : props.errors && props.errors[props.errorcheck]
                ? '1px solid red'
                : '1px solid #d8e2ef'
            }`,
            padding: '0 5px',
            borderRadius: '3px',
            width: '100%',
            maxWidth: '100%',
            marginLeft: 'auto'
          }}
          onFocus={() => setFocused(true)}
        />
      </div>
      {props.errors && props.errors[props.errorcheck] && (
        <div style={{ color: 'red', fontSize: '12px' }} className="text-center">
          {props.errors[props.errorcheck]}
        </div>
      )}
    </div>
  );
};

export default WetxInput;
