import { cabinetsActionTypes } from './cabinets.types';
import axiosInstance from '../helpers/axios-instance';

export const getFolders = id_client => async dispatch => {
  try {
    dispatch({ type: cabinetsActionTypes.LOAD_CABINETS_REQUEST });

    const response = await axiosInstance.get(`/cabinets/${id_client}`);

    dispatch({
      type: cabinetsActionTypes.LOAD_CABINETS_SUCCESS,
      payload: response.data.folders
    });

    return response.data.files;
  } catch (error) {
    dispatch({
      type: cabinetsActionTypes.LOAD_CABINETS_SUCCESS,
      payload: error.message
    });
  }
};

export const createFolder = (id_client, category_name) => async dispatch => {
  try {
    dispatch({ type: cabinetsActionTypes.CREATE_CABINET_REQUEST });
    const response = await axiosInstance.post('/cabinets', {
      id_client,
      category_name
    });
    dispatch({
      type: cabinetsActionTypes.CREATE_CABINET_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: cabinetsActionTypes.CREATE_CABINET_ERROR,
      payload: error.message
    });
  }
};

export const updateFolder = (id, id_client, category_name) => async dispatch => {
  try {
    dispatch({ type: cabinetsActionTypes.UPDATE_CABINET_REQUEST });
    const response = await axiosInstance.patch(`/cabinets/${id}`, {
      id_client,
      category_name
    });
    dispatch({
      type: cabinetsActionTypes.UPDATE_CABINET_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: cabinetsActionTypes.UPDATE_CABINET_ERROR,
      payload: error.message
    });
  }
};

export const deleteFolder = id => async dispatch => {
  try {
    dispatch({ type: cabinetsActionTypes.DELETE_CABINET_REQUEST });
    await axiosInstance.delete(`/cabinets/${id}`);
    dispatch({
      type: cabinetsActionTypes.DELETE_CABINET_SUCCESS,
      payload: { id_category: id }
    });
  } catch (error) {
    dispatch({
      type: cabinetsActionTypes.DELETE_CABINET_ERROR,
      payload: error.message
    });
  }
};
