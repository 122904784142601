import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import WETxSettingsChangePassword from '../Settings/WETxSettingsChangePassword';
import WETxSettingsProfile from '../Settings/WETxSettingsProfile';
import ItemBanner from '../item/ItemBanner';

import bg from '../../assets/img/wetx/avwsid.jpg';

import WETxUserImage from '../common/WETxUserImage';

const SettingsAside = () => (
  <Fragment>
    <WETxSettingsChangePassword />
  </Fragment>
);

const SettingsBanner = () => {
  const { sub } = useSelector(state => state.auth.attributes);
  const avatar = WETxUserImage(sub);
  return (
    <ItemBanner>
      <ItemBanner.Header avatarSrc={avatar} coverSrc={bg} isEditable />
    </ItemBanner>
  );
};

const SettingsContent = () => (
  <Fragment>
    <WETxSettingsProfile />
  </Fragment>
);

const WETxSettings = () => {
  return (
    <ContentWithAsideLayout banner={<SettingsBanner />} aside={<SettingsAside />}>
      <SettingsContent />
    </ContentWithAsideLayout>
  );
};

export default WETxSettings;
