import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import WetxUploaderSingleSelect from './WetxUploaderSingleSelect';
import WetxDateRange from './WetxDateRange';
import WetxSingleTag from '../../wetx-utils/WetxSingleTag';
import WetxAddTagModal from '../WetxAddTagModal';

import '../../../wetx-styles/wetx.light.css';

import { updateFile, removeTagFromFile } from '../../../redux/files/files.actions';
import store from '../../../redux/state';

const WetxDocEdit = ({ editingFile, setEditingFile, file, newFilename, id_client, readOnly }) => {
  const [fileTags, setFileTags] = useState(file.user_tags ? JSON.parse(file.user_tags) : null);
  const [currentFile, setCurrentFile] = useState(file);
  const [tagging, setTagging] = useState(false);

  const types = useSelector(state => state.files.types);
  const categories = useSelector(state => state.cabinets.items);

  const removeFileTag = id_tag => {
    store.dispatch(removeTagFromFile(file.id, id_tag));
    setFileTags([...fileTags.filter(tag => tag.file !== file.id && tag.id_tag !== id_tag)]);
  };

  const updateFileTags = tags => {
    tags.length > 0 ? setFileTags(tags) : setFileTags(null);
  };

  const addCategory = e => {
    file['id_category'] = parseInt(e.id_category);
    file['category_name'] = e.category_name;
    setCurrentFile(file);
  };

  const addType = e => {
    file['id_type'] = parseInt(e.id_type);
    file['type_name'] = e.type_name;
    setCurrentFile(file);
  };

  const addStartDate = e => {
    file['start_date'] = e;
    setCurrentFile(file);
  };

  const addEndDate = e => {
    file['end_date'] = e;
    setCurrentFile(file);
  };

  const saveFile = () => {
    file['filename'] = newFilename;
    file['types'] = types;
    setCurrentFile(file);
    store.dispatch(updateFile(file));
  };

  const hasTags = fileTags !== null && fileTags !== 'null';

  return (
    <div className="doc-edit__container d-flex flex-row">
      <div className="doc-edit__info flex-fill d-flex flex-row align-items-center">
        <div className="doc-edit__info-main" style={{ minWidth: '35%' }}>
          <div className="doc-edit__info-display">
            <div className="doc-edit__text d-flex flex-row align-items-center">
              <strong>Date: </strong>
              {editingFile ? (
                <WetxDateRange
                  startDate={currentFile.start_date}
                  addStartDate={addStartDate}
                  endDate={currentFile.end_date}
                  addEndDate={addEndDate}
                />
              ) : currentFile.start_date ? (
                <div className="d-flex flex-row">
                  <p className="doc-edit__text">{new Date(currentFile.start_date).toLocaleDateString()}</p>
                  {currentFile.end_date && (
                    <div className="d-flex flex-row">
                      to <p className="doc-edit__text">{new Date(currentFile.end_date).toLocaleDateString()}</p>
                    </div>
                  )}
                </div>
              ) : (
                'File has no date'
              )}
            </div>
            <div className="doc-edit__text d-flex flex-row align-items-center">
              <strong>Type: </strong>
              {editingFile ? (
                <WetxUploaderSingleSelect
                  title="Select Type"
                  options={types}
                  objSelector="id_type"
                  objTitle="type_name"
                  currentOption={
                    currentFile.id_type && { id_type: currentFile.id_type, type_name: currentFile.type_name }
                  }
                  handleChange={addType}
                />
              ) : currentFile.type_name ? (
                currentFile.type_name
              ) : (
                'File has no type'
              )}
            </div>
            <div className="doc-edit__text d-flex flex-row align-items-center">
              <strong>Cabinet: </strong>
              {editingFile ? (
                <WetxUploaderSingleSelect
                  title="Select Category"
                  options={categories}
                  objSelector="id_category"
                  objTitle="category_name"
                  currentOption={
                    currentFile.id_category && {
                      id_category: currentFile.id_category,
                      category_name: currentFile.category_name
                    }
                  }
                  handleChange={addCategory}
                />
              ) : (
                currentFile.category_name
              )}
            </div>
          </div>
        </div>
        <div className="doc-edit__info-spec d-flex flex-column justify-content-between">
          <p className="doc-edit__text">
            <strong>Block-Chain ID: </strong>
          </p>
          <div className="d-flex flex-row">
            <p className="doc-edit__text">
              <strong>OCR Status: </strong>
              {file.textract_finished || file.avgConfidence ? 'Done' : 'Pending'}
            </p>
            {(file.textract_finished || file.avgConfidence) && (
              <p className="doc-edit__text">
                <strong>OCR Confidence Score: </strong>
                {file.avgConfidence ? file.avgConfidence : file.textract_meta[0].avgConfidence}%
              </p>
            )}
          </div>
          <div className="doc-edit__info-spec__tags doc-edit__text d-flex flex-row">
            <strong style={{ minWidth: '80px', marginTop: '5px' }}>Applied labels: </strong>
            <div className="d-flex flex-row align-items-center flex-wrap doc-edit__tags">
              {editingFile && (
                <div className="doc-edit__tag-button mr-2" onClick={() => setTagging(true)}>
                  Add label
                </div>
                // TODO: Add Label action to the tag itself below.
              )}
              {hasTags ? (
                fileTags.map((tag, index) => (
                  <WetxSingleTag key={index} tag={tag} removeTagFromFile={editingFile && removeFileTag} />
                ))
              ) : (
                <div className="doc-edit__text" style={{ marginTop: '5px' }}>
                  File has no labels
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!readOnly && (
        <div className="doc-edit__actions">
          <p
            className="doc-edit__btn"
            onClick={() => {
              if (editingFile) {
                saveFile();
              }
              setEditingFile(!editingFile);
            }}
          >
            {editingFile ? 'Save' : 'Edit'}
          </p>
        </div>
      )}
      {editingFile && tagging && (
        <WetxAddTagModal file={file} setTagging={setTagging} id_client={id_client} updateFileTags={updateFileTags} />
      )}
    </div>
  );
};

export default WetxDocEdit;
