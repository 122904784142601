import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

import WetxIcon from '../utilities/WetxIcon';
import WetxManageTagsModal from './WetxManageTagsModal';
// import PermissionProvider from '../wetx-acl/PermissionProvider';

import store from '../../redux/state';
import { editDashboard } from '../../redux/dashboards/dashboards.actions';

const WetxSettingsDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tagManagementOpen, setTagManagementOpen] = useState(false);

  const client = useSelector(state => state.auth.clientOrganisation);

  const history = useHistory();

  let timer;

  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <div>
      <Dropdown
        nav
        inNavbar
        isOpen={dropdownOpen}
        toggle={toggle}
        onMouseOver={() => {
          let windowWidth = window.innerWidth;
          windowWidth > 992 && setDropdownOpen(true);
        }}
        onMouseLeave={() => {
          let windowWidth = window.innerWidth;
          timer = setTimeout(() => {
            windowWidth > 992 && setDropdownOpen(false);
          }, 500);
        }}
      >
        <DropdownToggle nav className="pr-0">
          <WetxIcon iconName="gear" color="gray" size="25px" title="Open File" margin="10px" />
        </DropdownToggle>
        <DropdownMenu
          right
          className="dropdown-menu-card mr-2 pb-2"
          onMouseOver={() => {
            clearTimeout(timer);
          }}
        >
          <div className="bg-white rounded-soft py-2">
            {history.location.pathname.includes('/dash') && (
              <DropdownItem
                className="d-flex flex-row align-items-center"
                onClick={() => store.dispatch(editDashboard({ editingDashboard: true, saveNewLayout: false }))}
              >
                <WetxIcon iconName="edit" size="16px" title={'Edit layout'} margin="5px" color={'gray'} />
                Edit Layout
              </DropdownItem>
            )}
            <DropdownItem className="d-flex flex-row align-items-center" onClick={() => setTagManagementOpen(true)}>
              <WetxIcon iconName="tag" size="16px" title={'Label management'} margin="5px" color={'gray'} />
              Label Management
            </DropdownItem>
            <DropdownItem className="d-flex flex-row align-items-center" tag={Link} to="/cloud/archive">
              <WetxIcon iconName="vault" size="16px" title={'Archived Documents'} margin="5px" color={'gray'} />
              Archived Documents
            </DropdownItem>
            {/* <PermissionProvider
              necessaryPermissions={['manage_users']}
              hasPermissionComponent={
                <DropdownItem className="d-flex flex-row align-items-center" tag={Link} to="/users/manage">
                  <WetxIcon iconName="users" size="16px" title={'Manage Users'} margin="5px" color={'gray'} />
                  Manage Users
                </DropdownItem>
              }
              noPermissionFallback={<div />}
            /> */}
          </div>
        </DropdownMenu>
      </Dropdown>
      {tagManagementOpen && (
        <WetxManageTagsModal
          openModal={true}
          id_client={client.id_client}
          setTagManagementOpen={setTagManagementOpen}
        />
      )}
    </div>
  );
};

export default WetxSettingsDropdown;
