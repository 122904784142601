import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';

import WetxArchivePage from '../components/wetx-archive/WetxArchivePage';
import WetxCloudDash from '../components/wetx-cloud/WetxCloudDash';
import WetxSearchPage from '../components/wetx-cloud/WetxSearchPage';
import { getPinnedFiles, getRecentFiles } from '../redux/files/files.actions';
import store from '../redux/state';

const CloudLayout = () => {
  const user = useSelector(state => state.auth.attributes.sub);
  const client = useSelector(state => state.auth.clientOrganisation);

  useEffect(() => {
    if (user) {
      store.dispatch(getRecentFiles(user));
    }

    if (client) {
      store.dispatch(getPinnedFiles(client.id_client));
    }

    //eslint-disable-next-line
  }, [user, client]);

  return (
    <div>
      <Switch>
        <Route
          render={({ match: { url } }) => {
            return (
              <>
                <Route path={`${url}/`} exact component={WetxCloudDash} />
                <Route path={`${url}/archive`} exact component={WetxArchivePage} />
                <Route path={`${url}/search`} exact component={WetxSearchPage} />
              </>
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default CloudLayout;
