import { toast } from 'react-toastify';

import axiosInstance from '../helpers/axios-instance';
import { dashboardsActionTypes } from './dashboards.types';

export const loadDashboards = () => async dispatch => {
  try {
    dispatch({ type: dashboardsActionTypes.LOAD_DASHBOARDS_REQUEST });
    const response = await axiosInstance.get('/dashboards');

    dispatch({
      type: dashboardsActionTypes.LOAD_DASHBOARDS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: dashboardsActionTypes.LOAD_DASHBOARDS_ERROR, payload: error.message });
  }
};

export const clearState = () => dispatch => {
  dispatch({ type: dashboardsActionTypes.CLEAR_STATE_SUCCESS });
};

export const editDashboard = editLayout => dispatch => {
  dispatch({ type: dashboardsActionTypes.EDIT_DASHBOARD_LAYOUT, payload: editLayout });
};

export const saveLayout = (idDashboard, layout) => async dispatch => {
  try {
    dispatch({ type: dashboardsActionTypes.SAVE_LAYOUT_REQUEST });
    const response = await axiosInstance.post(`/dashboards/${idDashboard}/save-layout`, { layout });
    if (response) {
      toast.success(response.data.message);
    }
    dispatch({ type: dashboardsActionTypes.SAVE_LAYOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: dashboardsActionTypes.SAVE_LAYOUT_ERROR, payload: error });
  }
};
