import { elasticActionTypes } from './elastic.types';

const initialState = {
  foundElastic: [],
  loading: false,
  error: null
};

function elasticReducer(state = initialState, action) {
  switch (action.type) {
    case elasticActionTypes.DEEP_SEARCH_REQUEST:
      return { ...state, loading: true };

    case elasticActionTypes.DEEP_SEARCH_SUCCESS:
      return { ...state, foundElastic: action.payload, loading: false };

    case elasticActionTypes.DEEP_SEARCH_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default elasticReducer;
