import { toast } from 'react-toastify';

import axiosInstance from '../helpers/axios-instance';
import { waterActionTypes } from './water.types';

export const getWaterRights = id_client => async dispatch => {
  try {
    dispatch({ type: waterActionTypes.GET_WATER_RIGHTS_REQUEST });

    const response = await axiosInstance.get(`/water/water-rights/${id_client}`);

    dispatch({ type: waterActionTypes.GET_WATER_RIGHTS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: waterActionTypes.GET_WATER_RIGHTS_ERROR, payload: error });
  }
};

export const getWaterShares = id_client => async dispatch => {
  try {
    dispatch({ type: waterActionTypes.GET_WATER_SHARES_REQUEST });

    const response = await axiosInstance.get(`/water/water-shares/${id_client}`);

    dispatch({ type: waterActionTypes.GET_WATER_SHARES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: waterActionTypes.GET_WATER_SHARES_ERROR, payload: error });
  }
};

export const getOrgWaterShares = id_client => async dispatch => {
  try {
    dispatch({ type: waterActionTypes.GET_ORG_WATER_SHARES_REQUEST });

    const response = await axiosInstance.get(`/water/org-water-shares/${id_client}`);

    dispatch({ type: waterActionTypes.GET_ORG_WATER_SHARES_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: waterActionTypes.GET_ORG_WATER_SHARES_ERROR, payload: error });
  }
};

export const createWaterEntry = (id_client, table, saveObj) => async dispatch => {
  try {
    if (table === 'water_rights') {
      dispatch({ type: waterActionTypes.CREATE_WATER_RIGHTS_REQUEST });
    }
    if (table === 'water_shares') {
      dispatch({ type: waterActionTypes.CREATE_WATER_SHARES_REQUEST });
    }
    if (table === 'org_water_shares') {
      dispatch({ type: waterActionTypes.CREATE_ORG_WATER_SHARES_REQUEST });
    }

    const response = await axiosInstance.post(`/water/${id_client}`, { saveObj, table });
    toast.success(response.data.message);

    if (table === 'water_rights') {
      dispatch({ type: waterActionTypes.CREATE_WATER_RIGHTS_SUCCESS, payload: saveObj });
    }
    if (table === 'water_shares') {
      dispatch({ type: waterActionTypes.CREATE_WATER_SHARES_SUCCESS, payload: saveObj });
    }
    if (table === 'org_water_shares') {
      dispatch({ type: waterActionTypes.CREATE_ORG_WATER_SHARES_SUCCESS, payload: saveObj });
    }
  } catch (error) {
    if (table === 'water_rights') {
      dispatch({ type: waterActionTypes.CREATE_WATER_RIGHTS_ERROR, payload: error });
    }
    if (table === 'water_shares') {
      dispatch({ type: waterActionTypes.CREATE_WATER_SHARES_ERROR, payload: error });
    }
    if (table === 'org_water_shares') {
      dispatch({ type: waterActionTypes.CREATE_ORG_WATER_SHARES_ERROR, payload: error });
    }
  }
};
