import React, { useEffect, Fragment } from 'react';
import { Badge, Progress, Modal } from 'reactstrap';

import WetxIcon from '../utilities/WetxIcon';

const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const WetxCloudDownloadIndicator = ({ file: { filename, tags }, isDownloading, openModal, setOpenDownloadModal }) => {
  useEffect(() => {
    if (!isDownloading) {
      const timer = setTimeout(() => {
        setOpenDownloadModal(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [isDownloading]);

  return (
    <Modal className="modal-lg" isOpen={openModal} centered={true}>
      <div
        className="modal-header d-flex flex-row justify-content-between"
        style={{ borderBottom: '1px solid #c7c7c7c7', fontSize: '0.8em' }}
      >
        <div className="modal-header__Meta flex-start d-inline-flex">
          <WetxIcon iconName="document" color="blue" size="45px" title="Open File" margin="10px" />
          <div className="modal-header__Meta__text d-flex flex-column">
            <div style={{ fontSize: '1.2rem' }}>
              <strong>Name: </strong>
              {filename.split('/')[filename.split('/').length - 1]}
            </div>
            {tags && (
              <div className="d-inline-flex">
                {tags.DOCTYPE && (
                  <div style={{ marginRight: '10px' }}>
                    <strong>Type: </strong>
                    {capitalize(tags.DOCTYPE.replace(/[^a-z, ^1-9]+/g, ' '))}
                  </div>
                )}
                <div style={{ marginRight: '10px' }}>
                  <strong>Location: </strong>
                  TBD
                </div>
                {tags.YEAR && (
                  <div style={{ marginRight: '10px' }}>
                    <strong>Document Date: </strong>
                    {tags.YEAR.length > 5 ? (
                      <Fragment>
                        {tags.YEAR.split(':')[0]} - {tags.YEAR.split(':')[tags.YEAR.split(':').length - 1]}
                      </Fragment>
                    ) : (
                      <Fragment>{tags.YEAR}</Fragment>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="modal-header__Actions flex-end d-flex flex-row"
          style={{ fontSize: '1.2rem', minWidth: '120px' }}
        />
      </div>

      <div className="d-flex flex-column">
        <Progress
          bar
          animated
          className="mb-3 wetx-download-indicator float-left"
          color="success"
          style={{ height: '1.2rem', fontWeight: 'bold', color: '#f5f5f5' }}
        />
        {isDownloading ? (
          <Badge className="mb-3 wetx-download-indicator_percentage" color="primary" />
        ) : (
          <Badge style={{ cursor: 'pointer' }} className="mb-3" color="primary">
            File Downloaded, closing
          </Badge>
        )}
      </div>
    </Modal>
  );
};

export default WetxCloudDownloadIndicator;
