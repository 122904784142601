import React, { useState } from 'react';

import AdminHeadControls from './utils/AdminHeadControls';
import BucketList from './components/BucketList';
import BucketForm from './components/BucketForm';

import '../../wetx-styles/admin.css';

const WetxAdminBuckets = () => {
  const [showCreateBucket, setShowCreateBucket] = useState(false);

  return (
    <div className="card flex-fill d-flex flex-column align-items-center">
      <div className="management-header w-100 text-center">Bucket Management</div>
      <AdminHeadControls
        buttons={[
          { label: 'List Buckets', action: setShowCreateBucket, value: false, id: 'list' },
          { label: 'Create Bucket', action: setShowCreateBucket, value: true, id: 'create' }
        ]}
      />
      {showCreateBucket ? <BucketForm /> : <BucketList />}
    </div>
  );
};

export default WetxAdminBuckets;
