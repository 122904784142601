import React, { useState } from 'react';

import '../../../wetx-styles/wetx.light.css';

const WetxPager = ({ dataGridRef, filesNumber, currentPageSize, allowedPageSizes, currentItemCount }) => {
  const [pageSize, setPageSize] = useState(currentPageSize || 10);
  const [pageIndex, setPageIndex] = useState(0);

  const nextBtn = React.createRef();
  const backBtn = React.createRef();

  const calcToPages = () => {
    return currentItemCount !== filesNumber
      ? (pageIndex + 1) * pageSize > currentItemCount
        ? currentItemCount
        : (pageIndex + 1) * pageSize
      : (pageIndex + 1) * pageSize > filesNumber
      ? filesNumber
      : (pageIndex + 1) * pageSize;
  };

  return (
    <div className="p-2 d-flex flex-row justify-content-between align-items-center w-100">
      <div className="d-flex flex-row align-items-center">
        <div>
          <select
            className="mr-3 wetx-pager__page-sizes"
            defaultValue={pageSize}
            onChange={e => {
              dataGridRef.current.instance.pageSize(parseInt(e.target.value));
              setPageSize(parseInt(e.target.value));
            }}
          >
            {allowedPageSizes.map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="wetx-pager__text d-flex align-items-center">
          Page: {pageIndex + 1} | Files: {pageIndex * pageSize + 1} - {calcToPages()} of{' '}
          {currentItemCount && currentItemCount !== filesNumber ? currentItemCount : filesNumber}
        </div>
      </div>

      <div className="pager-button__controls flex-end">
        <div
          className="pager-button__back disabled"
          ref={backBtn}
          onClick={e => {
            const index = dataGridRef.current.instance.pageIndex();

            if (index <= 0) {
              return e.preventDefault();
            }

            dataGridRef.current.instance.pageIndex(index - 1);
            setPageIndex(index - 1);
            nextBtn.current.classList.contains('disabled') && nextBtn.current.classList.remove('disabled');

            const nextIndex = dataGridRef.current.instance.pageIndex();
            if (nextIndex <= 0) {
              e.preventDefault();
              return e.target.classList.add('disabled');
            }
          }}
        >
          Back
        </div>
        <div
          className={`pager-button__next ${(filesNumber <= pageSize || currentItemCount <= pageSize) && 'disabled'}`}
          ref={nextBtn}
          onClick={e => {
            const index = dataGridRef.current.instance.pageIndex();
            const pages = dataGridRef.current.instance.pageCount();

            if (index + 1 === pages) {
              return e.preventDefault();
            }

            dataGridRef.current.instance.pageIndex(index + 1);
            setPageIndex(index + 1);
            backBtn.current.classList.contains('disabled') && backBtn.current.classList.remove('disabled');

            const nextIndex = dataGridRef.current.instance.pageIndex();
            if (nextIndex + 1 === pages || (currentItemCount !== filesNumber && currentItemCount <= pageSize)) {
              e.preventDefault();
              return e.target.classList.add('disabled');
            }
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default WetxPager;
