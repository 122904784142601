import { clientActionTypes } from './client.types';

const initialState = {
  clients: [],
  loading: false,
  error: null
};

function adminClientsReducer(state = initialState, action) {
  switch (action.type) {
    // REQUEST TYPES
    case clientActionTypes.LOAD_CLIENTS_REQUEST:
      return { ...state, loading: true };
    case clientActionTypes.CREATE_CLIENT_REQUEST:
      return { ...state, loading: true };

    // SUCCESS TYPES
    case clientActionTypes.LOAD_CLIENTS_SUCCESS:
      return { ...state, loading: false, clients: action.payload };
    case clientActionTypes.CREATE_CLIENT_SUCCESS:
      return { ...state, loading: false, clients: [...state.clients, action.payload] };

    // ERROR TYPES
    case clientActionTypes.LOAD_CLIENTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case clientActionTypes.CREATE_CLIENT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default adminClientsReducer;
