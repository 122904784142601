import React from 'react';

import { Card, Row } from 'reactstrap';
const WetxWidgetSimpleLink = ({ options: { bgImage, widgetImage, linkTo, title, subtitle } }) => {
  return (
    <Card
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: 'center',
        backgroundOrigin: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        maxWidth: '600px',
        maxHeight: '400px'
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <Row className="d-flex flex-row justify-content-center align-items-center p-2" style={{ width: '100%' }}>
        {widgetImage ? (
          <img className="mr-2" src={`${widgetImage}`} alt="" style={{ maxWidth: '120px', maxHeight: '60px' }} />
        ) : null}
        <div onClick={() => window.open(`${linkTo}`, '_blank')} className="text-center mb-1">
          <p style={{ marginBottom: '1px' }}>{subtitle}</p>
          <h5>{title}</h5>
        </div>
      </Row>
    </Card>
  );
};

export default WetxWidgetSimpleLink;
