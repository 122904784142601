import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Will redirect to first dynamic dashboard page
const WETXRootDash = () => {
  const dashboards = useSelector(state => state.dashboards.items);

  if (dashboards && dashboards.length === 0) {
    return <span>loading...</span>;
  }

  return <Redirect to={`/dash/${dashboards[0].slug}`} />;
};

export default WETXRootDash;
