import axios from 'axios';
import axiosInstance from '../helpers/axios-instance';
import { widgetsActionTypes } from './widgets.types';

export const loadWidgets = () => async dispatch => {
  try {
    dispatch({ type: widgetsActionTypes.LOAD_WIDGETS_REQUEST });

    const response = await axiosInstance.get('/widgets');

    dispatch({
      type: widgetsActionTypes.LOAD_WIDGETS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({ type: widgetsActionTypes.LOAD_WIDGETS_ERROR, payload: error.message });
  }
};

export const loadWidgetData = idWidget => async dispatch => {
  try {
    dispatch({ type: widgetsActionTypes.LOAD_WIDGET_DATA_REQUEST });
    const response = await axiosInstance.get(`/widgets/${idWidget}/data`);

    dispatch({
      type: widgetsActionTypes.LOAD_WIDGET_DATA_SUCCESS,
      payload: { idWidget, data: response.data }
    });
    return response.data;
  } catch (error) {
    dispatch({ type: widgetsActionTypes.LOAD_WIDGET_DATA_ERROR, payload: error.message });
  }
};

export const getChartDataFromUSGS = async (startDate, endDate) => {
  const res = await axios.get(
    `https://waterservices.usgs.gov/nwis/dv/?format=json&sites=09266500&startDT=${startDate}&endDT=${endDate}&siteStatus=all`
  );

  const resJson = JSON.parse(res.request.response);
  if (resJson.value.timeSeries[0]) {
    const initialArray = resJson.value.timeSeries[0].values[0].value;
    const xAxisLegend = initialArray.map(item => item.dateTime.slice(0, 10));
    const valuesArray = initialArray.map(item => item.value);
    const { sourceInfo } = resJson.value.timeSeries[0];
    const { variable } = resJson.value.timeSeries[0];

    const chartOption = {
      responsive: true,
      title: {
        text: sourceInfo.siteName,
        subtext: variable.variableDescription,
        left: 'center'
      },
      tooltip: {
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        name: 'Date',
        data: xAxisLegend
      },
      yAxis: {
        type: 'value',
        name: 'Value [ ft^3/s ]'
      },
      // 006994 anothr usable blue color
      color: ['#48b5e0'],
      series: [
        {
          data: valuesArray,
          type: 'line'
          // smooth: true
        }
      ]
    };

    return chartOption;
  } else {
    return null;
  }
};
