import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Form } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import FormGroupInput from '../common/FormGroupInput';

import { Auth } from 'aws-amplify';

const WETxSettingsChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (oldPassword === '' || newPassword === '' || confirmPassword === '') return setIsDisabled(true);

    setIsDisabled(newPassword !== confirmPassword);
  }, [oldPassword, newPassword, confirmPassword]);

  const handleSubmit = e => {
    e.preventDefault();

    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then(data => {
        setSuccess(data);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch(err => setError(err.message));
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Change Password" light={false} />
      <CardBody className="bg-light">
        <Form onSubmit={handleSubmit}>
          <FormGroupInput
            id="old-password"
            label="Old Password"
            value={oldPassword}
            onChange={({ target }) => setOldPassword(target.value)}
            type="password"
          />
          <FormGroupInput
            id="new-password"
            label="New Password"
            value={newPassword}
            onChange={({ target }) => setNewPassword(target.value)}
            type="password"
          />
          <FormGroupInput
            id="confirm-password"
            label="Confirm Password"
            value={confirmPassword}
            onChange={({ target }) => setConfirmPassword(target.value)}
            type="password"
          />
          <Button color="primary" block disabled={isDisabled}>
            Update Password
          </Button>
        </Form>
        <br />
        <div className="text-danger fs--1 text-center">{error}</div>
        <div className="text-success fs--1 text-center">{success}</div>
      </CardBody>
    </Card>
  );
};

export default WETxSettingsChangePassword;
