import React from 'react';
import { Link } from 'react-router-dom';

import WetxIcon from '../../utilities/WetxIcon';

import store from '../../../redux/state';
import { viewFile, saveNewRecentFile } from '../../../redux/files/files.actions';

const shortenFilename = filename => {
  if (filename.length > 50) {
    const firstPart = filename.substring(0, 25);
    const secondPart = filename.substring(filename.length - 25);
    return firstPart + '...' + secondPart;
  }
  return filename;
};

const fileFormatter = ({
  file,
  user,
  setSingleFile,
  setViewing,
  previewFile,
  previewPos,
  setPreviewFile,
  setPreviewPos
}) => {
  const { filename, id, pages, page } = file;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <WetxIcon iconName="document" color="blue" size="22px" title="Open File" margin="10px" />
      <Link
        to="#"
        onClick={async e => {
          e.preventDefault();
          store.dispatch(saveNewRecentFile(id, user));
          const fileUrl = await store.dispatch(viewFile(file));
          setSingleFile({ file, fileUrl, pages: pages && pages.length > 1 && pages, page: page && page });
          setViewing(true);
          setPreviewFile(null);
          setPreviewPos({ x: 0, y: 0 });
        }}
        onContextMenu={e => {
          e.preventDefault();
          if (!previewFile) {
            setPreviewFile(file);
            setPreviewPos({ x: e.clientX, y: e.clientY });
          }
        }}
        onMouseOut={e => {
          setPreviewFile(null);
          setPreviewPos({ x: 0, y: 0 });
        }}
        onMouseOver={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        className="font-weight-semi-bold"
      >
        {shortenFilename(filename)}
      </Link>
      {previewFile && (
        <div
          className="text-center"
          onClick={() => {
            setPreviewFile(null);
            setPreviewPos({ x: 0, y: 0 });
          }}
          style={{
            position: 'fixed',
            top: previewPos.y + 20,
            left: previewPos.x,
            width: '400px',
            height: 'auto',
            border: '1px solid #e4e4e4',
            padding: '5px',
            background: '#fff',
            borderRadius: '6px',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            zIndex: '1097'
          }}
        >
          {previewFile.filename}
        </div>
      )}
    </div>
  );
};

export default fileFormatter;
