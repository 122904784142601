import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Navbar, Nav, Badge } from 'reactstrap';
import classNames from 'classnames';
import AppContext from '../../context/Context';
import Logo from './Logo';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';

import Loader from '../common/Loader';

const NavbarTop = () => {
  const {
    showBurgerMenu,
    setShowBurgerMenu,
    isTopNav,
    isVertical,
    isCombo,
    navbarCollapsed,
    setNavbarCollapsed
  } = useContext(AppContext);
  const handleBurgerMenu = () => {
    isTopNav && !isCombo && setNavbarCollapsed(!navbarCollapsed);
    (isCombo || isVertical) && setShowBurgerMenu(!showBurgerMenu);
  };

  const { clientOrganisation, loading } = useSelector(state => state.auth);

  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand={isTopNav && topNavbarBreakpoint}
    >
      <div
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav && !isCombo,
          [`d-${navbarBreakPoint}-none`]: isVertical || isCombo
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <Logo at="navbar-top" width={135} id="topLogo" />
      {isTopNav && (
        <Collapse navbar isOpen={navbarCollapsed} className="scrollbar">
          <Nav navbar>
            <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>
        </Collapse>
      )}
      {loading ? (
        <Loader />
      ) : clientOrganisation ? (
        <Badge color="primary" className="d-sm-none d-lg-block">
          <div style={{ fontSize: '16px' }}>{clientOrganisation.name}</div>
        </Badge>
      ) : (
        <Badge color="primary" className="d-sm-none d-lg-block">
          <div style={{ fontSize: '16px' }}>WETx Administrator</div>
        </Badge>
      )}
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
