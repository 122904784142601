import { clientActionTypes } from './client.types';
import axiosInstance from '../../helpers/axios-instance';

export const loadClients = () => async dispatch => {
  try {
    dispatch({ type: clientActionTypes.LOAD_CLIENTS_REQUEST });
    const res = await axiosInstance.get('/admin/clients');
    dispatch({ type: clientActionTypes.LOAD_CLIENTS_SUCCESS, payload: res.data.clients });
  } catch (error) {
    dispatch({ type: clientActionTypes.LOAD_CLIENTS_ERROR, payload: error });
  }
};

export const createClient = () => async dispatch => {
  try {
    dispatch({ type: clientActionTypes.CREATE_CLIENT_REQUEST });
  } catch (error) {
    dispatch({ type: clientActionTypes.CREATE_CLIENT_ERROR, payload: error });
  }
};
