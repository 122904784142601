export const waterActionTypes = {
  GET_WATER_RIGHTS_REQUEST: 'GET_WATER_RIGHTS_REQUEST',
  GET_WATER_SHARES_REQUEST: 'GET_WATER_SHARES_REQUEST',
  GET_ORG_WATER_SHARES_REQUEST: 'GET_ORG_WATER_SHARES_REQUEST',
  CREATE_WATER_RIGHTS_REQUEST: 'CREATE_WATER_RIGHTS_REQUEST',
  CREATE_WATER_SHARES_REQUEST: 'CREATE_WATER_SHARES_REQUEST',
  CREATE_ORG_WATER_SHARES_REQUEST: 'CREATE_ORG_WATER_SHARES_REQUEST',

  GET_WATER_RIGHTS_SUCCESS: 'GET_WATER_RIGHTS_SUCCESS',
  GET_WATER_SHARES_SUCCESS: 'GET_WATER_SHARES_SUCCESS',
  GET_ORG_WATER_SHARES_SUCCESS: 'GET_ORG_WATER_SHARES_SUCCESS',
  CREATE_WATER_RIGHTS_SUCCESS: 'CREATE_WATER_RIGHTS_SUCCESS',
  CREATE_WATER_SHARES_SUCCESS: 'CREATE_WATER_SHARES_SUCCESS',
  CREATE_ORG_WATER_SHARES_SUCCESS: 'CREATE_ORG_WATER_SHARES_SUCCESS',

  GET_WATER_RIGHTS_ERROR: 'GET_WATER_RIGHTS_ERROR',
  GET_WATER_SHARES_ERROR: 'GET_WATER_SHARES_ERROR',
  GET_ORG_WATER_SHARES_ERROR: 'GET_ORG_WATER_SHARES_ERROR',
  CREATE_WATER_RIGHTS_ERROR: 'CREATE_WATER_RIGHTS_ERROR',
  CREATE_WATER_SHARES_ERROR: 'CREATE_WATER_SHARES_ERROR',
  CREATE_ORG_WATER_SHARES_ERROR: 'CREATE_ORG_WATER_SHARES_ERROR'
};
