import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import WetxManageTagsModal from '../../wetx-utils/WetxManageTagsModal';

import '../../../wetx-styles/wetx.light.css';

const WetxUploadTagSelect = ({ tags, editFileTags, width }) => {
  const [showTags, setShowTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagManagementOpen, setTagManagementOpen] = useState(false);

  const client = useSelector(state => state.auth.clientOrganisation);

  const addTagsToFile = id_tag => {
    const newSelectedTags = [...selectedTags, ...tags.filter(tag => tag.id_tag === parseInt(id_tag))];
    editFileTags(newSelectedTags);
    setSelectedTags(newSelectedTags);
  };

  const removeTagFromFile = id_tag => {
    const newSelectedTags = [...selectedTags.filter(tag => tag.id_tag !== parseInt(id_tag))];
    editFileTags(newSelectedTags);
    setSelectedTags(newSelectedTags);
  };

  return (
    <div className="tag-select__container" style={{ width: width && width }}>
      <div className="tag-select__action" onClick={() => setShowTags(!showTags)}>
        Labels
      </div>
      {showTags && (
        <div
          className="tag-select__tags-container"
          onMouseLeave={() => {
            setShowTags(false);
          }}
        >
          <div className="tag-select__tags">
            {tags && tags.length > 0 ? (
              tags.map(tag => (
                <div key={tag.tag_name} className="tag-select__tag">
                  <input
                    type="checkbox"
                    className="tag-select__checkbox"
                    id={tag.id_tag}
                    checked={
                      selectedTags.length > 0 && selectedTags.findIndex(selected => selected.id_tag === tag.id_tag) > -1
                    }
                    name={tag.id_tag}
                    onChange={e => {
                      if (e.target.checked === true) {
                        return addTagsToFile(e.target.name);
                      }
                      removeTagFromFile(e.target.name);
                    }}
                  />
                  <label htmlFor={tag.id_tag} className="tag-select__checkbox-label">
                    {tag.tag_name}
                  </label>
                </div>
              ))
            ) : (
              <div className="tag-select__empty">You do not have any system tags</div>
            )}
          </div>
          <div className="hor-divider" />
          <div className="add-tag__button" onClick={() => setTagManagementOpen(true)}>
            + New tag
          </div>
        </div>
      )}
      {tagManagementOpen && (
        <WetxManageTagsModal
          openModal={true}
          setTagManagementOpen={setTagManagementOpen}
          id_client={client.id_client}
        />
      )}
    </div>
  );
};

export default WetxUploadTagSelect;
