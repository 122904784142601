export const dashboardsActionTypes = {
  LOAD_DASHBOARDS_REQUEST: 'LOAD_DASHBOARDS_REQUEST',
  LOAD_DASHBOARDS_SUCCESS: 'LOAD_DASHBOARDS_SUCCESS',
  LOAD_DASHBOARDS_ERROR: 'LOAD_DASHBOARDS_ERROR',
  CLEAR_STATE_SUCCESS: 'CLEAR_STATE_SUCCESS',
  SAVE_LAYOUT_REQUEST: 'SAVE_LAYOUT_REQUEST',
  SAVE_LAYOUT_SUCCESS: 'SAVE_LAYOUT_SUCCESS',
  SAVE_LAYOUT_ERROR: 'SAVE_LAYOUT_ERROR',
  EDIT_DASHBOARD_LAYOUT: 'EDIT_DASHBOARD_LAYOUT'
};
