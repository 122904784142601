import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import DataGrid, { Column, HeaderFilter, Paging, Pager } from 'devextreme-react/data-grid';

import WetxPager from '../wetx-cloud/utils/WetxPager';
import NewWaterEntry from './NewWaterEntry';
import ButtonIcon from '../common/ButtonIcon';

import 'devextreme/dist/css/dx.light.css';
import '../../wetx-styles/wetx.light.css';

const WaterTable = ({ id, title, items }) => {
  const [data, setData] = useState(items);
  const [currentItemCount, setCurrentItemCount] = useState(null);
  const [creatingNewEntry, setCreatingNewEntry] = useState(false);

  const renderCustomCell = ({ data: { link, water_right_number } }) => {
    return (
      <a className="font-weight-semi-bold" href={link} target="_blank" rel="noreferrer" style={{}}>
        {water_right_number}
      </a>
    );
  };

  useEffect(() => {
    setData(items);
  }, [items]);

  const dataGridRef = React.createRef();

  return (
    <div style={{ width: '100%', height: '100%' }} className="file-table mb-3">
      <div
        className="p-2 file-header d-flex flex-row justify-content-between align-items-center"
        style={{ background: 'transparent' }}
      >
        <div
          className="m-1 d-flex flex-row align-items-center"
          style={{ width: '100%', fontSize: '18px', fontWeight: '800' }}
        >
          {title}
        </div>
        <ButtonIcon
          icon="plus"
          transform="shrink-3 down-2"
          color="falcon-default"
          size="sm"
          style={{ minWidth: '150px' }}
          onClick={() => setCreatingNewEntry(!creatingNewEntry)}
        >
          Create new
        </ButtonIcon>
      </div>
      <DataGrid
        showColumnLines={true}
        showRowLines={true}
        id={id}
        dataSource={data}
        showBorders={true}
        allowColumnResizing={true}
        ref={dataGridRef}
        onRowPrepared={e => {
          e.rowElement.style.height = '40px';
        }}
        onContentReady={e => {
          if (currentItemCount !== e.component.totalCount()) {
            setCurrentItemCount(e.component.totalCount());
          }
        }}
      >
        <HeaderFilter visible={true} allowSearch={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={false}
          displayMode="compact"
          allowedPageSizes={[5, 10, 15, 20, 25, 50]}
          showNavigationButtons={true}
          visible={false}
        />
        {data &&
          Object.keys(data[0]).map(key => {
            return key === 'link' ? null : (
              <Column
                key={key}
                dataField={key}
                caption={_.startCase(key)}
                cellRender={key === 'water_right_number' && renderCustomCell}
              />
            );
          })}
      </DataGrid>
      {data && (
        <WetxPager
          dataGridRef={dataGridRef}
          currentPageSize={5}
          allowedPageSizes={[5, 10, 15, 20, 25, 50]}
          filesNumber={dataGridRef.current ? dataGridRef.current.props.dataSource.length : data.length}
          currentItemCount={currentItemCount}
        />
      )}
      {creatingNewEntry && (
        <NewWaterEntry table={id} openModal={creatingNewEntry} setCreatingNewEntry={setCreatingNewEntry} />
      )}
    </div>
  );
};

export default WaterTable;
