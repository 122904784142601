import { filesActionTypes } from './files.types';
import { dashboardsActionTypes } from '../dashboards/dashboards.types';

const initialState = {
  items: [],
  itemFilters: [],
  recentFiles: [],
  pinnedFiles: [],
  tags: [],
  folders: [],
  archivedFiles: null,
  categories: null,
  types: null,
  loading: false,
  error: null
};

function filesReducer(state = initialState, action) {
  switch (action.type) {
    // REQUEST STATES
    case filesActionTypes.LOAD_FILES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case filesActionTypes.LOAD_ARCHIVED_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.LOAD_FOLDERS_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.LOAD_TYPES_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.LOAD_RECENT_FILES_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.LOAD_PINNED_FILES_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.LOAD_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
        tags: []
      };
    case filesActionTypes.SAVE_FILES_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.SAVE_RECENT_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.PIN_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.ARCHIVE_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.CREATE_TAG_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.RESTORE_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.UPDATE_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.ADD_TAG_TO_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.EDIT_TAG_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.REMOVE_TAG_FROM_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.DELETE_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.REMOVE_TAG_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.VIEW_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.DOWNLOAD_FILE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.UPDATE_FILES_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.UPDATE_FILES_TYPE_REQUEST:
      return { ...state, loading: true };
    case filesActionTypes.UPDATE_FILES_TAGS_REQUEST:
      return { ...state, loading: true };

    // SUCCESS STATES
    case filesActionTypes.LOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload
      };
    case filesActionTypes.LOAD_FILE_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        itemFilters: action.payload
      };
    case filesActionTypes.LOAD_ARCHIVED_SUCCESS:
      return {
        ...state,
        archivedFiles: action.payload,
        loading: false
      };
    case filesActionTypes.LOAD_FOLDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        folders: action.payload,
        categories: action.payload
      };
    case filesActionTypes.LOAD_TYPES_SUCCESS:
      return { ...state, loading: false, types: action.payload };
    case filesActionTypes.LOAD_RECENT_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        recentFiles: action.payload
      };
    case filesActionTypes.LOAD_PINNED_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        pinnedFiles: action.payload
      };
    case filesActionTypes.LOAD_TAGS_SUCCESS:
      return { ...state, loading: false, tags: action.payload };
    case filesActionTypes.SAVE_FILES_SUCCESS: {
      let newItems = state.items;
      for (let file of action.payload) {
        const fileIndex = newItems.findIndex(item => item.file_key === file.file_key);

        if (fileIndex > -1) {
          newItems[fileIndex] = file;
        } else {
          newItems = [file, ...state.items];
        }
      }

      return Object.assign({}, state, { items: newItems, loading: false });
    }
    case filesActionTypes.SAVE_RECENT_FILE_SUCCESS: {
      const newRecentIndex = state.items.findIndex(item => item.id === action.payload);
      if (newRecentIndex > -1) {
        let newRecentFiles = state.recentFiles;
        const isPinned = state.pinnedFiles.findIndex(item => item.id === action.payload);
        if (isPinned > -1) {
          return state;
        }
        const isRecent = state.recentFiles.findIndex(item => item.id === action.payload);
        if (isRecent > -1) {
          newRecentFiles.splice(isRecent, 1);
        }
        if (newRecentFiles.length === 20) {
          newRecentFiles.pop();
        }
        newRecentFiles.unshift(state.items[newRecentIndex]);
        state.recentFiles = newRecentFiles;
      }
      return Object.assign({}, state);
    }
    case filesActionTypes.PIN_FILE_SUCCESS:
      return { ...state, loading: false };
    case filesActionTypes.ARCHIVE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(item => item.id !== action.payload)
      };
    case filesActionTypes.CREATE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: [...state.tags, action.payload]
      };
    case filesActionTypes.RESTORE_FILE_SUCCESS: {
      const newItems = [...state.items, ...state.archivedFiles.filter(file => file.id === action.payload)];
      const newArchivedFiles = state.archivedFiles.filter(file => file.id !== action.payload);

      return Object.assign({}, state, {
        items: newItems,
        archivedFiles: newArchivedFiles,
        loading: false
      });
    }
    case filesActionTypes.UPDATE_FILE_SUCCESS: {
      const updatedFilesIndex = state.items.findIndex(item => item.id === action.payload.id);
      const updatedRecentIndex = state.recentFiles.findIndex(item => item.id === action.payload.id);
      const updatedPinnedIndex = state.pinnedFiles.findIndex(item => item.id === action.payload.id);

      if (updatedFilesIndex > -1) {
        let newFiles = state.items;
        newFiles[updatedFilesIndex] = action.payload;
        state.items = newFiles;
      }
      if (updatedRecentIndex > -1) {
        let newFiles = state.recentFiles;
        newFiles[updatedRecentIndex] = action.payload;
        state.recentFiles = newFiles;
      }
      if (updatedPinnedIndex > -1) {
        let newFiles = state.pinnedFiles;
        newFiles[updatedPinnedIndex] = action.payload;
        state.pinnedFiles = newFiles;
      }
      return Object.assign({}, state);
    }
    case filesActionTypes.ADD_TAG_TO_FILE_SUCCESS: {
      const { id_file, tag } = action.payload;
      const { background, color, id_tag, tag_name } = tag;

      const fileIndex = state.items.findIndex(item => item.id === id_file);
      const recentIndex = state.recentFiles.findIndex(item => item.id === id_file);
      const pinnedIndex = state.pinnedFiles.findIndex(item => item.id === id_file);
      const updateItem = state.items[fileIndex];

      updateItem.user_tags = [...JSON.parse(updateItem.user_tags), { background, color, id_tag, tag_name }];
      updateItem.user_tags = JSON.stringify(updateItem.user_tags);

      if (recentIndex > -1) {
        state.recentFiles[recentIndex] = updateItem;
      }
      if (pinnedIndex > -1) {
        state.pinnedFiles[pinnedIndex] = updateItem;
      }

      state.items[fileIndex] = updateItem;

      return Object.assign({}, state);
    }
    case filesActionTypes.EDIT_TAG_SUCCESS: {
      const newFiles = state.items;
      const newRecentFiles = state.recentFiles;
      const newPinnedFiles = state.pinnedFiles;

      newFiles.map((file, index) => {
        if (file.user_tags) {
          let tags = JSON.parse(file.user_tags);
          const tagIndex = tags.findIndex(item => item.id_tag === action.payload.id_tag);
          if (tagIndex > -1) {
            tags[tagIndex] = action.payload;
            file.user_tags = JSON.stringify(tags);
          }
        }
        return (newFiles[index] = file);
      });

      newRecentFiles.map((file, index) => {
        if (file.user_tags) {
          let tags = JSON.parse(file.user_tags);
          const tagIndex = tags.findIndex(item => item.id_tag === action.payload.id_tag);
          if (tagIndex > -1) {
            tags[tagIndex] = action.payload;
            file.user_tags = JSON.stringify(tags);
          }
        }
        return (newRecentFiles[index] = file);
      });

      newPinnedFiles.map((file, index) => {
        if (file.user_tags) {
          let tags = JSON.parse(file.user_tags);
          const tagIndex = tags.findIndex(item => item.id_tag === action.payload.id_tag);
          if (tagIndex > -1) {
            tags[tagIndex] = action.payload;
            file.user_tags = JSON.stringify(tags);
          }
        }
        return (newPinnedFiles[index] = file);
      });

      return Object.assign({}, state);
    }
    case filesActionTypes.REMOVE_TAG_FROM_FILE_SUCCESS: {
      const { id_file, id_tag } = action.payload;

      const fileIndex = state.items.findIndex(item => item.id === id_file);
      const recentIndex = state.recentFiles.findIndex(item => item.id === id_file);
      const pinnedIndex = state.pinnedFiles.findIndex(item => item.id === id_file);

      const updateItem = state.items[fileIndex];
      updateItem.user_tags = JSON.parse(updateItem.user_tags);
      updateItem.user_tags = updateItem.user_tags.filter(tag => tag.id_tag !== id_tag);
      updateItem.user_tags = JSON.stringify(updateItem.user_tags);

      if (recentIndex > -1) {
        state.recentFiles[recentIndex] = updateItem;
      }
      if (pinnedIndex > -1) {
        state.pinnedFiles[pinnedIndex] = updateItem;
      }

      state.items[fileIndex] = updateItem;

      return Object.assign({}, state);
    }
    case filesActionTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(item => item.file_key !== action.payload)
      };
    case filesActionTypes.REMOVE_TAG_SUCCESS: {
      state.tags = state.tags.filter(tag => tag.id_tag !== action.payload);
      // Remove deleted tag from all files
      const currentItems = state.items;
      currentItems.map(item => {
        if (item.user_tags) {
          const tags = JSON.parse(item.user_tags);
          const newTags = tags.filter(tag => tag.id_tag !== action.payload);

          item.user_tags = JSON.stringify(newTags);
        }
        return item;
      });

      return Object.assign({}, state);
    }
    case filesActionTypes.VIEW_FILE_SUCCESS:
      return { ...state, loading: false };
    case filesActionTypes.DOWNLOAD_FILE_SUCCESS:
      return { ...state, loading: false };
    // TODO working on syncing viwe with new methods
    case filesActionTypes.UPDATE_FILES_CATEGORY_SUCCESS: {
      const { files, category } = action.payload;
      const newFiles = [...state.items];

      newFiles.forEach(file => {
        const index = files.findIndex(f => f.id === file.id);

        if (index > -1) {
          file.id_category = category.id_category;
          file.category_name = category.category_name;
        }
      });

      return Object.assign({}, state, { items: newFiles, loading: false });
    }
    case filesActionTypes.UPDATE_FILES_TYPE_SUCCESS: {
      const { files, type } = action.payload;
      const newFiles = [...state.items];

      newFiles.forEach(file => {
        const index = files.findIndex(f => f.id === file.id);

        if (index > -1) {
          file.id_type = type.id_type;
          file.type_name = type.type_name;
        }
      });

      return Object.assign({}, state, { items: newFiles, loading: false });
    }
    case filesActionTypes.UPDATE_FILES_TAGS_SUCCESS:
      return { ...state, loading: false };

    // ERROR STATES
    case filesActionTypes.LOAD_FILES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.LOAD_ARCHIVED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.LOAD_FOLDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.LOAD_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.LOAD_RECENT_FILES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.LOAD_PINNED_FILES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.LOAD_TAGS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.SAVE_FILES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.SAVE_RECENT_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.PIN_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.ARCHIVE_FILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.CREATE_TAG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case filesActionTypes.RESTORE_FILE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.UPDATE_FILE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.ADD_TAG_TO_FILE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.EDIT_TAG_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.REMOVE_TAG_FROM_FILE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.DELETE_FILE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.REMOVE_TAG_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.VIEW_FILE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.DOWNLOAD_FILE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.UPDATE_FILES_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.UPDATE_FILES_TYPE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case filesActionTypes.UPDATE_FILES_TAGS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case dashboardsActionTypes.CLEAR_STATE_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

export default filesReducer;
