import React, { Fragment, useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';

import Weather from '../dashboard-alt/Weather';
import WeeklySales from '../dashboard-alt/WeeklySales';
import TotalOrder from '../dashboard-alt/TotalOrder';
import MarketShare from '../dashboard-alt/MarketShare';

// Custom WetxWidgets
import WetxWidgetSimpleLink from '../widgets/WetxWidgetSimpleLink';
import WetxWidgetSnotel from '../widgets/WetxWidgetSnotel';
import WetxWidgetHtml from '../widgets/WetxWidgetHtml';
import WetxWidgetSimpleChart from '../widgets/WetxWidgetSimpleChart';
import WetxWidgetRadial from '../widgets/WetxWidgetRadial';
import WetxWidgetUSGS from '../widgets/WetxWidgetUSGS';
import WetxWidgetWater from '../widgets/WetxWidgetWater';

import weeklySales from '../../data/dashboard/weeklySales';
import totalOrder from '../../data/dashboard/totalOrder';
import marketShare from '../../data/dashboard/marketShare';
import weather from '../../data/dashboard/weather';

import SpaceWarning from '../dashboard-alt/SpaceWarning';

import store from '../../redux/state';
import { saveLayout } from '../../redux/dashboards/dashboards.actions';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Widgets = ({ id }) => {
  const allAvailableWidgets = useSelector(state => state.widgets.items);
  const widget = allAvailableWidgets.find(w => w.id_widget === id);

  if (!widget) {
    return null;
  }

  const { type, options, html, id_widget, title } = widget;

  let widgetComponent = null;

  switch (type) {
    case 'demo':
      widgetComponent = (
        <Card className="h-md-100" style={{ height: '100%' }}>
          <div dangerouslySetInnerHTML={{ __html: options.html }} />
        </Card>
      );
      break;
    case 'demo2':
      widgetComponent = <SpaceWarning />;
      break;
    case 'WeeklySales':
      widgetComponent = <WeeklySales data={weeklySales} />;
      break;
    case 'TotalOrder':
      widgetComponent = <TotalOrder data={totalOrder} />;
      break;
    case 'MarketShare':
      widgetComponent = <MarketShare data={marketShare} />;
      break;
    case 'Weather':
      widgetComponent = <Weather data={weather} className="h-md-100" style={{ height: '100%' }} />;
      break;
    case 'WetxWidgetSimpleLink':
      widgetComponent = <WetxWidgetSimpleLink options={options} />;
      break;
    case 'WetxWidgetSnotel':
      widgetComponent = <WetxWidgetSnotel options={options} />;
      break;
    case 'WetxWidgetHtml':
      widgetComponent = <WetxWidgetHtml html={html} options={options} />;
      break;
    case 'WetxWidgetSimpleChart':
      widgetComponent = <WetxWidgetSimpleChart idWidget={id_widget} options={options} />;
      break;
    case 'WetxWidgetRadial':
      widgetComponent = <WetxWidgetRadial idWidget={id_widget} options={options} />;
      break;
    case 'WetxWidgetUSGS':
      widgetComponent = <WetxWidgetUSGS idWidget={id_widget} options={options} />;
      break;
    case 'WetxWidgetWater':
      widgetComponent = <WetxWidgetWater idWidget={id_widget} options={options} title={title} />;
      break;
    default:
      break;
  }

  return widgetComponent;
};

const WETXDash = () => {
  const { idDash } = useParams();
  const dashboards = useSelector(state => state.dashboards.items);

  const [layouts, setLayouts] = useState(null);
  const [widgetsToShow, setWidgetsToShow] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [currentDashboard, setCurrrentDashboard] = useState(null);

  const { editingLayout, saveNewLayout } = useSelector(state => state.dashboards);

  const loadDash = (idDash, dashboards) => {
    let dash = dashboards.find(d => d.slug === idDash);

    if (dash) {
      // const savedLayouts = getFromLocalStorage();
      // setLayouts(Object.keys(savedLayouts).length === 0 ? dash.layouts : savedLayouts);
      setLayouts(dash.layouts);
      // setLayouts(dash.layouts);
      saveToLocalStorage(dash.layouts);

      const { lg, md, sm, xs, xxs } = dash.layouts;
      const oneLayout = lg || md || sm || xs || xxs;
      setWidgetsToShow(oneLayout.map(l => l.i));
      setCurrrentDashboard(dash);
    }
  };

  useEffect(() => {
    loadDash(idDash, dashboards);
    if (currentDashboard) {
      if (saveNewLayout) {
        store.dispatch(saveLayout(currentDashboard.id_dashboard, layouts));
        currentDashboard.layouts = layouts;
        setCurrrentDashboard(currentDashboard);
        setLayouts(layouts);
      } else if (!saveNewLayout && editingLayout) {
        setLayouts(currentDashboard.layouts);
      }
      setEditMode(editingLayout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idDash, dashboards, editingLayout]);

  function onLayoutChange(layout, layouts) {
    const newLayouts = { ...getFromLocalStorage(), ...layouts };
    saveToLocalStorage(newLayouts);
    setLayouts(newLayouts);
  }

  function getFromLocalStorage() {
    let ls = null;
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem(`layouts_${idDash}`)) || {};
      } catch (e) {
        /*Ignore*/
      }
    }
    return ls;
  }

  function saveToLocalStorage(value) {
    if (global.localStorage) {
      global.localStorage.setItem(`layouts_${idDash}`, JSON.stringify(value));
    }
  }

  // Only first time
  if (layouts === null && idDash && dashboards.length > 0) {
    loadDash(idDash, dashboards);
  }

  if (layouts === null) {
    return null;
  }

  return (
    <Fragment>
      <ResponsiveGridLayout
        className="wetx"
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 9, sm: 5, xs: 3, xxs: 3 }}
        layouts={layouts}
        containerPadding={[0, 0]}
        margin={[16, 16]}
        rowHeight={10}
        isDraggable={editMode}
        isResizable={editMode}
        onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
      >
        {widgetsToShow.map(w => (
          <div key={w}>
            <Widgets id={w} />
          </div>
        ))}
      </ResponsiveGridLayout>
    </Fragment>
  );
};

export default WETXDash;
