import { cabinetsActionTypes } from './cabinets.types';

const initialState = {
  items: [],
  loading: false,
  error: null
};

function cabinetsReducer(state = initialState, action) {
  switch (action.type) {
    case cabinetsActionTypes.LOAD_CABINETS_REQUEST:
      return { ...state, loading: true };
    case cabinetsActionTypes.CREATE_CABINET_REQUEST:
      return { ...state, loading: true };
    case cabinetsActionTypes.UPDATE_CABINET_REQUEST:
      return { ...state, loading: true };
    case cabinetsActionTypes.DELETE_CABINET_REQUEST:
      return { ...state, loading: true };

    case cabinetsActionTypes.LOAD_CABINETS_SUCCESS:
      return { ...state, loading: false, items: action.payload };
    case cabinetsActionTypes.CREATE_CABINET_SUCCESS: {
      const newCabinets = [...state.items];
      newCabinets.push(action.payload);
      return Object.assign({}, state, { items: newCabinets, loading: false });
    }
    case cabinetsActionTypes.UPDATE_CABINET_SUCCESS: {
      const newCabinets = [...state.items];
      const index = newCabinets.findIndex(item => item.id_category === action.payload.id_category);
      if (index > -1) {
        newCabinets[index] = action.payload;
      } else {
        newCabinets.push(action.payload);
      }

      return Object.assign({}, state, { items: newCabinets, loading: false });
    }
    case cabinetsActionTypes.DELETE_CABINET_SUCCESS: {
      let newCabinets = [...state.items];
      newCabinets = newCabinets.filter(item => item.id_category !== action.payload.id_category);
      return Object.assign({}, state, { items: newCabinets, loading: false });
    }

    case cabinetsActionTypes.LOAD_CABINETS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case cabinetsActionTypes.CREATE_CABINET_ERROR:
      return { ...state, loading: false, error: action.payload };
    case cabinetsActionTypes.UPDATE_CABINET_ERROR:
      return { ...state, loading: false, error: action.payload };
    case cabinetsActionTypes.DELETE_CABINET_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}

export default cabinetsReducer;
