import { bucketActionTypes } from './buckets.types';

const initialState = {
  buckets: [],
  loading: false,
  error: null
};

function adminBucketsReducer(state = initialState, action) {
  switch (action.type) {
    // REQUEST TYPES
    case bucketActionTypes.LOAD_BUCKETS_REQUEST:
      return { ...state, loading: true };
    case bucketActionTypes.CREATE_BUCKET_REQUEST:
      return { ...state, loading: true };

    // SUCCESS TYPES
    case bucketActionTypes.LOAD_BUCKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        buckets: action.payload
      };
    case bucketActionTypes.CREATE_BUCKET_SUCCESS:
      return { ...state, loading: false, buckets: [...state.buckets, action.payload] };

    // ERROR TYPES
    case bucketActionTypes.LOAD_BUCKETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case bucketActionTypes.CREATE_BUCKET_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export default adminBucketsReducer;
