import _ from 'lodash';
import React, { useState } from 'react';
import { DateBox } from 'devextreme-react';

const WetxDateInput = props => {
  const [focused, setFocused] = useState(false);
  return (
    <div style={{ width: '100%', height: '60px' }} className="mt-3">
      <div style={{ width: '100%' }} className="input__container">
        <label htmlFor={props.title} className={`mr-3 mb-0 date input__title ${focused && 'focused'}`}>
          {_.startCase(props.title)}
        </label>
        <DateBox
          id={props.title}
          style={{
            height: '40px',
            width: '100%',
            border: `${props.errors && props.errors[props.title] ? '1px solid red' : '1px solid #d8e2ef'}`
          }}
          placeholder={focused ? props.placeholder : ''}
          value={props.value}
          onValueChange={props.onChange}
          onFocusIn={() => setFocused(true)}
        />
      </div>
      {props.errors && props.errors[props.title] && (
        <div style={{ color: 'red', fontSize: '12px' }} className="text-center">
          This field cannot be empty
        </div>
      )}
    </div>
  );
};

export default WetxDateInput;
