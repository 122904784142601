import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Form, Row } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import FormGroupInput from '../common/FormGroupInput';
import Flex from '../common/Flex';

import { Auth } from 'aws-amplify';

const WETxSettingsProfile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
      .then(user => {
        const { email, family_name, given_name, phone_number } = user.attributes;

        setEmail(email || '');
        setFirstName(given_name || '');
        setLastName(family_name || '');
        setPhone(phone_number || '');
      })
      .catch(err => console.log(err));
  }, []);

  const handleProfileSettings = e => {
    e.preventDefault();

    Auth.currentAuthenticatedUser()
      .then(async user => {
        await Auth.updateUserAttributes(user, {
          given_name: firstName || '',
          family_name: lastName || '',
          phone_number: phone || '',
          name: `${firstName} ${lastName}`
        });
      })
      .catch(err => console.log(err));
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Profile Settings" light={false} />
      <CardBody className="bg-light">
        <Form onSubmit={handleProfileSettings}>
          <Row>
            <Col lg={6}>
              <FormGroupInput
                id="first-name"
                label="First Name"
                value={firstName}
                onChange={({ target }) => setFirstName(target.value)}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="last-name"
                label="Last Name"
                value={lastName}
                onChange={({ target }) => setLastName(target.value)}
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="email"
                label="Email"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                type="email"
                disabled
              />
            </Col>
            <Col lg={6}>
              <FormGroupInput
                id="phone"
                label="Phone"
                value={phone}
                onChange={({ target }) => setPhone(target.value)}
                type="tel"
              />
            </Col>
            <Col tag={Flex} xs={12} justify="end">
              <Button color="primary" type="submit" onClick={handleProfileSettings}>
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default WETxSettingsProfile;
