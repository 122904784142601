import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import Amplify from 'aws-amplify';

import { Provider } from 'react-redux';

import App from './App';
import Main from './Main';
import './helpers/initFA';

import store from './redux/state';

import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

import { settings } from './config';

Amplify.configure(settings.Amplify);

ReactDOM.render(
  <Provider store={store}>
    <Main>
      <App />
    </Main>
  </Provider>,
  document.getElementById('main')
);
