import React from 'react';
import { Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import WetxIcon from '../utilities/WetxIcon';

import '../../wetx-styles/wetx.light.css';

const WetxCloudShareModal = ({
  file: {
    file: { filename, tags },
    fileUrl
  },
  openModal,
  setSharing
}) => {
  return (
    <Modal className="modal-lg" isOpen={openModal} centered={true}>
      <div className="share-modal__container">
        <div className="modal-header" style={{ borderBottom: '1px solid #c7c7c7c7', fontSize: '0.8em' }}>
          <div className="modal-header__Meta flex-start d-flex flex-column" style={{ width: '100%' }}>
            <div className="modal-header__Meta__text d-flex flex-row" style={{ width: '100%' }}>
              <WetxIcon iconName="link" color="black" size="29px" title="Open File" margin="10px" />
              <div className="share-modal__header-text">Share WETx Cloud Document</div>
            </div>
            <div className="hor-divider__share" />
          </div>
        </div>
        <div className="m-2 d-flex flex-column justify-content-between align-items-start">
          <div className="d-flex flex-column align-items-start justify-content-center">
            <div className="mb-3">{filename}</div>
            <a href={fileUrl} target="_blank" rel="noreferrer" className="mr-3">
              {fileUrl.substring(0, 70)}
            </a>
            <div className="mt-3">
              <strong>Restricted: </strong>This share link will only be active for 7 days
            </div>
          </div>
          <div className="share-modal__actions d-flex flex-row justify-content-end w-100 mt-3">
            <div className="share-modal__actions-dismiss" onClick={() => setSharing(false)}>
              Dismiss
            </div>
            <div
              className="share-modal__actions-copy ml-4"
              onClick={() => {
                navigator.clipboard.writeText(fileUrl);
                toast.success('Coppied to clipboard');
              }}
            >
              Copy Link
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WetxCloudShareModal;
