import React from 'react';

import { Card, CardBody } from 'reactstrap';

const WetxWidgetHtml = ({ html, options: { bgColor, title } }) => {
  return (
    <Card className="h-lg-100 h-100" style={{ margin: '0', maxWidth: '800px', maxHeight: '600px' }}>
      <CardBody
        dangerouslySetInnerHTML={{ __html: html }}
        className="widget-demo-html h-100"
        style={{ backgroundColor: bgColor, width: '100%', padding: '0' }}
      />
    </Card>
  );
};

export default WetxWidgetHtml;
