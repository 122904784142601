import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router';
import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils';

import store from '../redux/state';
import { loadUser } from '../redux/actions';
import { loadBuckets } from '../redux/admin/buckets/buckets.actions';
import { loadClients } from '../redux/admin/clients/clients.actions';

import NavbarTop from '../components/navbar/NavbarTop';
// import WetxPageNavigator from '../components/wetx-utils/WetxPageNavigator';
import WetxAdminNavbar from '../components/wetx-admin/WetxAdminNavbar';
import WetxAdminBuckets from '../components/wetx-admin/WetxAdminBuckets';
import WetxAdminClients from '../components/wetx-admin/WetxAdminClients';
import WetxAdminUsers from '../components/wetx-admin/WetxAdminUsers';
import WetxAdminHome from '../components/wetx-admin/WetxAdminHome';

const AdminLayout = () => {
  const { isFluid } = useContext(AppContext);

  const isKanban = getPageName('kanban');
  const { isAdmin } = useSelector(state => state.auth);

  const history = useHistory();

  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(loadBuckets());
    store.dispatch(loadClients());

    if (isAdmin === false) {
      history.push('/');
    }
    //eslint-disable-next-line
  }, [isAdmin]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      <div className="content">
        <NavbarTop />
        {/* TODO implement this once everything is merged with dev <WetxPageNavigator title="Admin Dashboard" /> */}
        <div>Admin dashboard</div>
        <div className="d-flex flex-row flex-wrap">
          <WetxAdminNavbar />
          <Switch>
            <Route
              render={({ match: { url } }) => {
                return (
                  <>
                    <Route path={`${url}/`} exact component={WetxAdminHome} />
                    <Route path={`${url}/buckets`} exact component={WetxAdminBuckets} />
                    <Route path={`${url}/clients`} exact component={WetxAdminClients} />
                    <Route path={`${url}/users`} exact component={WetxAdminUsers} />
                  </>
                );
              }}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
