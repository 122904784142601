import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WetxCheckBox from '..//wetx-utils/WetxCheckBox';

const WetxSearchBox = () => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [includeArchived, setIncludeArchived] = useState(false);

  const handleSearchChange = e => {
    setSearchInputValue(e.target.value);
  };

  const history = useHistory();
  useEffect(() => {
    return history.listen(location => {
      if (location.pathname !== '/cloud/search') {
        setSearchInputValue('');
        setIncludeArchived(false);
      }
    });
  }, [history]);

  const handleSearch = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (history.location.pathname === '/cloud/search') {
        history.replace({
          pathname: '/cloud/search',
          state: { searchInputValue, includeArchived }
        });
      } else {
        history.push({
          pathname: '/cloud/search',
          state: { searchInputValue, includeArchived }
        });
      }
    }
  };

  const handleSearchButtonClick = e => {
    e.preventDefault();
    if (history.location.pathname === '/cloud/search') {
      history.push({
        pathname: '/cloud/search',
        state: { searchInputValue, includeArchived }
      });
    } else {
      history.push({
        pathname: '/cloud/search',
        state: { searchInputValue, includeArchived }
      });
    }
  };

  const handleArchived = () => {
    const shouldIncludeArchived = !includeArchived;
    setIncludeArchived(shouldIncludeArchived);
  };

  return (
    <div
      style={{
        position: 'sticky',
        zIndex: '10',
        top: '69px',
        background: '#EDF2F9'
      }}
    >
      <div className="search-box w-100 mb-2">
        <Form>
          <Input
            type="search"
            name="searchTerm"
            placeholder="Search Vault Documents"
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={handleSearchChange}
            onKeyDown={handleSearch}
          />
          <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
          <div className="search-box-actions d-flex flex-row align-items-center">
            <button className="btn btn-primary search-box-button__button " onClick={handleSearchButtonClick}>
              Search
            </button>

            <WetxCheckBox
              id="archive-check"
              checked={includeArchived}
              onChange={handleArchived}
              label="Include archived"
            />
          </div>
          <span className="archive-checkbox" />
        </Form>
      </div>
    </div>
  );
};

export default WetxSearchBox;
