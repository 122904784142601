import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalBody } from 'reactstrap';

import WetxInput from './WetxInput';
import WetxDateInput from './WetxDateInput';

import store from '../../redux/state';
import { createWaterEntry } from '../../redux/water/water.actions';

import '../../wetx-styles/wetx.light.css';

const tables = {
  water_rights: [
    { title: 'water_right_number', type: 'text', placeholder: 'Water Right Number' },
    { title: 'link', type: 'text', placeholder: 'waterrights.statename.gov' },
    { title: 'quantity', type: 'number', placeholder: 'acft, cfs' },
    { title: 'source', type: 'text', placeholder: 'river, well, lake, reservoir, spring name, etc.' },
    { title: 'uses', type: 'text', placeholder: 'culinary, irrigation, stock, M&I, etc' },
    { title: 'status', type: 'text', placeholder: 'approved, disallowed, certificated, WUC, WD, LAP, ADEC' },
    { title: 'priority_date', type: 'date', placeholder: 'MM/DD/YYYY' }
  ],
  water_shares: [
    { title: 'irrigation_company', type: 'text' },
    { title: 'shares', type: 'number', placeholder: 'Number of shares owned' },
    { title: 'total_shares', type: 'number', placeholder: 'Total number of water shares' },
    { title: 'amount_in_acre_ft', type: 'number', decimal: true },
    { title: 'class_of_priority', type: 'text', placeholder: 'Class A, Class B, etc.' }
  ],
  org_water_shares: [
    { title: 'shareholder_name', type: 'text' },
    { title: 'shares', type: 'number', placeholder: 'Number of shares owned' },
    { title: 'total_shares', type: 'number', placeholder: 'Total number of water shares' },
    { title: 'amount_in_acre_ft', type: 'number', decimal: true },
    { title: 'class_of_priority', type: 'text', placeholder: 'Class A, Class B, etc.' }
  ]
};

const NewWaterEntry = ({ table, openModal, setCreatingNewEntry }) => {
  const [saveObj, setSaveObj] = useState(null);
  const [errors, setErrors] = useState(null);

  const { clientOrganisation } = useSelector(state => state.auth);

  return (
    <Modal className="modal-xs" isOpen={openModal} centered={true}>
      <div
        className="modal-header d-flex flex-row justify-content-between align-items-center"
        style={{ borderBottom: '1px solid #c7c7c7c7', fontSize: '0.8em' }}
      >
        <div>
          <p style={{ margin: '0', fontSize: '18px' }}>Enter your {table.replaceAll('_', ' ')} information below</p>
        </div>
        <div className="flex-end ml-100" title="Close File" style={{ paddingLeft: '5px', fontSize: '18px' }}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setCreatingNewEntry(false);
            }}
          />
        </div>
      </div>
      <ModalBody className="d-flex flex-column align-items-center justify-content-center">
        {tables[table].map((col, index) => {
          if (col.type === 'date') {
            return (
              <WetxDateInput
                key={index}
                className="mt-2"
                style={{ height: '40px' }}
                placeholder={col.placeholder ? col.placeholder : _.startCase(col.title)}
                value={saveObj && saveObj[col.title] && saveObj[col.title]}
                title={col.title}
                type={col.type}
                errors={errors}
                errorcheck={col.title}
                onChange={date => {
                  let newSaveObj = { ...saveObj };
                  newSaveObj[col.title] = date;
                  let newErrorObj = { ...errors };
                  if (newErrorObj[col.title]) {
                    delete newErrorObj[col.title];
                    setErrors(newErrorObj);
                  }
                  newSaveObj[col.title] !== null && newSaveObj[col.title] !== '' && setSaveObj(newSaveObj);
                }}
              />
            );
          }
          return (
            <WetxInput
              key={index}
              className="mt-2"
              style={{ height: '40px' }}
              placeholder={col.placeholder ? col.placeholder : _.startCase(col.title)}
              value={saveObj && saveObj[col.title] ? saveObj[col.title] : ''}
              title={col.title}
              type={col.type}
              errorcheck={col.title}
              onChange={e => {
                let newSaveObj = { ...saveObj };
                newSaveObj[col.title] = e.target.value;
                let newErrorObj = { ...errors };
                if (newErrorObj[col.title]) {
                  delete newErrorObj[col.title];
                  setErrors(newErrorObj);
                }
                setSaveObj(newSaveObj);
              }}
              errors={errors}
              onBlur={e => {
                if (e.target.value === '') {
                  let newErrorObj = { ...errors };
                  newErrorObj[col.title] = 'This field cannot be empty';
                  return setErrors(newErrorObj);
                }
              }}
            />
          );
        })}
      </ModalBody>
      <div className="water-actions mb-3 mr-3 ml-auto">
        <div
          className="btn btn-secondary mr-3"
          onClick={() => {
            setSaveObj(null);
            setErrors(null);
            setCreatingNewEntry(false);
          }}
        >
          Cancel
        </div>

        <div
          className="btn btn-primary mr-3"
          onClick={() => {
            if (!saveObj) {
              let newErrorObj = {};

              for (let col of tables[table]) {
                newErrorObj[col.title] = 'This field cannot be empty';
              }

              return setErrors(newErrorObj);
            }

            if (saveObj && Object.keys(saveObj).length !== tables[table].length) {
              const enteredKeys = Object.keys(saveObj);
              let newErrorObj = {};

              for (let col of tables[table]) {
                if (!enteredKeys.includes(col.title) || saveObj[col.title] === '') {
                  newErrorObj[col.title] = 'This field cannot be empty';
                }
              }

              return setErrors(newErrorObj);
            }

            if (!errors || (errors && Object.keys(errors).length === 0)) {
              if (clientOrganisation) {
                const { id_client } = clientOrganisation;

                store.dispatch(createWaterEntry(id_client, table, saveObj));
              }

              setErrors(null);
              setSaveObj(null);
              return;
            }
          }}
        >
          Save
        </div>
      </div>
    </Modal>
  );
};

export default NewWaterEntry;
