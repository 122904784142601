import React from 'react';
import { useSelector } from 'react-redux';

const BucketList = () => {
  const { buckets } = useSelector(state => state.adminBuckets);
  return (
    <div className="admin-buckets__list">
      <div className="admin-bucket__list-header d-flex flex-row justify-content-between pl-2 pr-2">
        {buckets && buckets.length > 0 && Object.entries(buckets[0]).map(key => <div key={key}>{key[0]}</div>)}
        <div>Actions</div>
      </div>
      {buckets ? (
        buckets.map((bucket, index) => (
          <div key={index} className="admin-buckets__bucket w-100 d-flex flex-row justify-content-between p-2">
            <div className="admin-buckets__bucket-name">{bucket.Name}</div>
            <div className="admin-buckets__bucket-controls">Controls here if necessary</div>
          </div>
        ))
      ) : (
        <div>You do not have any buckets, create buckets then you will see them here</div>
      )}
    </div>
  );
};

export default BucketList;
