import React, { useState, useEffect } from 'react';

import DataGrid, { Column, HeaderFilter, Paging, Pager } from 'devextreme-react/data-grid';

import WetxPager from '../wetx-cloud/utils/WetxPager';

import 'devextreme/dist/css/dx.light.css';
import '../../wetx-styles/wetx.light.css';

import { loadWidgetData } from '../../redux/widgets/widgets.actions';
import store from '../../redux/state';

const WetxWidgetWater = ({ idWidget, options, title }) => {
  const [widgetData, setWidgetData] = useState(null);

  const getWidgetData = async () => {
    const data = await store.dispatch(loadWidgetData(idWidget));

    if (data) {
      setWidgetData(data[0].widget_data);
    }
  };
  useEffect(() => {
    if (!widgetData) {
      getWidgetData();
    }
  });

  const renderCustomCell = ({ data: { Link, WaterRightNumber } }) => {
    return (
      <a className="font-weight-semi-bold" href={Link} target="_blank" rel="noreferrer" style={{}}>
        {WaterRightNumber}
      </a>
    );
  };

  const dataGridRef = React.createRef();

  return (
    <div style={{ width: '100%', height: '100%' }} className="file-table">
      <div
        className="p-2 file-header d-flex flex-row justify-content-between align-items-center"
        style={{ background: 'transparent' }}
      >
        <div
          className="m-1 d-flex flex-row align-items-center"
          style={{ width: '100%', fontSize: '18px', fontWeight: '800' }}
        >
          {title}
        </div>
      </div>
      <DataGrid
        showColumnLines={true}
        showRowLines={true}
        id={idWidget}
        dataSource={widgetData}
        showBorders={true}
        allowColumnResizing={true}
        ref={dataGridRef}
        onRowPrepared={e => {
          e.rowElement.style.height = '40px';
        }}
      >
        <HeaderFilter visible={true} allowSearch={true} />
        <Paging defaultPageSize={5} />
        <Pager
          showPageSizeSelector={false}
          displayMode="compact"
          allowedPageSizes={[5, 10, 15, 20, 25, 50]}
          showNavigationButtons={true}
          visible={false}
        />
        {widgetData &&
          Object.keys(widgetData[0]).map(key => {
            return key === 'Link' ? null : (
              <Column
                key={key}
                dataField={key === 'Shares' ? `${key}.value` : key}
                caption={key === 'Shares' ? widgetData[0][key].title : key.replace(/([a-z])([A-Z])/g, '$1 $2').trim()}
                cellRender={key === 'WaterRightNumber' && renderCustomCell}
              />
            );
          })}
      </DataGrid>
      {widgetData && (
        <WetxPager
          dataGridRef={dataGridRef}
          filesNumber={widgetData.length}
          currentPageSize={5}
          allowedPageSizes={[5, 10, 15, 20, 25, 50]}
        />
      )}
    </div>
  );
};

export default WetxWidgetWater;
