import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../wetx-styles/wetx.light.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// import WetxIcon from '../utilities/WetxIcon';
// import WetxTagFilter from './WetxTagFilter';
import WetxCustomConfirm from './WetxCustomConfirm';
import WetxSystemTagsOverlay from './WetxSystemTagsOverlay';
import WetxSingleTag from './WetxSingleTag';

import '../../wetx-styles/wetx.light.css';

import store from '../../redux/state';
import { createTag, removeTag, getClientTags, addTagToFile, removeTagFromFile } from '../../redux/files/files.actions';

const WetxManageTagsModal = ({
  id_client,
  openModal,
  setTagManagementOpen,
  fileTags,
  filename,
  updateFileTags,
  file
}) => {
  const [tagText, setTagText] = useState('');
  const [userTags, setUserTags] = useState(null);
  const [userFileTags, setFileTags] = useState(fileTags || null);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [tagForEdit, setTagForEdit] = useState(null);
  const [showFilterTags, setShowFilterTags] = useState(null);

  const client_tags = useSelector(state => state.files.tags);

  const handleTagTextChange = e => {
    if (!showFilterTags) {
      setShowFilterTags(true);
    }

    if (e.target.value.length > 20) {
      return toast.info('Label cannot be longer than 20 characters');
    }
    if (e.target.value.includes('"')) {
      return toast.info('You cannot have " in your label names');
    }

    setTagText(e.target.value);
  };

  const addTags = async () => {
    setShowFilterTags(false);
    if (tagText !== '') {
      if (tagText.length < 3) {
        return toast.info('Label must be at least 3 characters long');
      }
      const tag = {
        id_client,
        tag_name: tagText,
        background: '#0052cc',
        color: '#fff'
      };
      const createdTag = await store.dispatch(createTag(tag));
      if (userFileTags) {
        addFileTag(createdTag);
      }
      setUserTags([...userTags, createdTag]);
      setTagText('');
    }
  };

  const removeTags = id_tag => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <WetxCustomConfirm
            title="Are you sure you want to remove this label?"
            description="Removing this label will remove it from all documents and from the system permanently."
            onClose={onClose}
            cancelButtonText="Cancel"
            confirmButtonText="Yes, Remove label"
            confirmButtonClick={() => {
              store.dispatch(removeTag(id_tag, id_client));
              setUserTags([...userTags.filter(tag => tag.id_tag !== id_tag)]);
              setShowColorPicker(false);
              setTagForEdit(null);
            }}
          />
        );
      }
    });
  };

  const removeFileTag = id_tag => {
    store.dispatch(removeTagFromFile(file.id, id_tag));
    setFileTags([...userFileTags.filter(tag => tag.file !== file.id && tag.id_tag !== id_tag)]);
    updateFileTags([...userFileTags.filter(tag => tag.file !== file.id && tag.id_tag !== id_tag)]);
  };

  const addFileTag = tag => {
    if (userFileTags.filter(t => t.id_tag === tag.id_tag).length > 0) {
      return toast.info('This label is already on the file');
    }
    store.dispatch(addTagToFile(tag, file.id, tag.id_tag));
    setFileTags([...userFileTags, tag]);
    updateFileTags([...userFileTags, tag]);
    setTagText('');
  };

  useEffect(() => {
    setUserTags(client_tags);
    if (!userTags) {
      store.dispatch(getClientTags(id_client));
    }

    //eslint-disable-next-line
  }, [client_tags]);

  return (
    <Modal className="tag-management" isOpen={openModal} centered={true}>
      <div
        className="modal-header modal-header__padding d-flex flex-row justify-content-between pt-4 pb-4"
        style={{ border: 'none', fontSize: '0.8em', backgroundColor: '#F9FAFD' }}
      >
        <div className="modal-header__Meta flex-start d-inline-flex">
          <p className="m-0 text-center h5"> {!userFileTags ? 'Manage Labels' : 'Labels'}</p>
        </div>
        <div className="flex-end" title="Close File" style={{ paddingLeft: '5px', fontSize: '18px' }}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              updateFileTags && updateFileTags(userFileTags);
              setTagManagementOpen(false);
            }}
          />
        </div>
      </div>
      <ModalBody
        onClick={() => setShowFilterTags(false)}
        className="tags-container d-flex flex-column align-items-start pl-4 pr-4"
        style={{ backgroundColor: '#F9FAFD' }}
      >
        <div
          style={{
            background: '#fff',
            border: '1px solid #D8E2EE',
            width: '100%',
            padding: '30px 15px',
            borderRadius: '4px'
          }}
        >
          <p style={{ fontWeight: '400', fontSize: '16px', lineHeight: '13px' }}>
            {!userFileTags ? 'Create new label' : filename}
          </p>
          <div style={{ position: 'relative' }}>
            <input
              type="search"
              name="notAsearch"
              onChange={handleTagTextChange}
              value={tagText}
              onKeyUp={event => event.key === 'Enter' && addTags(event)}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                setShowFilterTags(true);
              }}
              placeholder={
                updateFileTags
                  ? 'Add an existing label or create a new label'
                  : 'Enter label name and press enter to create it'
              }
              className="mt-2 mb-4 pl-2"
              style={{ width: '100%', border: '1px solid #D8E2EE', height: '40px', borderRadius: '6px' }}
            />
            {showFilterTags && (
              <WetxSystemTagsOverlay
                userTags={userTags.filter(tag => tag.tag_name.toLowerCase().includes(tagText.toLowerCase()))}
                addTags={addTags}
                addFileTag={userFileTags && addFileTag}
                removeTags={!userFileTags && removeTags}
              />
            )}
          </div>
          <p style={{ fontWeight: '400', fontSize: '16px', lineHeight: '13px' }}>
            {!userFileTags ? 'System labels: ' : 'Applied labels: '}
          </p>
          <div className="mb-2">
            <ul id="tags">
              {!userFileTags && userTags
                ? userTags.map((tag, index) => (
                    <WetxSingleTag
                      key={index}
                      index={index}
                      tag={tag}
                      client_tags={client_tags}
                      setTagForEdit={setTagForEdit}
                      setShowColorPicker={setShowColorPicker}
                      removeTags={removeTags}
                      tagForEdit={tagForEdit}
                      showColorPicker={showColorPicker}
                    />
                  ))
                : userFileTags &&
                  userFileTags.map((tag, index) => (
                    <WetxSingleTag
                      key={index}
                      index={index}
                      tag={tag}
                      addTagToFile={addFileTag}
                      setTagForEdit={setTagForEdit}
                      removeTagFromFile={removeFileTag}
                      setShowColorPicker={setShowColorPicker}
                      showColorPicker={showColorPicker}
                      tagForEdit={tagForEdit}
                    />
                  ))}
            </ul>
          </div>

          {/* {showFilterTags && tagText !== '' && (
          <WetxTagFilter searchVal={tagText} id_file={id} setUserTags={setUserTags} userTags={userTags} />
        )} */}
        </div>
        <div className="tagging__actions w-100 d-flex flex-row justify-content-end mt-3 mb-2">
          <div
            className="tagging__actions-close"
            onClick={() => {
              updateFileTags && updateFileTags(userFileTags);
              setTagManagementOpen(false);
            }}
          >
            <p className="tagging-actions__button-txt">Close</p>
          </div>
          <div
            className="tagging__actions-save"
            onClick={() => {
              updateFileTags && updateFileTags(userFileTags);
              setTagManagementOpen(false);
            }}
          >
            <p className="tagging-actions__button-txt">Save</p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WetxManageTagsModal;
