import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import WetxIcon from '../../utilities/WetxIcon';

import { restoreFile } from '../../../redux/files/files.actions';
import store from '../../../redux/state';

import '../../../wetx-styles/wetx.light.css';

const ArchiveActionFormatter = ({ file, vertical }) => {
  return (
    <UncontrolledDropdown style={{ position: vertical ? 'relative' : 'absolute' }}>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal" style={{ padding: vertical && '0' }}>
        <FontAwesomeIcon icon={'ellipsis-h'} color={vertical && 'white'} className="fs--1" />
      </DropdownToggle>
      <DropdownMenu container="body" className="border pb-0 pt-2 px-1">
        <DropdownItem
          onClick={async e => {
            store.dispatch(restoreFile(file.id));
          }}
          className="mb-2"
        >
          <WetxIcon iconName="restore" size="16px" title={'Restore File'} margin="5px" color={'gray'} text="Restore" />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ArchiveActionFormatter;
