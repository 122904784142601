const initialState = {
  attributes: {},
  clientOrganisation: null,
  loading: false,
  error: null
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOAD_USER':
      return {
        ...state,
        loading: true
      };
    case 'LOAD_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        attributes: { ...action.payload }
      };
    case 'LOAD_USER_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        clientOrganisation: action.payload.client
      };
    case 'LOAD_USER_PERMISSIONS_SUCCESS':
      return {
        ...state,
        loading: false,
        permissions: action.payload.map(per => per.acl_id)
      };
    case 'LOAD_USER_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case 'SET_AUTH_GROUPS':
      const newGroups = [...action.payload];
      return {
        ...state,
        groups: newGroups,
        isAdmin: newGroups.indexOf('admin') > -1
      };
    case 'CLEAR_STATE_SUCCESS':
      return {
        attributes: {},
        clientOrganisation: null,
        loading: false,
        error: null
      };
    default:
      return state;
  }
}

export default authReducer;
