import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import WetxIcon from '../../utilities/WetxIcon';

import store from '../../../redux/state';
import { restoreFile } from '../../../redux/files/files.actions';

const WetxArchiveActions = ({ toggle, id }) => {
  return (
    <div className="modal-header__Actions flex-end d-flex flex-row" style={{ fontSize: '1.2rem' }}>
      <div className="flex-start mr-2 d-flex flex-row" style={{ paddingTop: '3px' }}>
        <WetxIcon
          onClick={() => {
            store.dispatch(restoreFile(id));
            toggle();
          }}
          iconName="restore"
          size="22px"
          title="Restore file"
          margin="5px"
          color="gray"
        />
      </div>
      <div
        className="flex-end"
        title="Close File"
        style={{ borderLeft: '1px solid #c7c7c7c7', paddingLeft: '5px', height: '30px' }}
      >
        <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={toggle} />
      </div>
    </div>
  );
};

export default WetxArchiveActions;
