const initialState = {
  users: [],
  loading: false,
  error: null
};

function adminUsersReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default adminUsersReducer;
