import React, { useState } from 'react';

import WetxManageTagsModal from '../wetx-utils/WetxManageTagsModal';

import '../../wetx-styles/wetx.light.css';

const WetxAddTagModal = ({
  id_client,
  file,
  file: { filename, user_tags },
  setTagging,
  insertFileWithNewTags,
  updateFileTags
}) => {
  const [userTags] = useState(JSON.parse(user_tags) || []);

  return (
    <WetxManageTagsModal
      openModal={true}
      id_client={id_client}
      setTagManagementOpen={setTagging}
      fileTags={userTags}
      filename={filename}
      insertFileWithNewTags={insertFileWithNewTags}
      updateFileTags={updateFileTags ? updateFileTags : insertFileWithNewTags}
      file={file}
    />
  );
};

export default WetxAddTagModal;
