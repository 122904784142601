import { dashboardsActionTypes } from './dashboards.types';

const initialState = {
  items: [],
  loading: false,
  editingLayout: false,
  error: null,
  saveNewLayout: false
};

function dashboardsReducer(state = initialState, action) {
  switch (action.type) {
    case dashboardsActionTypes.LOAD_DASHBOARDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardsActionTypes.SAVE_LAYOUT_REQUEST:
      return { ...state, loading: true, saveNewLayout: false };

    case dashboardsActionTypes.LOAD_DASHBOARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...action.payload.sort((a, b) => (a.order > b.order ? 1 : -1))]
      };
    case dashboardsActionTypes.SAVE_LAYOUT_SUCCESS:
      return { ...state, loading: false };

    case dashboardsActionTypes.LOAD_DASHBOARDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case dashboardsActionTypes.SAVE_LAYOUT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case dashboardsActionTypes.CLEAR_STATE_SUCCESS:
      return { ...state, items: [], loading: false };
    case dashboardsActionTypes.EDIT_DASHBOARD_LAYOUT:
      return { ...state, editingLayout: action.payload.editingDashboard, saveNewLayout: action.payload.saveNewLayout };
    default:
      return state;
  }
}

export default dashboardsReducer;
