import avatar from '../../assets/img/team/avatar.png';

import RyanGoodrich from '../../assets/img/wetx/RyanGoodrich.jpeg';
import SanelDeljkic from '../../assets/img/wetx/SanelDeljkic.png';
import Haris from '../../assets/img/wetx/Haris.png';

const WETxUserImage = user => {
  switch (user) {
    case '67ada59d-dc92-4a6d-abd7-edb4bda797f2':
      return SanelDeljkic;
    case '39477b69-14ab-4d37-8496-746a87ef9617':
      return Haris;
    case 'd7e3b891-3dfa-403a-9296-6bad361cc9d0':
      return RyanGoodrich;
    default:
      return avatar;
  }
};

export default WETxUserImage;
