import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createBucket } from '../../../redux/admin/buckets/buckets.actions';
import store from '../../../redux/state';

import AdminInput from '../utils/AdminInput';

const BucketForm = () => {
  const [bucketName, setBucketName] = useState('');
  const [region] = useState('us-west-2');
  const [errors, setErrors] = useState(null);

  const { loading } = useSelector(state => state.adminBuckets);

  const handleBucketNameChange = e => {
    if (errors) setErrors(null);
    setBucketName(e.target.value);
  };

  return (
    <div className="flex-fill d-flex flex-column align-items-center w-100">
      <div className="bucket-management__content p-2 d-flex flex-column w-100">
        <AdminInput
          label="Bucket name"
          value={bucketName}
          error={errors}
          errorCheck="bucket"
          onChange={handleBucketNameChange}
          infoText="Enter your bucket name, follow naming convencion of buckets: wetx-cloud-[client-short-name]"
        />
        <AdminInput
          label="Bucket region"
          value={region}
          readonly={true}
          infoText="Region change is disabled by the developer. For lambdas to use triggers properly they need to be in this region."
        />
      </div>
      <div className="admin-bucket__actions">
        <div
          className="btn btn-primary"
          onClick={() => {
            if (bucketName.substring(0, 11) !== 'wetx-cloud-') {
              return setErrors({ error: 'bucket', message: 'Bucket name must start with [wetx-cloud-]' });
            }
            if (bucketName.length < 15) {
              return setErrors({ error: 'bucket', message: 'Bucket name must be at least 15 characters long' });
            }
            store.dispatch(createBucket(bucketName, region));
            setBucketName('');
          }}
        >
          {loading ? 'Creating Bucket' : 'Create Bucket'}
        </div>
      </div>
    </div>
  );
};

export default BucketForm;
