import { Auth } from 'aws-amplify';

import axiosInstance from '../helpers/axios-instance';

export const loadUser = () => async dispatch => {
  try {
    dispatch({ type: 'LOAD_USER' });

    const { attributes, signInUserSession } = await Auth.currentAuthenticatedUser();
    const response = await axiosInstance.get(`/client-users/${attributes.sub}`);
    const permissionsResponse = await axiosInstance.get(`/users/permissions/${attributes.sub}`);

    dispatch({
      type: 'SET_AUTH_GROUPS',
      payload: signInUserSession.accessToken.payload['cognito:groups'] || []
    });

    dispatch({
      type: 'LOAD_USER_DATA_SUCCESS',
      payload: response.data
    });

    dispatch({
      type: 'LOAD_USER_SUCCESS',
      payload: attributes
    });

    if (!permissionsResponse.data.message) {
      dispatch({ type: 'LOAD_USER_PERMISSIONS_SUCCESS', payload: permissionsResponse.data });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: 'LOAD_USER_FAILURE', payload: error });
  }
};
