import React, { useState } from 'react';
import { toast } from 'react-toastify';

import '../../wetx-styles/wetx.light.css';

import { editTag } from '../../redux/files/files.actions';
import store from '../../redux/state';

const WetxTagColorPicker = ({ tag, setShowColorPicker, setTagForEdit, renderPos }) => {
  const [colors] = useState([
    { background: '#0052cc', color: '#fff' },
    { background: '#6c757d', color: '#fff' },
    { background: '#28a745', color: '#fff' },
    { background: '#dc3545', color: '#fff' },
    { background: '#ffc107', color: '#212529' },
    { background: '#17a2b8', color: '#fff' },
    { background: '#f8f9fa', color: '#212529' },
    { background: '#343a40', color: '#fff' }
  ]);
  const [currentColor, setCurrentColor] = useState({ background: tag.background, color: tag.color });
  const [tagName, setTagName] = useState('');
  const [saving, setSaving] = useState(false);

  return (
    <div className="d-flex flex-column text-center align-items-center justify-content-center color-picker">
      <div className="display-arrow" />
      <div style={{ position: 'relative', width: '100%', height: 'auto' }}>
        <input
          type="text"
          className="tag-name-input mb-3"
          value={tagName}
          placeholder="Rename label"
          onChange={e => {
            if (e.target.value.length > 20) {
              return toast.info('Tag cannot be longer than 20 characters');
            }
            if (e.target.value.includes('"')) {
              return toast.info('You cannot have " in your tag names');
            }
            setTagName(e.target.value);
          }}
        />
        <div className="tag-name__input-clear" onClick={() => setTagName('')}>
          x
        </div>
      </div>
      <div className="d-flex flex-row mt-6">
        {colors.map(color => {
          return (
            <div
              onClick={() => {
                // tag['background'] = color.background;
                // tag['color'] = color.color;
                setCurrentColor(color);
              }}
              key={color.background}
              style={{
                boxSizing: 'border-box',
                width: '20px',
                height: '20px',
                flexShrink: '0',
                maxWidth: '100%',
                borderRadius: '50%',
                cursor: 'pointer',
                marginRight: '5px',
                position: 'relative'
              }}
            >
              <p
                style={{ background: color.background, margin: 0, width: '100%', height: '100%', borderRadius: '50%' }}
              />
              {currentColor.background === color.background && (
                <div className="color-picker-check" style={{ color: color.color }}>
                  ✓
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="d-flex flex-row align-items-center mt-3">
        <button
          className="btn btn-secondary"
          onClick={() => {
            setTagForEdit(null);
            setShowColorPicker(false);
          }}
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            if (tagName.length < 3 && tagName.length !== 0) {
              return toast.info('Tag must be at least 3 characters long');
            }
            setSaving(true);

            tag['background'] = currentColor ? currentColor.background : tag.background;
            tag['color'] = currentColor ? currentColor.color : tag.color;
            tag['tag_name'] = tagName !== '' ? tagName : tag.tag_name;

            // Updates the tag on all files where it is used.
            // This should be moved to the overall save on the tag window instead of here in the popup.
            // TODO: Fix this!
            await store.dispatch(editTag(tag));
            setShowColorPicker(false);
            setTagForEdit(null);
          }}
          className="btn btn-primary ml-2"
        >
          {saving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  );
};

export default WetxTagColorPicker;
