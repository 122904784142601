import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import WetxCustomConfirm from '../../wetx-utils/WetxCustomConfirm';
import WetxIcon from '../../utilities/WetxIcon';

import store from '../../../redux/state';
import { pinFile, archiveFile } from '../../../redux/files/files.actions';

const WetxFileActions = ({ toggle, setIsPinned, isPinned, setSharing, retrieveFile, id, client, user }) => {
  return (
    <div className="modal-header__Actions flex-end d-flex flex-row" style={{ fontSize: '1.2rem', marginLeft: 'auto' }}>
      <div className="flex-start mr-2 d-flex flex-row" style={{ paddingTop: '3px' }}>
        <WetxIcon
          onClick={() => {
            if (isPinned) {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <WetxCustomConfirm
                      title="Are you sure you want to unpin this document?"
                      description="This will remove it from the pinned documents. The document will remain within the vault"
                      onClose={onClose}
                      cancelButtonText="Cancel"
                      confirmButtonText="Yes, Remove Pin"
                      confirmButtonClick={() => {
                        store.dispatch(pinFile(id, client.id_client, user));
                        setIsPinned(!isPinned);
                      }}
                    />
                  );
                }
              });
            } else {
              store.dispatch(pinFile(id, client.id_client, user));
              setIsPinned(!isPinned);
            }
          }}
          iconName="pin"
          size="22px"
          title={isPinned ? 'Unpin File' : 'Pin File'}
          margin="5px"
          color={isPinned ? 'blue' : 'gray'}
          rotate={isPinned}
        />
        <WetxIcon
          onClick={() => {
            setSharing(true);
          }}
          iconName="share"
          size="22px"
          title="Share file"
          margin="5px"
          color="gray"
        />
        <WetxIcon
          title="Download File"
          iconName="download"
          size="25px"
          color="gray"
          margin="5px"
          onClick={() => {
            retrieveFile();
          }}
        />
        <WetxIcon
          iconName="archive"
          color="red"
          size="22px"
          title="Archive File"
          margin="5px"
          onClick={() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <WetxCustomConfirm
                    title="Are you sure you want to archive this document?"
                    description="Archiving this document will remove it from the vault and place it in the archive."
                    onClose={onClose}
                    cancelButtonText="Cancel"
                    confirmButtonText="Archive Document"
                    confirmButtonClick={() => {
                      store.dispatch(archiveFile(id));
                      toggle();
                    }}
                  />
                );
              }
            });
          }}
        />
      </div>
      <div
        className="flex-end"
        title="Close File"
        style={{ borderLeft: '1px solid #c7c7c7c7', paddingLeft: '5px', height: '30px' }}
      >
        <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={toggle} />
      </div>
    </div>
  );
};

export default WetxFileActions;
