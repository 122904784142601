import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store from '../../redux/state';
import WetxPageNavigator from '../wetx-utils/WetxPageNavigator';

import { elasticDeepSearch } from '../../redux/elastic/elastic.actions';
import Loader from '../common/Loader';
import Fuse from 'fuse.js/dist/fuse.esm';
import WetxSearchResultsTable from './WetxSearchResultsTable';

const fuseJsOptions = {
  includeMatches: true,
  minMatchCharLength: 3,
  threshold: 0.3,
  keys: ['filename']
};

const WetxSearchPage = () => {
  const history = useHistory();

  const [searchValue, setSearchValue] = useState(history.location.state.searchInputValue);
  const [prevSearchValue, setPrevSearchValue] = useState('');
  const [includeArchived, setIncludeArchived] = useState(history.location.state.includeArchived);
  const [prevIncludeArchived, setPrevIncludeArchived] = useState('');

  const files = useSelector(state => state.files.items);
  const { archivedFiles } = useSelector(state => state.files);
  const { clientOrganisation } = useSelector(state => state.auth);
  const { foundElastic, loading } = useSelector(state => state.elastic);

  const [fileResults, setFileResults] = useState([]);
  const [archivedResults, setArchivedResults] = useState([]);
  const [elasticResult, setElasticResult] = useState([]);

  const elasticSearch = searchValue => {
    if (clientOrganisation) {
      setElasticResult([]);
      store.dispatch(elasticDeepSearch(clientOrganisation.client_bucket, searchValue, null, null));
    }
  };

  const searchFiles = () => {
    if (searchValue && searchValue.length > 0) {
      elasticSearch(searchValue);
      let searchResult = new Fuse(files, fuseJsOptions).search(searchValue).map(item => item.item);
      setFileResults(searchResult);
      if (includeArchived) {
        let archivedSearchResults =
          archivedFiles && new Fuse(archivedFiles, fuseJsOptions).search(searchValue).map(item => item.item);

        setArchivedResults(archivedSearchResults);
      } else if (archivedFiles && archivedFiles.length > 0) {
        setArchivedResults([]);
      }
    } else {
      setFileResults([]);
      setElasticResult([]);
    }
  };

  const isNewSearch = () => {
    let isNew = false;
    if (searchValue !== prevSearchValue || includeArchived !== prevIncludeArchived) {
      isNew = true;
      setPrevSearchValue(searchValue);
      setPrevIncludeArchived(includeArchived);
    }
    return isNew;
  };

  useEffect(() => {
    if (isNewSearch()) {
      searchFiles();
    } else if (searchValue && searchValue.length > 0) {
      setElasticResult(foundElastic);
    }

    history.listen(location => {
      if (location && location.state) {
        setSearchValue(location.state.searchInputValue);
        setIncludeArchived(location.state.includeArchived);
        if (isNewSearch()) {
          searchFiles();
        } else if (searchValue && searchValue.length > 0) {
          setElasticResult(foundElastic);
        }
      }
    });

    // eslint-disable-next-line
  }, [searchValue, includeArchived, foundElastic, loading]);

  return (
    <div>
      <WetxPageNavigator title="Search Results" />
      {loading && <Loader style={{ padding: '0 !important', marginRight: '20px' }} />}
      {!loading && elasticResult.length === 0 && fileResults.length === 0 && archivedResults.length === 0 && (
        <div className="card search-results__fail">
          <p className="title">We couldn't find any matches for "{searchValue}"</p>
          <p className="desc">
            Double check your search for any typos or spelling errors - or try a different search term
          </p>
        </div>
      )}
      {elasticResult && elasticResult.length > 0 && (
        <WetxSearchResultsTable
          files={elasticResult}
          folderName="Found within Documents"
          folderId="deep-search-results"
          hideUploadBtn={true}
          searchValue={searchValue}
        />
      )}
      {fileResults && fileResults.length > 0 && (
        <WetxSearchResultsTable
          files={fileResults}
          folderName="Found in Filenames"
          folderId="all-files"
          hideUploadBtn={true}
        />
      )}
      {archivedResults && archivedResults.length > 0 && (
        <WetxSearchResultsTable
          files={archivedResults}
          folderName="Found in Archived Filenames"
          folderId="all-files"
          hideUploadBtn={true}
        />
      )}
    </div>
  );
};

export default WetxSearchPage;
