export const version = '0.0.1';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: false,
  isRTL: false,
  isDark: false,
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'card', // 'transparent', 'card', 'inverted', 'vibrant',

  // AWS Amplify Config
  Amplify: {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITYPOOLID,

      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLID,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLWEBCLIENTID
    }
  }
};
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
