import React from 'react';
import { Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import WetxCloudDropdownFileItem from './WetxCloudDropdownFileItem';

const WetxCollapseFileView = ({ documents, toggleCollapse, isOpen, title, isPinned }) => {
  return (
    <div className={`mb-3 ${isPinned ? 'is-pinned' : ''}`}>
      <div className="d-flex flex-row align-items-center mb-1" onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon icon={isOpen ? 'chevron-up' : 'chevron-down'} style={{ fontWeight: '800' }} />
        <p className="m-0 ml-2" style={{ fontWeight: '800' }}>
          {title}
        </p>
      </div>

      <Collapse isOpen={isOpen} className="mb-2">
        <div className="d-flex justify-content-start" style={{ overflowX: 'scroll', display: 'inline-block' }}>
          {!documents || documents.length === 0 ? (
            <p>Loading...</p>
          ) : (
            documents.map((document, index) => <WetxCloudDropdownFileItem key={index} item={document} />)
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default WetxCollapseFileView;
