export const userActionTypes = {
  GET_ORG_USERS_REQUEST: 'GET_ORG_USERS_REQUEST',
  GET_PERMISSIONS_REQUEST: 'GET_PERMISSIONS_REQUEST',
  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_SHAREHOLDERS_REQUEST: 'GET_SHAREHOLDERS_REQUEST',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  GET_ROLE_PERMISSIONS_REQUEST: 'GET_ROLE_PERMISSIONS_REQUEST',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  DELETE_ADDITIONAL_PERMISSION_REQUEST: 'DELETE_ADDITIONAL_PERMISSION_REQUEST',

  GET_ORG_USERS_SUCCESS: 'GET_ORG_USERS_SUCCESS',
  GET_PERMISSIONS_SUCCESS: 'GET_PERMISSIONS_SUCCESS',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_SHAREHOLDERS_SUCCESS: 'GET_SHAREHOLDERS_SUCCESS',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  GET_ROLE_PERMISSIONS_SUCCESS: 'GET_ROLE_PERMISSIONS_SUCCESS',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  DELETE_ADDITIONAL_PERMISSION_SUCCESS: 'DELETE_ADDITIONAL_PERMISSION_SUCCESS',

  GET_ORG_USERS_ERROR: 'GET_ORG_USERS_ERROR',
  GET_PERMISSIONS_ERROR: 'GET_PERMISSIONS_ERROR',
  GET_ROLES_ERROR: 'GET_ROLES_ERROR',
  GET_SHAREHOLDERS_ERROR: 'GET_SHAREHOLDERS_ERROR',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  GET_ROLE_PERMISSIONS_ERROR: 'GET_ROLE_PERMISSIONS_ERROR',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  DELETE_ADDITIONAL_PERMISSION_ERROR: 'DELETE_ADDITIONAL_PERMISSION_ERROR'
};
