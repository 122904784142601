import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const WetxAdminNavbar = () => {
  const routes = [
    { name: 'WETx Admin Home', path: '/', icon: 'home' },
    { name: 'WETx Buckets', path: '/admin/buckets', icon: 'archive' },
    { name: 'WETx Clients', path: '/admin/clients', icon: 'users' },
    { name: 'WETx Users', path: '/admin/users', icon: 'user' }
  ];
  return (
    <div style={{ width: '200px', height: '80vh' }} className="card mr-2 position-sticky d-flex flex-column p-2">
      {routes.map((route, index) => {
        return (
          <div key={index} className="mb-1 d-flex flex-row align-items-center">
            {route.icon && <FontAwesomeIcon icon={route.icon} className="mr-1" style={{ width: '20px' }} />}
            <Link to={location => ({ ...location, pathname: route.path })}>{route.name}</Link>
          </div>
        );
      })}
    </div>
  );
};

export default WetxAdminNavbar;
