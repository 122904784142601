import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import WetxIcon from '../../utilities/WetxIcon';
import WetxCustomConfirm from '../../wetx-utils/WetxCustomConfirm';

import { viewFile, saveNewRecentFile, archiveFile, pinFile, downloadFile } from '../../../redux/files/files.actions';
import store from '../../../redux/state';

import '../../../wetx-styles/wetx.light.css';

const ActionFormatter = ({
  file,
  user,
  client,
  setSingleFile,
  pinnedDocuments,
  setViewing,
  setIsDownloading,
  setOpenDownloadModal,
  setSharing,
  setTagging,
  vertical
}) => {
  const [isPinned, setIsPinned] = useState(false);
  const { id, pages, page } = file;

  const checkIfPinned = () => {
    pinnedDocuments.map(doc => doc.id === id && setIsPinned(true));
  };

  useEffect(() => {
    if (pinnedDocuments) {
      checkIfPinned();
    }
    // eslint-disable-next-line
  }, [pinnedDocuments]);

  return (
    <UncontrolledDropdown style={{ position: vertical ? 'relative' : 'absolute' }}>
      <DropdownToggle color="link" size="sm" className="text-600 btn-reveal" style={{ padding: vertical && '0' }}>
        <FontAwesomeIcon icon={'ellipsis-h'} color={vertical && 'white'} className="fs--1" />
      </DropdownToggle>
      <DropdownMenu container="body" className="border py-2">
        <DropdownItem
          onClick={async e => {
            e.preventDefault();
            store.dispatch(saveNewRecentFile(id, user));
            const fileUrl = await store.dispatch(viewFile(file));
            setSingleFile({ file, fileUrl, pages: pages && pages.length > 1 && pages, page: page && page });
            setViewing(true);
          }}
          className="mb-2"
        >
          <WetxIcon iconName="view" size="16px" title={'View File'} margin="5px" color={'gray'} text="View" />
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            if (isPinned) {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <WetxCustomConfirm
                      title="Are you sure you want to unpin this document?"
                      description="This will remove it from the pinned documents. The document will remain within the vault"
                      onClose={onClose}
                      cancelButtonText="Cancel"
                      confirmButtonText="Yes, Remove Pin"
                      confirmButtonClick={() => {
                        store.dispatch(pinFile(file.id, client.id_client, user));
                        setIsPinned(!isPinned);
                      }}
                    />
                  );
                }
              });
            } else {
              store.dispatch(pinFile(file.id, client.id_client, user));
              setIsPinned(!isPinned);
            }
          }}
          className="mb-2"
        >
          <WetxIcon
            iconName="pin"
            size="16px"
            title={isPinned ? 'Unpin File' : 'Pin File'}
            margin="5px"
            text={isPinned ? 'Unpin' : 'Pin'}
            color={isPinned ? 'blue' : 'gray'}
            rotate={isPinned ? true : false}
          />
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setSingleFile({ file });
            setTagging(true);
          }}
          className="mb-2"
        >
          <WetxIcon iconName="tag" color="gray" size="16px" title="Manage File Labels" margin="5px" text="Label" />
        </DropdownItem>
        <DropdownItem
          onClick={async () => {
            const fileUrl = await store.dispatch(viewFile(file));
            setSingleFile({ file, fileUrl });
            setSharing(true);
          }}
          className="mb-2"
        >
          <WetxIcon iconName="share" color="gray" size="16px" title="Share File" margin="5px" text="Share" />
        </DropdownItem>
        <DropdownItem
          onClick={async () => {
            setIsDownloading(true);
            setOpenDownloadModal(true);
            setSingleFile({ file });
            const fileDownloaded = await store.dispatch(downloadFile(file));
            if (fileDownloaded) setIsDownloading(false);
          }}
        >
          <WetxIcon iconName="download" color="green" size="16px" title="Download File" margin="5px" text="Download" />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          onClick={() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <WetxCustomConfirm
                    title="Are you sure you want to archive this document?"
                    description="Archiving this document will remove it from the vault and place it in the archive."
                    onClose={onClose}
                    cancelButtonText="Cancel"
                    confirmButtonText="Archive Document"
                    confirmButtonClick={() => {
                      store.dispatch(archiveFile(id));
                    }}
                  />
                );
              }
            });
          }}
          className="text-danger"
        >
          <WetxIcon iconName="archive" color="red" size="16px" title="Archive File" margin="5px" text="Archive" />
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ActionFormatter;
