import { widgetsActionTypes } from './widgets.types';
import { dashboardsActionTypes } from '../dashboards/dashboards.types';

const initialState = {
  items: [],
  loading: false,
  error: null
};

function widgetsReducer(state = initialState, action) {
  switch (action.type) {
    case widgetsActionTypes.LOAD_WIDGETS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case widgetsActionTypes.LOAD_WIDGET_DATA_REQUEST:
      return { ...state, loading: true };

    case widgetsActionTypes.LOAD_WIDGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [...action.payload.sort((a, b) => (a.order > b.order ? 1 : -1))]
      };
    case widgetsActionTypes.LOAD_WIDGET_DATA_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case widgetsActionTypes.LOAD_WIDGETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case widgetsActionTypes.LOAD_WIDGET_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    case dashboardsActionTypes.CLEAR_STATE_SUCCESS:
      return { ...state, items: [], loading: false };
    default:
      return state;
  }
}

export default widgetsReducer;
