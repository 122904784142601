import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { DateBox } from 'devextreme-react';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../wetx-styles/wetx.light.css';

const WetxDateRange = ({ startDate, addStartDate, endDate, addEndDate }) => {
  const [dateStart, setDateStart] = useState(startDate || '');
  const [dateEnd, setDateEnd] = useState(endDate || '');
  return (
    <div className="d-flex flex-row align-items-center justify-content-end date-range">
      <DateBox
        placeholder="Select start date"
        value={dateStart !== '' && new Date(dateStart)}
        onValueChange={date => {
          if (dateEnd !== '' && new Date(dateEnd).getTime() < date.getTime()) {
            return toast.info('Start date cannot be after end date');
          }
          setDateStart(date);
          addStartDate(new Date(date).toISOString());
        }}
      />

      <p className="doc-edit__text">to</p>
      <DateBox
        placeholder="Select end date"
        value={dateEnd !== '' && new Date(dateEnd)}
        onValueChange={date => {
          if (dateStart === '') {
            return toast.info('You have to set start date first');
          }
          if (new Date(dateStart).getTime() > date.getTime()) {
            return toast.info('End date cannot be before start date');
          }

          setDateEnd(date);
          addEndDate(new Date(date).toISOString());
        }}
      />
    </div>
  );
};

export default WetxDateRange;
