import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';

import WETxUserImage from '../common/WETxUserImage';
import WetxIcon from '../utilities/WetxIcon';

import Avatar from '../common/Avatar';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { sub } = useSelector(state => state.auth.attributes);
  const { isAdmin } = useSelector(state => state.auth);
  const avatar = WETxUserImage(sub);

  let timer;

  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        timer = setTimeout(() => {
          windowWidth > 992 && setDropdownOpen(false);
        }, 500);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={avatar} />
      </DropdownToggle>
      <DropdownMenu
        right
        className="dropdown-menu-card"
        onMouseOver={() => {
          clearTimeout(timer);
        }}
      >
        <div className="bg-white rounded-soft py-2">
          <DropdownItem className="d-flex flex-row align-items-center" tag={Link} to="/settings">
            <WetxIcon iconName="user" size="16px" title={'Restore File'} margin="5px" color={'gray'} />
            Profile Settings
          </DropdownItem>
          <DropdownItem className="d-flex flex-row align-items-center" onClick={() => alert('Not implemented yet')}>
            <WetxIcon iconName="megaphone" size="16px" title={'Restore File'} margin="5px" color={'gray'} />
            Provide Feedback
          </DropdownItem>
          {isAdmin && (
            <DropdownItem tag={Link} to="/admin">
              Admin Dashboard
            </DropdownItem>
          )}
          <div className="hor-divider" style={{ margin: '5px 0' }} />
          <DropdownItem className="d-flex flex-column align-items-center" tag={Link} to="/auth/logout">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
