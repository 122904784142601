import React, { useState, useEffect } from 'react';
import { Viewer, ProgressBar, Worker } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';

import WetxPDFSearch from './WetxPdfSearchBox';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

const WetxPdfViewer = ({ fileUrl, initialPage, currentPage, initialHighlight }) => {
  const [numPages, setNumPages] = useState(1);
  const pageNavigationPluginInstance = pageNavigationPlugin();

  const searchPluginInstance = searchPlugin({
    keyword: initialHighlight ? initialHighlight.split(' ') : []
  });
  const { Search } = searchPluginInstance;

  const renderToolbar = Toolbar => (
    <Toolbar>
      {slots => {
        const { CurrentPageInput, EnterFullScreen, GoToNextPage, GoToPreviousPage, Zoom, ZoomIn, ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'space-around',
              display: 'flex'
            }}
          >
            <div style={{ padding: '0px 2px' }}>
              <GoToPreviousPage />
            </div>
            <div
              style={{
                padding: '0px 2px',
                alignItems: 'center',
                justifyContent: 'space-around',
                display: 'flex'
              }}
            >
              <CurrentPageInput />/<span>{numPages}</span>
            </div>
            <div style={{ padding: '0px 2px' }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <WetxPDFSearch Search={Search} />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <ZoomOut />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Zoom />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <EnterFullScreen />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [],
    renderToolbar
  });

  useEffect(() => {
    pageNavigationPluginInstance.jumpToPage(currentPage - 1);

    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <div className="wetx-pdf-viewer__container" style={{ width: '100%', height: '100%' }}>
      <div className="wetx-pdf-viewer__canvas" style={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl}
            initialPage={initialPage - 1}
            plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance, searchPluginInstance]}
            renderLoader={percentages => (
              <div style={{ width: '240px' }}>
                <ProgressBar progress={Math.round(percentages)} />
              </div>
            )}
            onDocumentLoad={e => {
              setNumPages(e.doc.numPages);
            }}
          />
        </Worker>
      </div>
    </div>
  );
};

export default WetxPdfViewer;
