import { toast } from 'react-toastify';
import axiosInstance from '../helpers/axios-instance';
import { filesActionTypes } from './files.types';
import download_file from '../helpers/file-download';

export const loadFiles = () => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.LOAD_FILES_REQUEST });

    const response = await axiosInstance.get('/s3?archived=false');

    dispatch({ type: filesActionTypes.LOAD_FILES_SUCCESS, payload: response.data.files });
    dispatch({ type: filesActionTypes.LOAD_FILE_FILTERS_SUCCESS, payload: response.data.filters });

    return response.data.files;
  } catch (error) {
    dispatch({ type: filesActionTypes.LOAD_FILES_ERROR, payload: error.message });
  }
};

export const updateFilesFolder = (files, category) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.UPDATE_FILES_CATEGORY_REQUEST });
    await axiosInstance.post('/s3/categories/multi', { files, category });
    dispatch({ type: filesActionTypes.UPDATE_FILES_CATEGORY_SUCCESS, payload: { files, category } });
  } catch (error) {
    dispatch({
      type: filesActionTypes.UPDATE_FILES_CATEGORY_ERROR,
      payload: error.message
    });
  }
};

export const updateFilesType = (files, type) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.UPDATE_FILES_TYPE_REQUEST });
    await axiosInstance.post('/s3/types/multi', { files, type });
    dispatch({ type: filesActionTypes.UPDATE_FILES_TYPE_SUCCESS, payload: { files, type } });
  } catch (error) {
    dispatch({
      type: filesActionTypes.UPDATE_FILES_TYPE_ERROR,
      payload: error.message
    });
  }
};

export const updateFilesTags = (files, tags) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.UPDATE_FILES_TAGS_REQUEST });
    await axiosInstance.post('/s3/tags/multi', { files, tags });
    dispatch({ type: filesActionTypes.UPDATE_FILES_TAGS_SUCCESS, payload: { files, tags } });
  } catch (error) {
    dispatch({
      type: filesActionTypes.UPDATE_FILES_TAGS_ERROR,
      payload: error.message
    });
  }
};

export const getFileTypes = id_client => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.LOAD_TYPES_REQUEST });

    const response = await axiosInstance.get(`/s3/types/${id_client}`);

    dispatch({ type: filesActionTypes.LOAD_TYPES_SUCCESS, payload: response.data.types });
  } catch (error) {
    dispatch({ type: filesActionTypes.LOAD_TYPES_ERROR, payload: error.message });
  }
};

export const loadArchivedFiles = () => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.LOAD_ARCHIVED_REQUEST });

    const response = await axiosInstance.get('/s3?archived=true');

    dispatch({ type: filesActionTypes.LOAD_ARCHIVED_SUCCESS, payload: response.data.files });

    return response.data.files;
  } catch (error) {
    dispatch({ type: filesActionTypes.LOAD_ARCHIVED_ERROR, payload: error.message });
  }
};

export const getRecentFiles = id_user => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.LOAD_RECENT_FILES_REQUEST });
    const response = await axiosInstance.get(`/s3/recent-files/${id_user}`);
    dispatch({ type: filesActionTypes.LOAD_RECENT_FILES_SUCCESS, payload: response.data.files });
  } catch (error) {
    dispatch({ type: filesActionTypes.LOAD_RECENT_FILES_ERROR, payload: error.message });
  }
};

export const getPinnedFiles = id_client => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.LOAD_PINNED_FILES_REQUEST });
    const response = await axiosInstance.get(`/s3/pinned-files/${id_client}`);
    dispatch({ type: filesActionTypes.LOAD_PINNED_FILES_SUCCESS, payload: response.data.files });
  } catch (error) {
    dispatch({ type: filesActionTypes.LOAD_PINNED_FILES_ERROR, payload: error.message });
  }
};

export const getClientTags = idClient => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.LOAD_TAGS_REQUEST });
    const response = await axiosInstance.get(`/s3/tags/client/${idClient}`);
    dispatch({ type: filesActionTypes.LOAD_TAGS_SUCCESS, payload: response.data.tags });
  } catch (error) {
    return dispatch({ type: filesActionTypes.LOAD_TAGS_ERROR, payload: error.message });
  }
};

export const saveFiles = (files, bucket) => async dispatch => {
  try {
    const region = 'us-west-2';

    dispatch({ type: filesActionTypes.SAVE_FILES_REQUEST });

    const response = await axiosInstance.post('/s3', { files, bucket, region });

    dispatch({ type: filesActionTypes.SAVE_FILES_SUCCESS, payload: response.data.savedFiles });
  } catch (error) {
    dispatch({ type: filesActionTypes.SAVE_FILES_ERROR, payload: error.message });
  }
};

export const saveNewRecentFile = (id_file, id_user) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.SAVE_RECENT_FILE_REQUEST });
    await axiosInstance.post('/s3/recent-files', { id_file, id_user });
    dispatch({ type: filesActionTypes.SAVE_RECENT_FILE_SUCCESS, payload: id_file });
  } catch (error) {
    dispatch({ type: filesActionTypes.SAVE_RECENT_FILE_ERROR, payload: error.message });
  }
};

export const pinFile = (id_file, id_client, id_user) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.PIN_FILE_REQUEST });
    const response = await axiosInstance.post(`/s3/pinned-files`, { id_file, id_client, id_user });
    dispatch({ type: filesActionTypes.PIN_FILE_SUCCESS });
    if (response.data.pinned === '0') {
      toast.info('Removed from pinned files');
    } else {
      toast.success('File pinned');
    }
    dispatch(getPinnedFiles(id_client));
  } catch (error) {
    dispatch({ type: filesActionTypes.PIN_FILE_ERROR, payload: error.message });
  }
};

export const archiveFile = id_file => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.ARCHIVE_FILE_REQUEST });
    const response = await axiosInstance.put(`/s3/archive/${id_file}`);

    dispatch({ type: filesActionTypes.ARCHIVE_FILE_SUCCESS, payload: id_file });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: filesActionTypes.ARCHIVE_FILE_ERROR, payload: error.message });
  }
};

export const createTag = tag => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.CREATE_TAG_REQUEST });
    const response = await axiosInstance.post(`/s3/tags`, { ...tag });
    tag['id_tag'] = response.data.id_tag;
    dispatch({ type: filesActionTypes.CREATE_TAG_SUCCESS, payload: tag });
    return tag;
  } catch (error) {
    dispatch({ type: filesActionTypes.CREATE_TAG_ERROR, payload: error.message });
  }
};

export const restoreFile = id_file => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.RESTORE_FILE_REQUEST });

    const response = await axiosInstance.put(`/s3/restore/${id_file}`);

    dispatch({ type: filesActionTypes.RESTORE_FILE_SUCCESS, payload: id_file });

    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: filesActionTypes.RESTORE_FILE_ERROR, payload: error.message });
  }
};

export const updateFile = file => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.UPDATE_FILE_REQUEST });

    const response = await axiosInstance.put('/s3', file);

    dispatch({ type: filesActionTypes.UPDATE_FILE_SUCCESS, payload: file });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: filesActionTypes.UPDATE_FILE_ERROR, payload: error.message });
  }
};

export const addTagToFile = (tag, id_file, id_tag) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.ADD_TAG_TO_FILE_REQUEST });
    await axiosInstance.post(`/s3/tags/${id_file}`, { id_tag });
    dispatch({ type: filesActionTypes.ADD_TAG_TO_FILE_SUCCESS, payload: { id_file, tag } });
    return true;
  } catch (error) {
    dispatch({ type: filesActionTypes.ADD_TAG_TO_FILE_ERROR, payload: error.message });
  }
};

export const editTag = tag => async dispatch => {
  try {
    const { id_tag, tag_name, background, color } = tag;
    dispatch({ type: filesActionTypes.EDIT_TAG_REQUEST });
    await axiosInstance.put(`/s3/tags/${id_tag}`, { tag_name, background, color });
    dispatch({ type: filesActionTypes.EDIT_TAG_SUCCESS, payload: { id_tag, tag_name, background, color } });
  } catch (error) {
    dispatch({ type: filesActionTypes.EDIT_TAG_ERROR, payload: error.message });
  }
};

export const removeTagFromFile = (id_file, id_tag) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.REMOVE_TAG_FROM_FILE_REQUEST });
    await axiosInstance.delete(`/s3/tags/${id_file}/${id_tag}`);
    dispatch({ type: filesActionTypes.REMOVE_TAG_FROM_FILE_SUCCESS, payload: { id_file, id_tag } });
    return true;
  } catch (error) {
    dispatch({ type: filesActionTypes.REMOVE_TAG_FROM_FILE_ERROR, payload: error.message });
  }
};

export const deleteFile = (file_key, bucket) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.DELETE_FILE_REQUEST });
    const key = 'public/' + file_key;

    await axiosInstance.delete(`/s3?file_key=${key}&client_bucket=${bucket}`);

    dispatch({ type: filesActionTypes.DELETE_FILE_SUCCESS, payload: file_key });
  } catch (error) {
    dispatch({ type: filesActionTypes.DELETE_FILE_ERROR, payload: error.message });
  }
};

export const removeTag = (id_tag, id_client) => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.REMOVE_TAG_REQUEST });
    await axiosInstance.delete(`/s3/tags/${id_tag}`);
    dispatch({ type: filesActionTypes.REMOVE_TAG_SUCCESS, payload: id_tag });
  } catch (error) {
    dispatch({ type: filesActionTypes.REMOVE_TAG_ERROR, payload: error.message });
  }
};

export const viewFile = file => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.VIEW_FILE_REQUEST });
    const { file_key, bucket, region, searchable_key } = file;
    const view_file_key = searchable_key && searchable_key !== 'null' ? searchable_key : file_key;

    const response = await axiosInstance.get(
      `/s3/get-signed-url?file=${view_file_key}&bucket=${bucket}&region=${region}`
    );
    dispatch({ type: filesActionTypes.VIEW_FILE_SUCCESS });
    return response.data.url;
  } catch (error) {
    dispatch({ type: filesActionTypes.VIEW_FILE_ERROR, payload: error });
  }
};

export const downloadFile = file => async dispatch => {
  try {
    dispatch({ type: filesActionTypes.DOWNLOAD_FILE_REQUEST });
    const { filename, file_key, bucket, region } = file;

    const response = await axiosInstance.get(`/s3/get-signed-url?file=${file_key}&bucket=${bucket}&region=${region}`);
    const downloadIndicator = document.querySelector('.wetx-download-indicator');
    const downloadIndicatorPercentage = document.querySelector('.wetx-download-indicator_percentage');

    await fetch(response.data.url)
      .then(resp => {
        const reader = resp.body.getReader();
        const contentLength = +resp.headers.get('Content-Length');
        let receivedLength = 0;

        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);

                if (downloadIndicator && downloadIndicatorPercentage) {
                  receivedLength += value.length;
                  const downloadPercentage = Math.floor((receivedLength / contentLength) * 100).toString();
                  downloadIndicator.setAttribute('aria-valuenow', downloadPercentage);
                  downloadIndicator.style.width = downloadPercentage + '%';
                  downloadIndicator.innerText = downloadPercentage + '%';
                  downloadIndicatorPercentage.innerText = 'Downloaded: ' + downloadPercentage + '%';
                }
                return pump();
              });
            }
          }
        });
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
      .then(url => download_file(url, filename))
      .catch(err => console.log(err));

    dispatch({ type: filesActionTypes.DOWNLOAD_FILE_SUCCESS });
    return true;
  } catch (error) {
    dispatch({ type: filesActionTypes.DOWNLOAD_FILE_ERROR, payload: error });
  }
};
