import React, { useState, useEffect } from 'react';
import { Nav, NavbarText } from 'reactstrap';
import { Auth } from 'aws-amplify';

import ProfileDropdown from './ProfileDropdown';
import WetxSettingsDropdown from '../wetx-utils/WetxSettingsDropdown';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import WetxDashboardEditActions from '../wetx-utils/WetxDashboardEditActions';

const TopNavRightSideNavItem = () => {
  const [name, setName] = useState('');

  const { isAdmin } = useSelector(state => state.auth);
  const { editingLayout } = useSelector(state => state.dashboards);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const { name } = user.attributes;
        setName(name);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      {editingLayout && <WetxDashboardEditActions />}
      {!isAdmin && (
        <Fragment>
          <WetxSettingsDropdown />
          <NavbarText className="mr-2">{name}</NavbarText>
        </Fragment>
      )}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
