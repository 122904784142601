import React from 'react';

import WetxTagColorPicker from '../wetx-cloud/WetxTagColorPicker';

import '../../wetx-styles/wetx.light.css';

const WetxSingleTag = ({
  index,
  tag,
  setTagForEdit,
  setShowColorPicker,
  removeTags,
  removeTagFromFile,
  tagForEdit,
  showColorPicker,
  client_tags,
  setTagging
}) => {
  return (
    <li
      key={index}
      className="tag"
      style={{
        background: tag.background,
        color: tag.color,
        padding: '5px 8px',
        borderRadius: '10px',
        height: '22px',
        fontSize: '12px',
        lineHeight: '12px'
      }}
    >
      <span
        onClick={e => {
          if (setTagForEdit) {
            setTagForEdit(tag);
            setShowColorPicker(true);
          }
          if (setTagging) {
            setTagging(true);
          }
        }}
        className="tag-title"
        style={{ cursor: removeTags || removeTagFromFile ? 'pointer' : 'default' }}
      >
        {tag.tag_name}
      </span>
      {(removeTagFromFile || removeTags) && (
        <span
          className="tag-close-icon"
          style={{
            background: tag.color,
            color: tag.background
          }}
          onClick={() => {
            if (removeTags) {
              removeTags(tag.id_tag);
            } else {
              removeTagFromFile && removeTagFromFile(tag.id_tag);
            }
          }}
        >
          x
        </span>
      )}

      {tagForEdit && tagForEdit.id_tag === tag.id_tag && showColorPicker && (
        <WetxTagColorPicker
          tag={tagForEdit}
          clientTags={client_tags}
          setShowColorPicker={setShowColorPicker}
          setTagForEdit={setTagForEdit}
        />
      )}
    </li>
  );
};

export default WetxSingleTag;
