import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WetxPageNavigator from '../wetx-utils/WetxPageNavigator';
import ManageCabinetsModal from './cabinets/ManageCabinetsModal';

import WetxCollapseFileView from './WetxCollapseFileView';
import WetxDevExtremeTable from './WetxDevExtremeTable';

const WetxCloudDash = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isPinnedOpen, setIsPinnedOpen] = useState(true);
  const [files, setFiles] = useState([]);
  const [recentDocuments, setRecentDocuments] = useState([]);
  const [pinnedDocuments, setPinnedDocuments] = useState([]);
  const [manageCabinetsOpen, setManageCabinetsOpen] = useState(false);
  const [cabinets, setCabinets] = useState(null);

  const { items, recentFiles, pinnedFiles, loading } = useSelector(state => state.files);
  const folders = useSelector(state => state.cabinets.items);

  const togglePinned = () => setIsPinnedOpen(!isPinnedOpen);
  const toggleRecent = () => setIsOpen(!isOpen);

  useEffect(() => {
    setFiles(items);
    setRecentDocuments(recentFiles);
    setPinnedDocuments(pinnedFiles);
    // eslint-disable-next-line
  }, [items, recentFiles, pinnedFiles, loading]);

  useEffect(() => {
    setCabinets(folders);
  }, [folders]);

  return (
    <div className="wetx-cloud-dash d-flex flex-column">
      <WetxCollapseFileView
        title="Pinned Documents"
        documents={pinnedDocuments}
        isOpen={isPinnedOpen}
        isPinned={true}
        toggleCollapse={togglePinned}
      />

      <WetxCollapseFileView
        title="Recent Documents"
        documents={recentDocuments}
        isOpen={isOpen}
        toggleCollapse={toggleRecent}
      />
      <WetxPageNavigator title="Cabinets" />
      <div
        className="btn-small"
        onClick={() => {
          setManageCabinetsOpen(true);
        }}
      >
        <p className="pager-button__next">+ New</p>
      </div>
      {cabinets &&
        cabinets.length > 0 &&
        cabinets.map((folder, index) => (
          <WetxDevExtremeTable
            key={index}
            files={files.filter(file =>
              file.category ? file.category === folder.id_category : file.id_category === folder.id_category
            )}
            folderName={folder.category_name}
            folderId={folder.id_category}
          />
        ))}
      {manageCabinetsOpen && (
        <ManageCabinetsModal open={manageCabinetsOpen} setOpen={setManageCabinetsOpen} cabinets={cabinets} />
      )}
    </div>
  );
};

export default WetxCloudDash;
