import React, { useState, useEffect } from 'react';

import { Card, CardBody, Col } from 'reactstrap';
import ReactEcharts from 'echarts-for-react';

import Loader from '../common/Loader';

import { loadWidgetData } from '../../redux/widgets/widgets.actions';
import store from '../../redux/state';

const WetxWidgetRadial = ({ idWidget, options: { title, option } }) => {
  const [widgetData, setWidgetData] = useState(null);

  const getWidgetDataMeta = async () => {
    const data = await store.dispatch(loadWidgetData(idWidget));

    if (data) setWidgetData(data[0].widget_data);
  };

  useEffect(() => {
    if (!widgetData) {
      getWidgetDataMeta();
    }
  });

  if (!widgetData) return <Loader />;

  let total = 0;
  for (let i = 0; i < widgetData.series[0].data.length; i++) {
    total += widgetData.series[0].data[i].value;
  }

  const dataHistory = () => {
    return widgetData.series[0].data.map((item, index) => (
      <div className="d-flex flex-row align-items-center" key={index}>
        <span
          style={{
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: `${item.color}`,
            marginBottom: '5px'
          }}
        />
        <p style={{ fontSize: '12px', marginBottom: '5px', marginLeft: '5px' }}>{`${item.name} ${Math.floor(
          (item.value / total) * 100
        )}%`}</p>
      </div>
    ));
  };

  const newObj = { ...option, ...widgetData };
  return (
    <Card style={{ height: '100%', width: '100%', maxHeight: '400px' }}>
      <CardBody>
        <div className="d-flex flex-row justify-content-center align-items-center" style={{ height: '100%' }}>
          <Col style={{ fontSize: '2.1rem', fontWeight: '600' }}>{dataHistory()}</Col>
          <Col>
            <ReactEcharts style={{ maxHeight: '100px' }} option={newObj} />
          </Col>
        </div>
      </CardBody>
    </Card>
  );
};

export default WetxWidgetRadial;
