import React from 'react';

const AdminInput = ({ value, onChange, error, readonly, label, infoText, errorCheck }) => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row align-items-center">
        <label htmlFor="bucket-name" className="m-0 mr-1">
          {label}:
        </label>
        <input
          readOnly={readonly && true}
          type="text"
          id={label}
          value={value}
          onChange={onChange}
          className={`pl-2 pr-2 pb-1 admin-input ${readonly ? 'readonly' : ''} ${
            error && error.error === errorCheck ? 'error' : ''
          }`}
        />
      </div>
      <div className={`admin-input__meta ${error && error.error === errorCheck ? 'error' : ''}`}>
        {error && error.error === errorCheck ? error.message : infoText}
      </div>
    </div>
  );
};

export default AdminInput;
