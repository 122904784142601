import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Loader from '../common/Loader';

import { loadArchivedFiles } from '../../redux/files/files.actions';
import store from '../../redux/state';
import WetxDevExtremeTable from '../wetx-cloud/WetxDevExtremeTable';
import WetxPageNavigator from '../wetx-utils/WetxPageNavigator';
import { getFolders } from '../../redux/cabinets/cabinets.actions';

const WetxArchivePage = () => {
  const { archivedFiles, loading } = useSelector(state => state.files);
  const folders = useSelector(state => state.cabinets.items);
  const { clientOrganisation } = useSelector(state => state.auth);

  useEffect(() => {
    store.dispatch(loadArchivedFiles());
    if (clientOrganisation && folders.length === 0) {
      store.dispatch(getFolders(clientOrganisation.id_client));
    }
    //eslint-disable-next-line
  }, [clientOrganisation]);

  if (!archivedFiles && loading) return <Loader />;

  return (
    <div className="d-flex flex-column">
      <WetxPageNavigator title="Archive" />
      {(!archivedFiles || archivedFiles.length === 0) && !loading && <div>The archive is empty</div>}
      {folders &&
        archivedFiles &&
        archivedFiles.length > 0 &&
        folders.length > 0 &&
        folders.map((folder, index) => (
          <WetxDevExtremeTable
            key={index}
            folderName={folder.category_name}
            folderId={folder.id_category}
            files={archivedFiles ? archivedFiles.filter(file => file.id_category === folder.id_category) : []}
            archive={true}
          />
        ))}
    </div>
  );
};

export default WetxArchivePage;
