import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ManageCabinetsTable from './ManageCabinetsTable';

const ManageCabinetsModal = ({ open, setOpen, cabinets }) => {
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Modal className="modal-lg modal-view" isOpen={open} centered={true}>
      <div className="modal-header d-flex flex-column" style={{ padding: '1px' }}>
        <div className="d-flex flex-row justify-content-between w-100" style={{ padding: '15px' }}>
          <div className="modal-header__Meta flex-start d-inline-flex flex-fill">
            <div className="m-0 text-center h5">Manage Cabinets</div>
          </div>
          <div className="flex-end" title="Close File" style={{ paddingLeft: '5px', height: '30px' }}>
            <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={toggle} />
          </div>
        </div>
      </div>

      <ModalBody style={{ width: '100%', height: '60%' }}>
        <ManageCabinetsTable cabinets={cabinets} />
      </ModalBody>
    </Modal>
  );
};

export default ManageCabinetsModal;
