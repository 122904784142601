import React from 'react';

import pin from '../../assets/img/icons/pin.svg';
import download from '../../assets/img/icons/download.svg';
import document from '../../assets/img/icons/docs.png';
import archive from '../../assets/img/icons/archive.svg';
import arrow_right from '../../assets/img/icons/icon-angle-right.svg';
import view from '../../assets/img/icons/eye.svg';
import tag from '../../assets/img/icons/tag.svg';
import share from '../../assets/img/icons/share.svg';
import copy from '../../assets/img/icons/copy.svg';
import upload from '../../assets/img/icons/upload.svg';
import gear from '../../assets/img/icons/gear.svg';
import link from '../../assets/img/icons/link.svg';
import arrow from '../../assets/img/icons/arrow_full.svg';
import restore from '../../assets/img/icons/restore.svg';
import cloud from '../../assets/img/icons/cloud.svg';
import user from '../../assets/img/icons/user.svg';
import users from '../../assets/img/icons/users.svg';
import megaphone from '../../assets/img/icons/megaphone.svg';
import edit from '../../assets/img/icons/edit.svg';
import vault from '../../assets/img/icons/vault.svg';

import '../../wetx-styles/wetx.light.css';

const Icon = ({ iconName, color, rotate }) => {
  switch (iconName) {
    case 'pin':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{
            width: '100%',
            height: '100%',
            transform: !rotate && 'rotate(45deg)',
            transitionDuration: '.5s'
          }}
          src={pin}
          alt="pin"
        />
      );
    case 'download':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={download}
          alt="download"
        />
      );
    case 'document':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={document}
          alt="document"
        />
      );
    case 'archive':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={archive}
          alt="archive"
        />
      );
    case 'arrow_right':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={arrow_right}
          alt="arrow_right"
        />
      );
    case 'view':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={view}
          alt="view"
        />
      );
    case 'tag':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={tag}
          alt="tag"
        />
      );
    case 'share':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={share}
          alt="share"
        />
      );
    case 'copy':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={copy}
          alt="copy"
        />
      );
    case 'upload':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={upload}
          alt="copy"
        />
      );
    case 'gear':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={gear}
          alt="copy"
        />
      );
    case 'vault':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={vault}
          alt="vault"
        />
      );
    case 'link':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={link}
          alt="copy"
        />
      );
    case 'restore':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{ width: '100%', height: '100%' }}
          src={restore}
          alt="copy"
        />
      );
    case 'arrow':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{
            width: '100%',
            height: '100%',
            transform: rotate && `rotate(${rotate}deg)`,
            transitionDuration: '.5s'
          }}
          src={arrow}
          alt="copy"
        />
      );
    case 'edit':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{
            width: '100%',
            height: '100%',
            transform: rotate && `rotate(${rotate}deg)`,
            transitionDuration: '.5s'
          }}
          src={edit}
          alt="edit"
        />
      );
    case 'megaphone':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{
            width: '100%',
            height: '100%',
            transform: rotate && `rotate(${rotate}deg)`,
            transitionDuration: '.5s'
          }}
          src={megaphone}
          alt="megaphone"
        />
      );
    case 'user':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{
            width: '100%',
            height: '100%',
            transform: rotate && `rotate(${rotate}deg)`,
            transitionDuration: '.5s'
          }}
          src={user}
          alt="user"
        />
      );
    case 'users':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{
            width: '100%',
            height: '100%',
            transform: rotate && `rotate(${rotate}deg)`,
            transitionDuration: '.5s'
          }}
          src={users}
          alt="users"
        />
      );
    case 'cloud':
      return (
        <img
          className={`wetx-icon wetx-icon__${color}`}
          style={{
            width: '100%',
            height: '100%',
            transform: rotate && `rotate(${rotate}deg)`,
            transitionDuration: '.5s'
          }}
          src={cloud}
          alt="cloud"
        />
      );
    default:
      return null;
  }
};

const WetxIcon = ({ iconName, size, color, margin, title, onClick, rotate, text }) => {
  return (
    <div
      className="d-flex flex-row align-items-center"
      onClick={onClick}
      style={{
        width: size,
        height: size,
        minWidth: size,
        marginRight: margin ? margin : 0
      }}
      title={title ? title : null}
    >
      <Icon
        className="flex-fill"
        iconName={iconName}
        color={color}
        style={{ height: size, width: size }}
        rotate={rotate}
      />
      {text && (
        <div className="ml-3" style={{ fontSize: size ? size : '16px' }}>
          {text && text}
        </div>
      )}
    </div>
  );
};

export default WetxIcon;
