import React from 'react';

import '../../wetx-styles/admin.css';

const WetxAdminHome = () => {
  return (
    <div className="card flex-fill">
      <div className="management-header w-100 text-center">Admin Home</div>
      <div className="bucket-management__content p-2 d-flex flex-column">
        Admin home content, maybe we can choose to pose as certain clients here
      </div>
    </div>
  );
};

export default WetxAdminHome;
