import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import AppContext from '../context/Context';
import AdminLayout from './AdminLayout';

const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { signedIn } = useContext(AppContext);

  return <Route {...rest} render={props => (signedIn ? <Component {...props} /> : <Redirect to="/auth/login" />)} />;
};

const AdminRoute = ({ component: Component, ...rest }) => {
  const { signedIn } = useContext(AppContext);

  return <Route {...rest} render={props => (signedIn ? <Component {...props} /> : <Redirect to="/auth/login" />)} />;
};

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/auth" component={AuthBasicLayout} />
        <AdminRoute path="/admin" component={AdminLayout} />
        <PrivateRoute component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
