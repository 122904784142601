export const filesActionTypes = {
  LOAD_FILES_REQUEST: 'LOAD_FILES_REQUEST',
  LOAD_ARCHIVED_REQUEST: 'LOAD_ARCHIVED_REQUEST',
  LOAD_FOLDERS_REQUEST: 'LOAD_FOLDERS_REQUEST',
  LOAD_TYPES_REQUEST: 'LOAD_TYPES_REQUEST',
  LOAD_RECENT_FILES_REQUEST: 'LOAD_RECENT_FILES_REQUEST',
  LOAD_PINNED_FILES_REQUEST: 'LOAD_PINNED_FILES_REQUEST',
  LOAD_TAGS_REQUEST: 'LOAD_TAGS_REQUEST',
  SAVE_FILES_REQUEST: 'SAVE_FILES_REQUEST',
  SAVE_RECENT_FILE_REQUEST: 'SAVE_RECENT_FILE_REQUEST',
  PIN_FILE_REQUEST: 'PIN_FILE_REQUEST',
  ARCHIVE_FILE_REQUEST: 'ARCHIVE_FILE_REQUEST',
  CREATE_TAG_REQUEST: 'CREATE_TAG_REQUEST',
  RESTORE_FILE_REQUEST: 'RESTORE_FILE_REQUEST',
  UPDATE_FILE_REQUEST: 'UPDATE_FILE_REQUEST',
  ADD_TAG_TO_FILE_REQUEST: 'ADD_TAG_TO_FILE_REQUEST',
  EDIT_TAG_REQUEST: 'EDIT_TAG_REQUEST',
  REMOVE_TAG_FROM_FILE_REQUEST: 'REMOVE_TAG_FROM_FILE_REQUEST',
  DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
  REMOVE_TAG_REQUEST: 'REMOVE_TAG_REQUEST',
  VIEW_FILE_REQUEST: 'VIEW_FILE_REQUEST',
  DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
  UPDATE_FILES_CATEGORY_REQUEST: 'UPDATE_FILES_CATEGORY_REQUEST',
  UPDATE_FILES_TYPE_REQUEST: 'UPDATE_FILES_TYPE_REQUEST',
  UPDATE_FILES_TAGS_REQUEST: 'UPDATE_FILES_TAGS_REQUEST',

  LOAD_FILES_SUCCESS: 'LOAD_FILES_SUCCESS',
  LOAD_FILE_FILTERS_SUCCESS: 'LOAD_FILE_FILTERS_SUCCESS',
  LOAD_ARCHIVED_SUCCESS: 'LOAD_ARCHIVED_SUCCESS',
  LOAD_FOLDERS_SUCCESS: 'LOAD_FOLDERS_SUCCESS',
  LOAD_TYPES_SUCCESS: 'LOAD_TYPES_SUCCESS',
  LOAD_RECENT_FILES_SUCCESS: 'LOAD_RECENT_FILES_SUCCESS',
  LOAD_PINNED_FILES_SUCCESS: 'LOAD_PINNED_FILES_SUCCESS',
  LOAD_TAGS_SUCCESS: 'LOAD_TAGS_SUCCESS',
  SAVE_FILES_SUCCESS: 'SAVE_FILES_SUCCESS',
  SAVE_RECENT_FILE_SUCCESS: 'SAVE_RECENT_FILE_SUCCESS',
  PIN_FILE_SUCCESS: 'PIN_FILE_SUCCESS',
  ARCHIVE_FILE_SUCCESS: 'ARCHIVE_FILE_SUCCESS',
  CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
  RESTORE_FILE_SUCCESS: 'RESTORE_FILE_SUCCESS',
  UPDATE_FILE_SUCCESS: 'UPDATE_FILE_SUCCESS',
  ADD_TAG_TO_FILE_SUCCESS: 'ADD_TAG_TO_FILE_SUCCESS',
  EDIT_TAG_SUCCESS: 'EDIT_TAG_SUCCESS',
  REMOVE_TAG_FROM_FILE_SUCCESS: 'REMOVE_TAG_FROM_FILE_SUCCESS',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  REMOVE_TAG_SUCCESS: 'REMOVE_TAG_SUCCESS',
  VIEW_FILE_SUCCESS: 'VIEW_FILE_SUCCESS',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  UPDATE_FILES_CATEGORY_SUCCESS: 'UPDATE_FILES_CATEGORY_SUCCESS',
  UPDATE_FILES_TYPE_SUCCESS: 'UPDATE_FILES_TYPE_SUCCESS',
  UPDATE_FILES_TAGS_SUCCESS: 'UPDATE_FILES_TAGS_SUCCESS',

  LOAD_FILES_ERROR: 'LOAD_FILES_ERROR',
  LOAD_ARCHIVED_ERROR: 'LOAD_ARCHIVED_ERROR',
  LOAD_FOLDERS_ERROR: 'LOAD_FOLDERS_ERROR',
  LOAD_TYPES_ERROR: 'LOAD_TYPES_ERROR',
  LOAD_RECENT_FILES_ERROR: 'LOAD_RECENT_FILES_ERROR',
  LOAD_PINNED_FILES_ERROR: 'LOAD_PINNED_FILES_ERROR',
  LOAD_TAGS_ERROR: 'LOAD_TAGS_ERROR',
  SAVE_FILES_ERROR: 'SAVE_FILES_ERROR',
  SAVE_RECENT_FILE_ERROR: 'SAVE_RECENT_FILE_ERROR',
  PIN_FILE_ERROR: 'PIN_FILE_ERROR',
  ARCHIVE_FILE_ERROR: 'ARCHIVE_FILE_ERROR',
  CREATE_TAG_ERROR: 'CREATE_TAG_ERROR',
  RESTORE_FILE_ERROR: 'RESTORE_FILE_ERROR',
  UPDATE_FILE_ERROR: 'UPDATE_FILE_ERROR',
  ADD_TAG_TO_FILE_ERROR: 'ADD_TAG_TO_FILE_ERROR',
  EDIT_TAG_ERROR: 'EDIT_TAG_ERROR',
  REMOVE_TAG_FROM_FILE_ERROR: 'REMOVE_TAG_FROM_FILE_ERROR',
  DELETE_FILE_ERROR: 'DELETE_FILE_ERROR',
  REMOVE_TAG_ERROR: 'REMOVE_TAG_ERROR',
  VIEW_FILE_ERROR: 'VIEW_FILE_ERROR',
  DOWNLOAD_FILE_ERROR: 'DOWNLOAD_FILE_ERROR',
  UPDATE_FILES_CATEGORY_ERROR: 'UPDATE_FILES_CATEGORY_ERROR',
  UPDATE_FILES_TYPE_ERROR: 'UPDATE_FILES_TYPE_ERROR',
  UPDATE_FILES_TAGS_ERROR: 'UPDATE_FILES_TAGS_ERROR'
};
