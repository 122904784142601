import { DateBox } from 'devextreme-react';
import React, { useState, useEffect } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { toast } from 'react-toastify';

import '../../../wetx-styles/wetx.light.css';

const WetxDateRangeFilter = ({ data, files, setFilteredFiles, folderId }) => {
  const [dropdownOpen, setDropdopwnOpen] = useState(false);
  const [startFilter, setStartFilter] = useState(null);
  const [endFilter, setEndFilter] = useState(null);

  const filterDateRange = () => {
    if (!startFilter) return toast.info('You cannot use empty date filter');
    let filteredByDate = [];

    files.filter(file => {
      const { start_date, end_date } = file;

      const startMonth = start_date && new Date(start_date).getMonth() + 1;
      const startYear = start_date && new Date(start_date).getFullYear();
      const endMonth = end_date && new Date(end_date).getMonth() + 1;
      const endYear = end_date && new Date(end_date).getFullYear();

      const startFilterMonth = startFilter && new Date(startFilter).getMonth() + 1;
      const startFilterYear = startFilter && new Date(startFilter).getFullYear();
      const endFilterMonth = endFilter && new Date(endFilter).getMonth() + 1;
      const endFilterYear = endFilter && new Date(endFilter).getFullYear();

      if (endFilter) {
        if (
          ((startMonth >= startFilterMonth && startYear >= startFilterYear) ||
            (startMonth >= endFilterMonth && startYear >= endFilterYear)) &&
          ((endMonth >= startFilterMonth && endYear >= startFilterYear) ||
            (endMonth >= endFilterMonth && endYear >= endFilterYear))
        ) {
          filteredByDate = [...filteredByDate, file];
        }
      } else if (startFilter && !endFilter) {
        if (
          (startMonth === startFilterMonth && startYear === startFilterYear) ||
          (endMonth === startFilterMonth && endYear === startFilterYear)
        ) {
          filteredByDate = [...filteredByDate, file];
        }
      }

      return filteredByDate;
    });

    const ttl = 1 * 3600 * 1000;
    const storageItem = JSON.stringify({
      startFilter,
      endFilter,
      expires: new Date().getTime() + ttl
    });
    localStorage.setItem(`date-filter-${folderId}`, storageItem);

    setFilteredFiles(filteredByDate);
    setDropdopwnOpen(false);
  };

  const customDateRangeFilter = element => {
    if (element) {
      element.addEventListener('click', e => {
        setDropdopwnOpen(!dropdownOpen);
        e.stopPropagation();
      });
    }
  };

  const localStorageFilter = () => {
    const dateFilter = localStorage.getItem(`date-filter-${folderId}`);
    if (dateFilter) {
      const jsonDate = JSON.parse(dateFilter);

      if (jsonDate.expires < new Date().getTime()) {
        return localStorage.removeItem(`date-filter-${folderId}`);
      }
      setStartFilter(jsonDate.startFilter);
      setEndFilter(jsonDate.endFilter);
    }
  };

  useEffect(() => {
    localStorageFilter();

    //eslint-disable-next-line
  }, [folderId]);

  return (
    <div className="d-flex flex-row justify-content-between date-range__container">
      <div>{data.column.caption}</div>
      <UncontrolledDropdown className="dx-column-indicators" isOpen={dropdownOpen}>
        <DropdownToggle color="link" size="sm" className="text-600 btn-reveal" style={{ padding: '0' }}>
          <span className="dx-header-filter dx-header-filter-empty" ref={customDateRangeFilter} />
        </DropdownToggle>
        <DropdownMenu container="body" className="border py-2 date-range__picker-container">
          <div>
            <DateBox
              type="date"
              value={startFilter && new Date(startFilter)}
              displayFormat="shortdate"
              placeholder="Select your start date"
              onValueChange={date => {
                if (endFilter && new Date(endFilter).getTime() < date.getTime())
                  return toast.info('End date cannot be smaller than start date');

                setStartFilter(date);
              }}
              className="mb-2"
            />
            <DateBox
              value={endFilter && new Date(endFilter)}
              placeholder="Select your end date"
              onValueChange={date => {
                if (!startFilter) return toast.info('You cannot select end date before selecting start date');
                if (date.getTime() < new Date(startFilter).getTime())
                  return toast.info('End date cannot be smaller than start date');
                setEndFilter(date);
              }}
            />
            <div className="d-flex flex-row justify-content-between mt-2 p-2">
              <div className="btn btn-outline-secondary" onClick={filterDateRange}>
                Filter
              </div>
              <div
                className="btn btn-outline-secondary"
                onClick={e => {
                  setStartFilter(null);
                  setEndFilter(null);
                  localStorage.removeItem(`date-filter-${folderId}`);
                  setDropdopwnOpen(false);
                  setFilteredFiles(null);
                }}
              >
                {startFilter ? 'Clear' : 'Cancel'}
              </div>
            </div>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default WetxDateRangeFilter;
