export const cabinetsActionTypes = {
  LOAD_CABINETS_REQUEST: 'LOAD_CABINETS_REQUEST',
  CREATE_CABINET_REQUEST: 'CREATE_CABINET_REQUEST',
  UPDATE_CABINET_REQUEST: 'UPDATE_CABINET_REQUEST',
  DELETE_CABINET_REQUEST: 'DELETE_CABINET_REQUEST',

  LOAD_CABINETS_SUCCESS: 'LOAD_CABINETS_SUCCESS',
  CREATE_CABINET_SUCCESS: 'CREATE_CABINET_SUCCESS',
  UPDATE_CABINET_SUCCESS: 'UPDATE_CABINET_SUCCESS',
  DELETE_CABINET_SUCCESS: 'DELETE_CABINET_SUCCESS',

  LOAD_CABINETS_ERROR: 'LOAD_CABINETS_ERROR',
  CREATE_CABINET_ERROR: 'CREATE_CABINET_ERROR',
  UPDATE_CABINET_ERROR: 'UPDATE_CABINET_ERROR',
  DELETE_CABINET_ERROR: 'DELETE_CABINET_ERROR'
};
