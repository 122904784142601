const download_file = (fileURL, fileName) => {
  const filename = fileName;
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement('a');
    save.href = fileURL;
    save.type = 'application/pdf';
    save.target = '_blank';
    save.download = filename;
    save.textContent = filename;
    if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
      // document.location = save.href;
      save.click();
      save.remove();
      // window event not working here
    } else {
      var evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, '_blank');
    _window.document.close();
    _window.document.execCommand('SaveAs', true, fileName || fileURL);
    _window.close();
  }
};

export default download_file;
