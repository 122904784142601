import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import is from 'is_js';

import rootReducer from './reducers/index';

let store = null;

if (is.chrome() && process.env.REACT_APP_LOCAL_DEVELOPMENT) {
  store = createStore(
    rootReducer,
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} else {
  store = createStore(rootReducer, compose(applyMiddleware(thunk)));
}

export default store;
