import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import WETXDash from '../components/wetx-dash/WETxDash';
import WETXRootDash from '../components/wetx-dash/WETxRootDash';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';

import WETxSettings from '../components/page/WETxSettings';
import WetxSearchBox from '../components/navbar/WetxSearchBox';
import CloudLayout from './CloudLayout';
import WaterPage from '../components/wetx-water/WaterPage';
// import ManageUsersPage from '../components/wetx-manage-users/ManageUsersPage';

import AppContext from '../context/Context';
import { getPageName } from '../helpers/utils';
import store from '../redux/state';
import { loadDashboards } from '../redux/dashboards/dashboards.actions';
import { loadWidgets } from '../redux/widgets/widgets.actions';
import { loadUser } from '../redux/actions';
import { loadFiles, loadArchivedFiles, getClientTags, getFileTypes } from '../redux/files/files.actions';
import { useSelector } from 'react-redux';
import { getFolders } from '../redux/cabinets/cabinets.actions';

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);

  const { isAdmin } = useSelector(state => state.auth);
  const client = useSelector(state => state.auth.clientOrganisation);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    window.scrollTo(0, 0);
    store.dispatch(loadDashboards());
    store.dispatch(loadWidgets());
    store.dispatch(loadFiles());
    store.dispatch(loadArchivedFiles());

    if (client) {
      store.dispatch(getFolders(client.id_client));
      store.dispatch(getClientTags(client.id_client));
      store.dispatch(getFileTypes(client.id_client));
    } else {
      store.dispatch(loadUser());
    }
  }, [client]);

  if (isAdmin) {
    return <Redirect to="/admin" />;
  }

  const NotFound = () => {
    return <div>Page not found</div>;
  };

  return (
    <div style={{ position: 'relative' }} className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <div className="content">
        <NavbarTop />
        <WetxSearchBox />
        <Switch>
          <Route path="/" exact component={WETXRootDash} />

          <Route path="/settings" exact component={WETxSettings} />
          <Route path="/alt" exact component={DashboardAlt} />
          <Route path="/dash/:idDash" exact component={WETXDash} />
          <Route path="/water" exact component={WaterPage} />
          {/* <Route path="/users/manage" exact component={ManageUsersPage} /> */}

          <Route path="/cloud" component={CloudLayout} />
          <Route path="*" component={NotFound} />
        </Switch>
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
