import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import Weather from './Weather';
import WeeklySales from './WeeklySales';
import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';

import CardSummary from '../dashboard/CardSummary';

import weeklySales from '../../data/dashboard/weeklySales';
import totalOrder from '../../data/dashboard/totalOrder';
import marketShare from '../../data/dashboard/marketShare';
import weather from '../../data/dashboard/weather';

const DashboardAlt = () => {
  return (
    <Fragment>
      <Row noGutters>
        <Col md={6} className="col-xxl-3 mb-3 pr-md-2">
          <WeeklySales data={weeklySales} />
        </Col>
        <Col md={6} className="col-xxl-3 mb-3 pl-md-2 pr-xxl-2">
          <TotalOrder data={totalOrder} />
        </Col>
        <Col md={6} className="col-xxl-3 mb-3 pr-md-2 pl-xxl-2">
          <MarketShare data={marketShare} />
        </Col>
        <Col md={6} className="col-xxl-3 mb-3 pl-md-2">
          <Weather data={weather} className="h-md-100" />
        </Col>
      </Row>
      <Row noGutters>
        <Col md={6} className="col-xxl-3 mb-3 pr-md-2">
          <CardSummary rate="-0.23%" title="Customers" color="warning" linkText="See all">
            58.39k
          </CardSummary>
        </Col>
        <Col md={6} className="col-xxl-3 mb-3 pl-md-2 pr-xxl-2">
          <CardSummary rate="-0.23%" title="Customers" color="warning" linkText="See all">
            58.39k
          </CardSummary>
        </Col>
        <Col md={6} className="col-xxl-3 mb-3 pr-md-2 pl-xxl-2">
          <CardSummary rate="-0.23%" title="Customers" color="warning" linkText="See all">
            58.39k
          </CardSummary>
        </Col>
        <Col md={6} className="col-xxl-3 mb-3 pl-md-2">
          <CardSummary rate="-0.23%" title="Customers" color="warning" linkText="See all">
            58.39k
          </CardSummary>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DashboardAlt;
