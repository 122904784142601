import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from './context/Context';
import { settings } from './config';
import toggleStylesheet from './helpers/toggleStylesheet';
import { getItemFromStore, setItemToStore, themeColors } from './helpers/utils';

import { Auth, Hub } from 'aws-amplify';

const Main = props => {
  const [signedIn, setSignedIn] = useState(false);
  const [user, setUser] = useState(null);

  const [isTopNav, setIsTopNav] = useState(settings.isTopNav);
  const [isVertical, setIsVertical] = useState(settings.isVertical);
  const [showBurgerMenu, setShowBurgerMenu] = useState(settings.showBurgerMenu);
  const [currency, setCurrency] = useState(settings.currency);
  const [isRTL, setIsRTL] = useState(settings.isRTL);
  const [isFluid, setIsFluid] = useState(settings.isFluid);
  const [navbarStyle, setNavbarStyle] = useState(settings.navbarStyle);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const [isDark, setIsDark] = useState(settings.isDark);
  const [isCombo, setIsCombo] = useState(settings.isCombo);

  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore('isNavbarVerticalCollapsed', settings.isNavbarVerticalCollapsed)
  );

  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);
  const value = {
    signedIn,
    setSignedIn,

    user,
    setUser,

    isRTL,
    isDark,
    isCombo,
    isFluid,
    setIsRTL,
    isTopNav,
    currency,
    setIsDark,
    setIsCombo,
    setIsFluid,
    isVertical,
    toggleModal,
    setIsTopNav,
    navbarStyle,
    setCurrency,
    setIsVertical,
    showBurgerMenu,
    setNavbarStyle,
    isOpenSidePanel,
    navbarCollapsed,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    setNavbarCollapsed,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed
  };

  useEffect(() => {
    setItemToStore('isNavbarVerticalCollapsed', isNavbarVerticalCollapsed);
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setSignedIn(true);
        setUser(user);
      })
      .catch(err => {
        if (err === 'The user is not authenticated') {
          setSignedIn(false);
        }
      });

    Hub.listen('auth', data => {
      const { payload } = data;

      // A user has been signed out
      if (payload.event === 'signOut') {
        setSignedIn(false);
        setUser(null);
      }

      // A user 67ada59d-dc92-4a6d-abd7-edb4bda797f2 has been signed in
      if (payload.event === 'signIn') {
        setSignedIn(true);
        setUser(payload.data);
      }
    });

    return () => {
      Hub.remove('auth');
    };
  }, []);

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light
        }}
      />
    );
  }

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

Main.propTypes = { children: PropTypes.node };

export default Main;
