import React, { useState } from 'react';

const AdminHeadControls = ({ buttons }) => {
  const [active, setActive] = useState(buttons[0].id);
  return (
    <div className="header-controls d-flex flex-row justify-content-center w-100">
      {buttons &&
        buttons.map((button, index) => (
          <div
            className={`header-controls__button ${active === button.id ? 'active' : ''}`}
            key={index}
            onClick={() => {
              setActive(button.id);
              button.action(button.value);
            }}
          >
            {button.label}
          </div>
        ))}
    </div>
  );
};

export default AdminHeadControls;
