import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from '../../redux/state';
import { getOrgWaterShares, getWaterRights, getWaterShares } from '../../redux/water/water.actions';

import WaterTable from '../wetx-utils/WaterTable';

const WaterPage = () => {
  const [rights, setRights] = useState(null);
  const [shares, setShares] = useState(null);
  const [orgShares, setOrgShares] = useState(null);

  const { clientOrganisation } = useSelector(state => state.auth);
  const { waterRights, waterShares, orgWaterShares } = useSelector(state => state.water);

  useEffect(() => {
    if (clientOrganisation) {
      if (!waterRights) {
        store.dispatch(getWaterRights(clientOrganisation.id_client));
      }
      if (!waterShares) {
        store.dispatch(getWaterShares(clientOrganisation.id_client));
      }
      if (!orgWaterShares) {
        store.dispatch(getOrgWaterShares(clientOrganisation.id_client));
      }
    }

    if (waterRights) {
      setRights(waterRights);
    }
    if (waterShares) {
      setShares(waterShares);
    }
    if (orgWaterShares) {
      setOrgShares(orgWaterShares);
    }
    // eslint-disable-next-line
  }, [clientOrganisation, waterRights, waterShares, orgWaterShares]);

  return (
    <div>
      {rights && <WaterTable id="water_rights" title="Water Rights" items={rights} />}
      {shares && <WaterTable id="water_shares" title="Water Shares" items={shares} />}
      {orgShares && <WaterTable id="org_water_shares" title="Organization's Water Shares" items={orgShares} />}
    </div>
  );
};

export default WaterPage;
