import React from 'react';
import { Link } from 'react-router-dom';

const WetxPageNavigator = ({ pages, title }) => {
  return (
    <div>
      {pages && (
        <div className="d-flex flex-row" style={{ height: '60px', fontSize: '12px' }}>
          {pages.map((page, index) => (
            <div key={index} className="d-flex flex-row align-items-center mr-1">
              <Link
                onClick={e => e.preventDefault}
                style={{
                  color: index === pages.length - 1 ? '#1F77FF' : '#000',
                  cursor: 'default',
                  textDecoration: 'none'
                }}
                to={page.link}
              >
                {page.name}
              </Link>
              {index !== pages.length - 1 && (
                <div
                  className="ml-1"
                  style={{
                    width: '0',
                    height: '0',
                    borderStyle: 'solid',
                    borderWidth: '0 0 7px 7px',
                    borderColor: 'transparent transparent black transparent'
                  }}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div className="h4">{title}</div>
    </div>
  );
};

export default WetxPageNavigator;
