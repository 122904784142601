import React, { useState } from 'react';

import '../../wetx-styles/admin.css';
import AdminHeadControls from './utils/AdminHeadControls';

const WetxAdminClients = () => {
  const [showCreateClient, setShowCreateClient] = useState(null);
  return (
    <div className="card flex-fill d-flex flex-column align-items-center">
      <div className="management-header w-100 text-center">Client Management</div>
      <AdminHeadControls
        buttons={[
          { label: 'List Clients', action: setShowCreateClient, value: false, id: 'list' },
          { label: 'Create Client', action: setShowCreateClient, value: true, id: 'create' }
        ]}
      />
    </div>
  );
};

export default WetxAdminClients;
