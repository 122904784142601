import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import WetxUploaderSingleSelect from './utils/WetxUploaderSingleSelect';
import WetxUploadTagSelect from './utils/WetxUploadTagSelect';
import store from '../../redux/state';
import { loadFiles, updateFilesFolder, updateFilesTags, updateFilesType } from '../../redux/files/files.actions';

const WetxMultiActionsModal = ({ open, setOpen, selectedFiles }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [savingChanges, setSavingChanges] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const { types, tags } = useSelector(state => state.files);
  const folders = useSelector(state => state.cabinets.items);

  const editFileTags = tags => {
    setSelectedTags(tags);
  };

  const changeCategory = cat => {
    setSelectedCategory(cat);
  };

  const changeType = type => {
    setSelectedType(type);
  };

  const saveFileChanges = async () => {
    setSavingChanges(true);
    if (selectedCategory) {
      await store.dispatch(updateFilesFolder(selectedFiles, selectedCategory));
    }
    if (selectedType) {
      await store.dispatch(updateFilesType(selectedFiles, selectedType));
    }
    if (selectedTags && selectedTags.length > 0) {
      await store.dispatch(updateFilesTags(selectedFiles, selectedTags));
    }

    store.dispatch(loadFiles());
    setSavingChanges(false);

    clearAndClose();
  };

  const clearAndClose = () => {
    setSelectedType(null);
    setSelectedCategory(null);
    setSelectedTags(null);
    toggle();
  };

  return (
    <Modal className="modal-lg modal-view multi-actions-modal" isOpen={open} centered={true}>
      <div className="modal-header d-flex flex-column" style={{ padding: '1px' }}>
        <div className="d-flex flex-row justify-content-between w-100" style={{ padding: '15px' }}>
          <div className="modal-header__Meta flex-start d-inline-flex flex-fill">
            <div className="m-0 text-center h5">Manage [{selectedFiles.length}] selected documents </div>
          </div>
          <div className="flex-end" title="Close File" style={{ paddingLeft: '5px', height: '30px' }}>
            <FontAwesomeIcon icon={faTimes} style={{ cursor: 'pointer' }} onClick={clearAndClose} />
          </div>
        </div>
      </div>

      <ModalBody style={{ width: '100%', height: '60%' }}>
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-column w-100 m-2">
            <div>Choose Cabinet for [{selectedFiles.length}] selected documents: </div>
            <WetxUploaderSingleSelect
              title="Select Cabinet"
              options={folders}
              objSelector="id_category"
              objTitle="category_name"
              currentOption={selectedCategory}
              handleChange={changeCategory}
              width="100%"
            />
          </div>

          <div className="d-flex flex-column w-100 m-2">
            <div>Choose Type for [{selectedFiles.length}] selected documents: </div>
            <WetxUploaderSingleSelect
              title="Select Type"
              options={types}
              objSelector="id_type"
              objTitle="type_name"
              currentOption={selectedType}
              handleChange={changeType}
              width="100%"
            />
          </div>
          <div className="d-flex flex-column w-100 m-2">
            <div>Add Labels for [{selectedFiles.length}] selected documents: </div>
            <div>[{selectedTags && selectedTags.length > 0 ? `${selectedTags.length}` : '0'} labels selected]</div>
            <WetxUploadTagSelect tags={tags} editFileTags={editFileTags} width="100%" />
          </div>

          <div className="d-flex flex-row" style={{ width: 'fit-content', marginLeft: 'auto' }}>
            <div
              className="btn btn-secondary"
              style={{ marginRight: '10px' }}
              onClick={() => {
                clearAndClose();
              }}
            >
              Cancel
            </div>
            <div className="btn btn-primary" onClick={saveFileChanges}>
              {savingChanges ? 'Saving...' : 'Save'}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WetxMultiActionsModal;
