import React, { useState } from 'react';

const WetxSingleFilePageNavigator = ({ currentPage, pages, setCurrentPage }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(pages.findIndex(page => page === currentPage));
  return (
    <div className="single-file d-flex flex-row align-items-center justify-content-end">
      <div style={{ minWidth: '120px' }}>Search results: </div>
      <div className="d-flex flex-row align-items-center">
        {currentPageIndex !== 0 && (
          <div
            className="mr-2"
            style={{ cursor: 'pointer', userSelect: 'none', fontSize: '24px', fontWeight: '800', color: 'blue' }}
            onClick={() => {
              setCurrentPage(pages[currentPageIndex - 1]);
              setCurrentPageIndex(currentPageIndex - 1);
            }}
          >
            {'<'}
          </div>
        )}
        {currentPageIndex + 1} of {pages.length}
        {currentPageIndex + 1 !== pages.length && (
          <div
            className="ml-2"
            style={{ cursor: 'pointer', userSelect: 'none', fontSize: '24px', fontWeight: '800', color: 'blue' }}
            onClick={() => {
              setCurrentPage(pages[currentPageIndex + 1]);
              setCurrentPageIndex(currentPageIndex + 1);
            }}
          >
            {'>'}
          </div>
        )}
      </div>
    </div>
  );
};

export default WetxSingleFilePageNavigator;
