import React, { useState, useEffect } from 'react';

import '../../../wetx-styles/wetx.light.css';

const WetxUploaderSingleSelect = ({
  title,
  options,
  handleChange,
  currentOption,
  currentCategory,
  objSelector,
  objTitle,
  width
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(currentOption || null);

  const selectOption = option => {
    setSelectedOption(option);

    handleChange(option);
  };

  useEffect(() => {
    if (currentCategory) {
      const filteredOption = options.filter(option => option[objSelector] === currentCategory);

      if (filteredOption) {
        setSelectedOption(filteredOption[0]);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="uploader-single-select__container" style={{ width: width && width }}>
      <div className="single-select__action" onClick={() => setShowOptions(true)}>
        {selectedOption ? (selectedOption[objTitle] ? selectedOption[objTitle] : selectedOption) : title}
      </div>
      {showOptions && (
        <div
          className="single-select__options-container"
          onMouseLeave={() => {
            setShowOptions(false);
          }}
        >
          {options && options.length > 0 ? (
            options.map((option, index) => {
              return (
                <div
                  className="single-select__options d-flex align-items-center"
                  key={index}
                  onClick={() => {
                    selectOption(option);
                    setShowOptions(false);
                  }}
                >
                  <div className="single-select__option">{option[objTitle] ? option[objTitle] : option}</div>
                  {selectedOption && (
                    <div className="single-select__checkmark">
                      {option[objSelector] && selectedOption[objSelector]
                        ? option[objSelector] === selectedOption[objSelector]
                          ? '✓'
                          : null
                        : option === selectedOption
                        ? '✓'
                        : null}
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div>You do not have any options to select</div>
          )}
        </div>
      )}
    </div>
  );
};

export default WetxUploaderSingleSelect;
