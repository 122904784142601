import { bucketActionTypes } from './buckets.types';
import axiosInstance from '../../helpers/axios-instance';

export const loadBuckets = () => async dispatch => {
  try {
    dispatch({ type: bucketActionTypes.LOAD_BUCKETS_REQUEST });
    const res = await axiosInstance.get('/admin/buckets');
    dispatch({ type: bucketActionTypes.LOAD_BUCKETS_SUCCESS, payload: res.data.buckets });
  } catch (error) {
    dispatch({ type: bucketActionTypes.LOAD_BUCKETS_ERROR, payload: error.message });
  }
};

export const createBucket = (bucketName, region) => async dispatch => {
  try {
    dispatch({ type: bucketActionTypes.CREATE_BUCKET_REQUEST });
    const res = await axiosInstance.post('/admin/buckets', { bucketName, region });
    dispatch({ type: bucketActionTypes.CREATE_BUCKET_SUCCESS, payload: res.data.bucket });
  } catch (error) {
    dispatch({ type: bucketActionTypes.CREATE_BUCKET_ERROR, payload: error.message });
  }
};
