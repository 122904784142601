import React from 'react';

const WetxCheckBox = ({ id, checked, onChange, label, ...rest }) => {
  return (
    <span
      className={`filter-action d-flex flex-row justify-content-start w-100 ${rest.className ? rest.className : ''}`}
    >
      <input
        id={id}
        type="checkbox"
        checked={checked}
        style={{
          height: '20px',
          width: '20px',
          minWidth: '20px',
          maxWidth: '20px',
          border: '1px solid #ddd',
          cursor: 'pointer'
        }}
        onChange={onChange}
      />
      <label className="m-0 ml-1" htmlFor={id}>
        {label}
      </label>
    </span>
  );
};

export default WetxCheckBox;
